import {
  AllInboxTwoTone,
  CheckCircle,
  CoPresent,
  PostAdd,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

//* Imported Components
import colors from "app/style/colors";

function PackageSilver() {
  return (
    <Box>
      <Box sx={{ borderRadius: "12px", boxShadow: 3, py: 4, px: 5, my: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AllInboxTwoTone
            sx={{ color: colors.LapisBlue, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Premium Service Package
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary }}>
          Upgrade for only $99 to receive these 2 premium services
          <span style={{ fontWeight: "bold", color: colors.black }}>
            — up to $220 value!
          </span>
        </Typography>
        <Box sx={{ my: 3, boxShadow: 3, p: 4, boxSizing: "border-box" }}>
          <Box sx={{ display: "flex", my: 3 }}>
            <CoPresent
              sx={{ fontSize: "48px", color: colors.TealishGreen, mr: 3 }}
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  EIN / Tax ID Number{" "}
                  <span style={{ color: colors.primary, marginLeft: "10px" }}>
                    FREE
                  </span>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      textDecorationLine: "line-through",
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    $70
                  </Typography>
                  <CheckCircle sx={{ ml: 2, color: colors.lightPrimary }} />
                </Box>
              </Box>
              <Typography sx={{ color: colors.secondary }}>
                A nine-digit number assigned by the IRS used to identify
                taxpayers
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <PostAdd
              sx={{ fontSize: "48px", color: colors.LavenderMist, mr: 3 }}
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Business Contract Templates{" "}
                  <span style={{ color: colors.primary, marginLeft: "10px" }}>
                    FREE
                  </span>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      textDecorationLine: "line-through",
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    $150
                  </Typography>
                  <CheckCircle sx={{ ml: 2, color: colors.lightPrimary }} />
                </Box>
              </Box>
              <Typography sx={{ color: colors.secondary }}>
                Your search for the right legal template stops here. Get access
                to
                <span
                  style={{
                    color: colors.LavenderMist,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  25+ high-quality legal documents
                </span>{" "}
                written by a business attorney to help keep your business
                contractually protected.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to="/company-information"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          component={Link}
          to="/process-members"
          variant="contained"
          //onClick={handleSubmit}
          sx={{ px: 4 }}
        >
          Get Upgrade & Bonus
        </Button>
      </Box>
      <Typography
        sx={{
          float: "right",
          textDecoration: "none",
          width: "210px",
          color: colors.primary,
          mb: 4,
          px: 4,
        }}
        component={Link}
        to="/process-members"
        //variant="outlined"
        //onClick={handleSubmit}
      >
        No thanks, I will pass on this opportunity.
      </Typography>
    </Box>
  );
}

export default PackageSilver;
