import { Box, Grid, Typography } from '@mui/material'
import { AccountCircle, ArrowForward, House, CreditScore, AddCard } from '@mui/icons-material';
import { Link } from 'react-router-dom';
//* Import Component
import colors from 'app/style/colors';
import SideNav from 'app/components/SideNav/SideNav';
import Layout from 'app/layout/Layout';
import useAuth from 'app/hooks/useAuth';
import ProfileDetail from 'app/components/ProfileDetail/ProfileDetail';

function MyProfile() {

  const { user } = useAuth();

  const userId = user.split("_")[0]

  return (
    <Layout>
      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
        <Grid item xs={12} md={2}
          sx={{
            bgcolor: colors.white, textAlign: "center", boxShadow: 3,
            borderRadius: '12px', mx: 4, height: '180px'
          }}
        >

          <SideNav indexValue={0} />

        </Grid>
        <Grid item xs={12} md={8}>
          <ProfileDetail id={userId} pageName={"Personal Details"} />

          <Box sx={{
            borderRadius: '10px', boxShadow: 3, padding: 1,

          }}>

            <Box
              sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', my: 3 }}>
              <Box
                component={Link} to='/myaccount'
                sx={{
                  borderRadius: '12px', width: '350px', boxShadow: 4, textDecoration: 'none',
                  height: '120px', textAlign: 'center', m: 2, cursor: 'pointer',
                  backgroundImage: `linear-gradient(to right top, #3c408e, #2d5fa9, #1d7cc0, #2299d2, #3fb6e0);`,
                  '&:hover': {
                    boxShadow: 5
                  },
                }}>
                <Grid sx={{ display: 'flex', my: 3 }}>
                  <AccountCircle sx={{ fontSize: '70px', mx: 2.5, color: colors.white }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', color: colors.white }}>My Account</Typography>
                    <Typography variant='body2'
                      sx={{ color: colors.white }}
                    >Edit your name or change your password</Typography>
                  </Box>
                  <ArrowForward
                    sx={{ textALign: 'right', color: colors.white, mt: 4, mr: 1, cursor: 'pointer' }} />
                </Grid>
              </Box>
              <Box
                component={Link} to='/myaddress'
                sx={{
                  borderRadius: '12px', width: '350px', boxShadow: 4, textDecoration: 'none',
                  height: '120px', textAlign: 'center', m: 2, cursor: 'pointer',
                  backgroundImage: `linear-gradient(to right top, #fa541c, #f47116, #ee881c, #e79d2b, #e0af3f);`,
                  '&:hover': {
                    boxShadow: 5
                  },
                }}>
                <Grid sx={{ display: 'flex', my: 3 }}>
                  <House sx={{ fontSize: '70px', mx: 2.5, color: colors.white }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', color: colors.white }}>Business Address</Typography>
                    <Typography variant='body2'
                      sx={{ color: colors.white }}
                    >Setup your business/residential address</Typography>
                  </Box>
                  <ArrowForward
                    sx={{ textALign: 'right', color: colors.white, mt: 4, mr: 1, cursor: 'pointer' }} />
                </Grid>
              </Box>
              <Box
                component={Link} to='/myorder'
                sx={{
                  borderRadius: '12px', width: '350px', boxShadow: 4, textDecoration: 'none',
                  height: '120px', textAlign: 'center', m: 2, cursor: 'pointer',
                  backgroundImage: `linear-gradient(to right top, #344768, #3e5274, #485d80, #53688c, #5d7399);`,
                  '&:hover': {
                    boxShadow: 5
                  },
                }}>
                <Grid sx={{ display: 'flex', my: 3 }}>
                  <CreditScore sx={{ fontSize: '70px', mx: 2.5, color: colors.white }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', color: colors.white }}>Confirmed Orders</Typography>
                    <Typography variant='body2'
                      sx={{ color: colors.white }}
                    >Check number of orders made up till now</Typography>
                  </Box>
                  <ArrowForward
                    sx={{ textALign: 'right', color: colors.white, mt: 4, mr: 1, cursor: 'pointer' }} />
                </Grid>
              </Box>
              <Box
                component={Link} to='/myinvoice'
                sx={{
                  borderRadius: '12px', width: '350px', boxShadow: 4, textDecoration: 'none',
                  height: '120px', textAlign: 'center', m: 2, cursor: 'pointer',
                  backgroundImage: `linear-gradient(to right top, #dc549b, #dd4e92, #de4788, #de417e, #de3b74);`,
                  '&:hover': {
                    boxShadow: 5
                  },
                }}>
                <Grid sx={{ display: 'flex', my: 3 }}>
                  <AddCard sx={{ fontSize: '70px', mx: 2.5, color: colors.white }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold', color: colors.white }}>Pending Bills</Typography>
                    <Typography variant='body2'
                      sx={{ color: colors.white }}
                    >Check details of the payments that are unpaid</Typography>
                  </Box>
                  <ArrowForward
                    sx={{ textALign: 'right', color: colors.white, mt: 4, mr: 1, cursor: 'pointer' }} />
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MyProfile