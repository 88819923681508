import React from 'react';
import { Box, Typography, Grid, FormControl, OutlinedInput, Container, Button } from '@mui/material';

import { Mail } from 'assets/Images';
import colors from 'app/style/colors';

function Newsletter() {
  return (
    <Box sx={{ py: 5, bgcolor: '#F9FAFB' }}>
      <Container>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item md={1}>
            <Mail />
          </Grid>
          <Grid item md={5}>
            <Typography variant="h4" sx={{ mt: 1, mb: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >Sign Up For Newsletter</Typography>
            <Typography variant="body1" sx={{ color: colors.textPrimary }} >Receive 50% discount on first project</Typography>
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            <Box component="form">
              <FormControl sx={{ m: 1, width: '80%' }} variant="outlined">
                <OutlinedInput
                  placeholder='Enter your email'
                  fullWidth
                  sx={{ bgcolor: colors.lightWhite }}
                />
              </FormControl>
              <Button
                variant='contained'
                sx={{
                  py: 1.5,
                  my: 1.2
                }}
              >
                Sign Up
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Newsletter;