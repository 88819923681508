import React from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material'

//*Import Components
import Layout from 'app/layout/Layout'
import colors from 'app/style/colors'
import Images from 'assets/Images'

function Marketing() {

  return (

    <Layout>
      <Box sx={{
        marginTop: 1.2, borderRadius: '14px',
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.Marketing})`,
        height: 350, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
      }
      }>
        <Typography variant="h1" sx={{
          color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
        }}>
          Marketing
        </Typography>
        <Divider
          sx={{
            bgcolor: colors.primary,
            width: '140px',
            padding: '1px',
            margin: 'auto',
            fontSize: '28px',
            mb: 1
          }}
        />

        <Divider
          sx={{
            bgcolor: colors.primary,
            width: '100px',
            padding: '1px',
            margin: 'auto',
            fontSize: '28px'
          }}
        />
      </Box>
      <Container>
        {/* ========== Header ========== */}
        <Grid container sx={{ bgcolor: colors.lightGray, my: 4, justifyContent: 'space-between' }}>
          <Grid item xs={12} md={5}
            sx={{ my: 'auto' }}
          >
            <Typography variant="h3" sx={{
              color: colors.black, textAlign: 'center', mt: 10,
              fontWeight: 'bold',
            }}>
              Marketing
            </Typography>
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '140px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px',
                mb: 1
              }}
            />
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '100px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px'
              }}
            />
            <Typography variant="h6"
              sx={{ my: 3, mx: 2, fontWeight: 'bold', color: colors.textPrimary, textAlign: 'justify' }}>Get set and grow!! That’s right, once you have your business set up, advertise it with a robust strategy for brand awareness and consistent growth.</Typography>


          </Grid>
          <Grid item xs={12} md={7}
          >
            <img src={Images.MarketingSection} alt='marketing'
            />
          </Grid>
        </Grid>

        <Grid sx={{ my: 4 }}>
          <Typography variant="h5" sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}>Our Marketing Team</Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>Marketing your business increases its visibility to the target audience that helps sell your products faster. Marketing, therefore, is an essential tool for business growth in such a competitive world of business. Various marketing channels are helping e-commerce businesses get recognition and boost their sale.</Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>Social media is the best place to meet the right people for your services to increase your sales, bring traffic to your website, and make you a brand. Multiple social media platforms are helping grow and develop an environment for online business around the world.</Typography>
          <Typography variant="h5" sx={{ color: colors.primary, fontWeight: 'bold', my: 2 }}>1.	Facebook/Instagram Marketing</Typography>
          <Typography variant="h6" sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}>Most effective social media marketing platforms supporting multiple ad formats and psychographic targeting.</Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>These massive platforms allow free organic posts and a targeted paid advertisement that brands can utilize to put their services and products in front of a vast audience. These platforms allow adding shoppable tags and one on one engagement with the audience.</Typography>
          <Typography variant="h5" sx={{ color: colors.primary, fontWeight: 'bold', my: 2 }}>2.	Pinterest</Typography>
          <Typography variant="h6" sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}>Best place to meet your consumers and bring crazy traffic back home.</Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>Pinterest is getting popular as a marketing tool for many businesses. Millions of people of all age groups are using this platform to share trending products and designs. So, it is a compelling and affordable option to market your product or services by running ads and using promoted pins to bring traffic to your business website. It is free to get a business account with Pinterest, but advertisers are charged for promoted pins (CPE) only when someone clicks and goes to your website directly through the promoted pin. If your pin is re-pinned, there are no charges, and someone clicks on it.</Typography>
          <Typography variant="h5" sx={{ color: colors.primary, fontWeight: 'bold', my: 2 }}>3.	Google Ads</Typography>
          <Typography variant="h6" sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}>Allows you to get a better hold of your advertisement budget and target a relevant audience only.</Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>Google has a massive reach every second, which means what it advertises reaches the maximum number of customers. It lets smaller businesses promote their product and services by selectively showing the ads to people with relevant interests. Moreover, Google allows you to manage your campaigns more effectively, keep control of the marketing cost, get quicker results, and build brand awareness.</Typography>

        </Grid >
      </Container >
    </Layout >
  )
}

export default Marketing