import React from 'react'
import { Divider } from '@mui/material';

// *Import Components
import Growth from 'app/components/Growth/Growth';
import Pricing from 'app/components/Pricing/Pricing';
import Newsletter from 'app/components/Newsletter/Newsletter';
import Provide from 'app/components/Provide/Provide';
import Explore from 'app/components/Explore/Explore';
import FeaturedService from 'app/components/FeaturedService/FeaturedService';
import Testimonial from 'app/components/Testimonial/Testimonial';
import Layout from 'app/layout/Layout';
import Qualities from 'app/components/Qualities/Qualities';
import Ecommerce from 'app/components/EcommerceJourney/Ecommerce';
import BusinessGrowth from 'app/components/BusinessGrowth/BusinessGrowth';
import WhyChooseUs from 'app/components/WhyChooseUs/WhyChooseUs';
import TaxFilling from 'app/components/TaxFillingService/TaxFilling';

function Home() {
  return (
    <Layout>

      {/* ========== Growth ========== */}
      <Growth />

      {/* <Divider /> */}

      {/* ========== Work ========== */}
      {/* <Work /> */}

      {/* ========== Qualities ========== */}
      <Qualities />

      <Divider />

      {/* ========== Provide ========== */}
      <Provide />

      <Divider />

      {/* ========== Explore ========== */}
      <Explore />

      <Divider />

      {/* ========== Need Help ========== */}
      {/* <NeedHelp />

      <Divider /> */}

      {/* ========== FeaturedService ========== */}
      <FeaturedService />

      <Divider />

      {/* ========== Pricing ========== */}
      {/* <Pricing />

      <Divider /> */}

      {/* ========== Business Growth ========== */}
      <BusinessGrowth />

      <Divider />

      {/* ========== E-commerce Journey ========== */}
      <Ecommerce />

      <Divider />

      {/* ========== Testimonial ========== */}
      {/* <Testimonial /> */}

      <Divider />

      {/* ========== WhyChooseUs ========== */}
      <WhyChooseUs />

      <Divider />

      {/* ========== Tax Filling Return Service ========== */}
      <TaxFilling />

      <Divider />

      {/* ========== Newsletter ========== */}
      <Newsletter />

      <Divider />

    </Layout>
  );
}

export default Home;