import {
  AccountBalanceTwoTone,
  FlagCircle,
  MonetizationOn,
} from "@mui/icons-material";
import { Box, Button, Divider, Radio, Typography } from "@mui/material";
import colors from "app/style/colors";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

function BusinessBanking() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: AgentRegister.js ~ line 40 ~ AgentRegister ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // *Getting Value from Local Storage
  let packageName = localStorage.getItem("package");

  const [selectedRadioValue, setSelectedRadioValue] = useState("noBankAccount");

  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    const indexExpeditedValue = newDetailsData.findIndex(
      (item) => item[0] === "Business Banking"
    );
    if (indexExpeditedValue === -1) {
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["Business Banking", true]],
      };
      dispatch(processContactDetails(obj));
    } else {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "Business Banking"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: duplicate,
      };
      dispatch(processContactDetails(obj));
    }
  };

  // Store Data and merge with previous data

  const { handleSubmit } = useForm();

  const [businessData, setBusinessData] = useState("");
  console.log(
    "🚀 ~ file: BusinessBanking.js ~ line 46 ~ BusinessBanking ~ businessData",
    businessData
  );

  const agentInfo = async (data) => {
    console.log(
      "🚀 ~ file: AgentRegister.js ~ line 44 ~ agentInfo ~ data",
      data
    );
    // setLoading(true)
    try {
      setBusinessData("");
      let obj = {};
      console.log("selectedRadioValue", selectedRadioValue);
      if (selectedRadioValue === "yesHaveBankAccount") {
        console.log("yesHaveBankAccount");
        obj = {
          ...companyDetails,
          businessBanking: true,
        };
      } else {
        obj = {
          ...companyDetails,
          businessBanking: false,
        };
      }
      setBusinessData(obj);
      console.log(obj);
      dispatch(processContactDetails(obj));
      navigate(
        `${
          packageName === "silver" ? "/process-postage" : "/process-taxInvite"
        }`
      );
    } catch (error) {
      console.log(
        "🚀 ~ file: AgentRegister.js ~ line 150 ~ agentInfo ~ error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(agentInfo)}>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccountBalanceTwoTone
            sx={{ color: colors.WaterBlue, fontSize: "36px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Setting up Small Business Banking
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ fontWeight: "bold", color: colors.secondary, my: 3 }}>
          Open your new account over the phone. No financial center visit
          required.
        </Typography>
        <Typography sx={{ color: colors.secondary }}>
          We understand your time as a business owner is valuable; apply for an
          account without having to visit a financial center. A small business
          banking specialist will contact you when your state formation filing
          is completed to open your account over the phone.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: colors.secondary, width: "360px" }}
          >
            Earn a $100 to $500 bonus* for your business *with qualifying
            activities
          </Typography>
          <MonetizationOn
            sx={{
              color: colors.WaterBlue,
              fontSize: "36px",
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{ my: 4 }}>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "6px",
              p: 2,
              my: 3,
              cursor: "pointer",
            }}
          >
            <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
              <Radio
                checked={selectedRadioValue === "yesHaveBankAccount"}
                onChange={handleChange}
                value="yesHaveBankAccount"
                name="radio-buttons"
              />
              <Typography variant="body1" sx={{ color: colors.secondary }}>
                Yes, please have a Bank of America Small Business Specialist
                contact me
              </Typography>
            </Box>
            <Divider sx={{ my: 3 }} />
            <Typography sx={{ color: colors.secondary, my: 2 }}>
              <span style={{ fontWeight: "bold" }}>
                Earn a bonus from $100 to $500{" "}
              </span>
              with qualifying activities For a limited time, you could earn a
              bonus of $100 to $500* with a new Business Advantage Banking
              account and eligible business credit card from Bank of America.1{" "}
              <span style={{ color: colors.LavenderMist }}>View Details</span>
            </Typography>
            <Typography sx={{ color: colors.secondary, my: 2 }}>
              <span style={{ fontWeight: "bold" }}>Earn $100 </span>when you
              open a new eligible Bank of America small business checking
              account with a specialist and make Qualifying Deposits of $1,000
              or more within the first 30 days of new account opening.
            </Typography>
            <Typography
              sx={{ color: colors.secondary, fontWeight: "bold", my: 3 }}
            >
              OR
            </Typography>
            <Typography sx={{ color: colors.secondary, my: 2 }}>
              <span style={{ fontWeight: "bold" }}>Earn $200</span> when you
              open a new eligible Bank of America small business checking
              account with a specialist and make qualifying deposits of $3,000
              or more within the first 30 days of new account opening.*
            </Typography>
            <Typography sx={{ color: colors.secondary, my: 2 }}>
              <span style={{ fontWeight: "bold" }}>
                Earn an additional $300
              </span>{" "}
              statement credit when you are approved for and open an eligible
              Bank of America small business credit card and make at least
              $3,000 in new Net Purchases within 90 days of card account
              opening.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "6px",
              p: 2,
              my: 3,
              cursor: "pointer",
            }}
          >
            <Box sx={{ mx: 1, display: "flex", alignItems: "center" }}>
              <Radio
                checked={selectedRadioValue === "noBankAccount"}
                onChange={handleChange}
                value="noBankAccount"
                name="radio-buttons"
              />
              <Typography variant="body1" sx={{ color: colors.secondary }}>
                No, not at this time
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: colors.secondary,
              mt: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            One owner of the company is required to have a physical residence in
            the U.S. to open a small business bank account.
            <FlagCircle sx={{ color: colors.WaterBlue, fontSize: "38px" }} />
          </Typography>
          <Typography sx={{ color: colors.secondary, my: 4 }}>
            By selecting <span style={{ fontWeight: "bold" }}>"Yes"</span>, you
            consent to being contacted by Bank of America at the contact
            information you provided. You understand that you are not required
            to submit this information as a condition of purchasing any Bank of
            America product or services. By selecting "Yes", you also consent to
            sharing information related to your business with Bank of America
            including, if available, articles of formation and/or Employer
            Identification Number, which are required to open a new account.
          </Typography>
          <Typography sx={{ color: colors.secondary, my: 2 }}>
            Bank of America, N.A. Member FDIC. © 2022 Bank of America
            Corporation.
            <span style={{ color: colors.LavenderMist, cursor: "pointer" }}>
              {" "}
              Disclaimer
            </span>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
        }}
      >
        <Button
          component={Link}
          to="/process-EIN-number"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          // onClick={handleSubmit}
          // component={Link}
          // to={
          //   packageName === "silver" ? "/process-postage" : "/process-taxInvite"
          // }
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default BusinessBanking;
