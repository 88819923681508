//* Import from MUI
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stepper, Step, StepButton, Button, Typography, Container, Grid, FormControl, TextField, InputAdornment, IconButton, InputLabel, OutlinedInput, Autocomplete } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

//* Import Component
import 'react-toastify/dist/ReactToastify.css';
import { Error } from 'app/components/UI/Error';
import Images from 'assets/Images';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import colors from 'app/style/colors';
import Invoice from '../Dialog/Invoice';
import Layout from 'app/layout/Layout';
import { Service } from 'app/config/service';
import useAuth from 'app/hooks/useAuth';
import { emailRegex } from 'app/utils';

const steps = ['Personal Details', 'Business Details', 'View Complete Details', 'Payment Details'];

function ServicePayment() {

  const { user } = useAuth()
  const userId = user?.split("_")[0]

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigate = useNavigate();
  const location = useLocation();

  // Stepper Form Section's Constant
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [loading, setLoading] = useState(false);

  //const [cardDetails, setCardDetails] = useState(false);
  const [customerData, setData] = useState('');
  const [customerDetail, setCustomerDetail] = useState('');
  const [openInvoice, setOpenInvoice] = useState(false);
  const [userData, setUserData] = useState({});


  //* Get Order Details API
  const getUserInfo = async () => {
    try {
      const { Data } = await Service.getUserInfo(userId)
      setUserData(Data)
      //console.log('file: ServicePayment.js => line 58 => userData?.FirstName => userData?.FirstName', userData?.FirstName);


    } catch (error) {
      console.log('file: MyOrders.js => line 71 => getUserInfo => error', error);
    }
  }


  // *Subsection 1
  const customerInfo = async (data) => {
    setLoading(true)
    try {
      setData("")
      let obj = [
        { userF_Name: data.f_name },
        { userL_Name: data.l_name },
        { userEmail: data.email },
        { phone: data.phone },
        { pass: data.pass }
      ]

      const { Data } = await Service.checkEmail(data.email)
      if (Data === "Email Already Exist") {
        Swal.fire({
          icon: 'error',
          html: "<p style='color: red'>" + `${Data}` + "</p>",
          confirmButtonColor: colors.primary,
        })
      }
      else {
        handleNext()
        setData(obj)
      }
    } catch (error) {
      console.log('file: ServicePayment.js => line 14 => ServicePayment => error', error)
    } finally {
      setLoading(false)
    }
  }

  // *Password Section
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInvoiceDialog = () => {
    setOpenInvoice(!openInvoice);
    navigate('/')
  };

  //* Country Dropdown List
  const countryDropDownList = [
    { label: 'Pakistan' }]

  //* State Dropdown List
  const stateDropDownList = [
    { label: 'Sindh' },
    { label: 'Punjab' },
    { label: 'Khyber Pakhtunkhwa' },
    { label: 'Balochistan' },
    { label: 'Gilgit Baltistan' },
  ]
  //* City Dropdown List
  const cityDropDownList = [
    { label: 'Karachi' },
    { label: 'Hyderabad' },
    { label: 'Thatta' },
    { label: 'Lahore' },
    { label: 'Multan' },
    { label: 'Peshawar' },
    { label: 'Islamabad' },
  ]

  // *Sweeet Alert Try
  const successAlert = (Message) => {

    Swal.fire({
      title: 'Thank You',
      html: "<p style='color: red'>" + ' A Verification link has been sent to your email id' + "</p>" + `${Message
        }`,
      icon: 'success',
      confirmButtonColor: colors.primary,
    }).then(() => {
      setOpenInvoice(!openInvoice);
    });
  }
  const errorAlert = (Message) => {

    Swal.fire({
      title: 'oops',
      html: `${Message}`,
      icon: 'error',
      confirmButtonColor: colors.primary,
    })
  }


  //Subsection 2
  const customerAdd = async (data) => {
    setLoading(true)
    try {
      setCustomerDetail("")
      let obj = [
        ...customerData,
        { add: data.resBus_add },
        { city: data.city },
        { state: data.state },
        { country: data.country }
      ]
      setCustomerDetail(obj)

      handleNext()
    } catch (error) {
      console.log('file: ServicePayment.js => line 14 => ServicePayment => error', error)
    } finally {
      setLoading(false)
    }
  }

  // *Subsection 3
  const [CvvValue, setCvvValue] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [orderId, setOrderId] = useState("");
  const cardInfo = async (data) => {
    //setCardDetails(true)
    try {
      let obj = [
        { card_number: data.card_num },
        { owner_name: data.owner_name },
        { cvc: data.cvc },
        { expiry_through: data.expiry_through },
        { payment: data.payment }
      ]
      console.log('file: ServicePayment.js => line 39 => cardInfo => obj', obj)
      successAlert()

    } catch (error) {
      console.log('file: ServicePayment.js => line 14 => ServicePayment => error', error)
    } finally {
      //setCardDetails(false)
    }
  }

  //Service Order API Obj
  const serviceOrder = async () => {
    try {
      let obj = {
        Password: customerDetail[4]?.pass ? customerDetail[4]?.pass : null,
        ServiceOrders: {
          ServicePreRequisiteID: location.state.serviceId,
          FirstName: customerDetail[0]?.userF_Name ? customerDetail[0]?.userF_Name : userData?.FirstName,
          LastName: customerDetail[1]?.userL_Name ? customerDetail[1]?.userL_Name : userData?.LastName,
          Email: customerDetail[2]?.userEmail ? customerDetail[2]?.userEmail : userData?.Email,
          Phone: customerDetail[3]?.phone ? customerDetail[3]?.phone : userData?.Phone,
          Address: customerDetail[5]?.add ? customerDetail[5]?.add : userData?.Address,
          City: customerDetail[6]?.city ? customerDetail[6]?.city : userData?.City,
          State: customerDetail[7]?.state ? customerDetail[7]?.state : userData?.State,
          Country: customerDetail[8]?.country ? customerDetail[8]?.country : userData?.Country,
        },
      }
      const { Status, ResponseCode, Message, Data } = await Service.postServiceOrder(obj)
      if (Status === true && ResponseCode === 201) {
        successAlert(Message)
        setOrderId(Data)
        handleNext()
      }
      else if (Status === true && ResponseCode === 500) {
        errorAlert(Message)
      }

    } catch (error) {
      console.log('file: ServicePayment.js => line 14 => ServicePayment => error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user) {
      getUserInfo()
      setActiveStep(2)
    } else {
      setActiveStep(0)
      setUserData([])
    }

  }, [user])

  return (
    <Layout>
      <Container sx={{ width: '70%', mt: 4 }}>
        {/* ====== Invoice dialog ===== */}
        <Invoice id={orderId} open={openInvoice} handleClose={handleInvoiceDialog} />

        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          Get the Service
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepButton disabled={user ? true : false} onClick={() => {
                  setActiveStep(index)
                }}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 &&
            <Grid container xs={12} md={12} justifyContent="space-around">

              <Grid item xs={12} md={4} sx={{ padding: '6px' }}>
                <img src={Images.stepperDetails} width="400px" alt="" />
              </Grid>
              <Grid item xs={12} md={5}
                sx={{
                  borderRadius: '14px', my: 4
                }}>
                <Box component={"form"} onSubmit={handleSubmit(customerInfo)}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="First Name"
                      sx={{ width: '350px' }}
                      error={errors?.f_name?.message && (true)}
                      {...register("f_name", {
                        required: 'Please enter your first name.',
                      })}
                    />
                    {errors?.f_name?.message && (
                      <Error message={errors?.f_name?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Last Name"
                      sx={{ width: '350px' }}
                      error={errors?.l_name?.message && (true)}
                      {...register("l_name", {
                        required: 'Please enter your last name.',
                      })}
                    />
                    {errors?.l_name?.message && (
                      <Error message={errors?.l_name?.message} />
                    )}
                  </FormControl>

                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Email"
                      type="email"
                      sx={{ width: '350px' }}
                      error={errors?.email?.message && (true)}
                      {...register("email", {
                        required: 'Please enter your email.',
                        pattern: {
                          value: emailRegex,
                          message: 'Please enter a valid email address.'
                        }
                      })}
                    />
                    {errors?.email?.message && (
                      <Error message={errors?.email?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Phone"
                      sx={{ width: '350px' }}
                      error={errors?.phone?.message && (true)}
                      {...register("phone", {
                        required: 'Please enter phone number.',
                      })}
                    />
                    {errors?.phone?.message && (
                      <Error message={errors?.phone?.message} />
                    )}
                  </FormControl>

                  <FormControl variant="outlined" fullWidth sx={{ my: 1 }}>
                    <InputLabel error={errors?.pass?.message && values?.password === "" && (true)}>Password</InputLabel>
                    <OutlinedInput
                      label="Password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={errors?.pass?.message && values?.password === "" && (true)}
                      {...register("pass", {
                        required: 'Please create your pass.',
                      })}
                      onChange={handleChange('password')}
                    />
                    {errors?.pass?.message && values?.password === "" && (
                      <Error message={errors?.pass?.message} />
                    )}
                  </FormControl>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      variant="contained"
                      type="submit"
                      loading={loading}
                      sx={{
                        borderRadius: "10px",
                        p: 1,
                        my: 2,
                        '&:hover': {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          }
          {activeStep === 1 &&
            <Grid container xs={12} md={12} justifyContent="space-around">

              <Grid item xs={12} md={4} sx={{ padding: '6px' }}>
                <img src={Images.businessStepperDetails} width="370px" alt="" />
              </Grid>
              <Grid item xs={12} md={5}
                sx={{
                  borderRadius: '14px', my: 4
                }}>
                <Box component={"form"} onSubmit={handleSubmit(customerAdd)}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      id="outlined-textarea"
                      multiline
                      variant="outlined"
                      label="Business/Ressidential Address"
                      sx={{ width: '350px' }}
                      error={errors?.resBus_add?.message && (true)}
                      {...register("resBus_add", {
                        required: 'Please enter your business/ressidential address.',
                      })}
                    />
                    {errors?.data?.resBus_add?.message && (
                      <Error message={errors?.data.resBus_add?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={countryDropDownList}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}
                        variant="outlined"
                        label="Country"
                        sx={{ width: '350px' }}
                        error={errors?.country?.message && (true)}
                        {...register("country", {
                          required: 'Please enter your country.',
                        })}
                      />}
                    />
                    {/* <TextField
                      variant="outlined"
                      label="Country"
                      sx={{ width: '350px' }}
                      error={errors?.country?.message && (true)}
                      {...register("country", {
                        required: 'Please enter your country.',
                      })}
                    /> */}
                    {errors?.country?.message && (
                      <Error message={errors?.country?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={stateDropDownList}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}
                        variant="outlined"
                        label="State"
                        sx={{ width: '350px' }}
                        error={errors?.state?.message && (true)}
                        {...register("state", {
                          required: 'Please enter your state.',
                        })}
                      />}
                    />
                    {/* <TextField
                      variant="outlined"
                      label="State"
                      sx={{ width: '350px' }}
                      error={errors?.state?.message && (true)}
                      {...register("state", {
                        required: 'Please enter your state.',
                      })}
                    /> */}
                    {errors?.state?.message && (
                      <Error message={errors?.state?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={cityDropDownList}
                      sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params}
                        variant="outlined"
                        label="City"
                        sx={{ width: '350px' }}
                        error={errors?.city?.message && (true)}
                        {...register("city", {
                          required: 'Please enter your city.',
                        })}
                      />}
                    />
                    {/* <TextField
                      variant="outlined"
                      label="City"
                      sx={{ width: '350px' }}
                      error={errors?.city?.message && (true)}
                      {...register("city", {
                        required: 'Please enter your city.',
                      })} 
                    />*/}
                    {errors?.city?.message && (
                      <Error message={errors?.city?.message} />
                    )}
                  </FormControl>

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      variant="text"
                      onClick={handleBack}
                      sx={{
                        borderRadius: "10px",
                        p: 1,
                        my: 2,

                      }}>
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      variant="contained"
                      type="submit"
                      loading={loading}
                      sx={{
                        borderRadius: "10px",
                        p: 1,
                        my: 2,
                        '&:hover': {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          }
          {activeStep === 2 &&
            <Grid container sx={{
              display: 'flex',
              mt: '30px', boxShadow: 3,
              borderRadius: '30px', p: 3, width: '100%', mx: "auto"
            }}>

              <Grid item xs={12} md={5} sx={{ textAlign: 'center', p: 2 }} >
                <Typography variant='h5'>Customer Details</Typography>
                <Typography variant='body2' sx={{ color: colors.secondary }}>Make sure everything is <br />
                  correct about you.</Typography>
                <img src={Images.customerDetails} alt="Customer Details"
                  style={{
                    width: '100%',
                    textAlign: "center", marginTop: '20px'
                  }} />
                <Button
                  disabled={user ? true : false}
                  variant="text"
                  onClick={handleBack}
                  sx={{
                    borderRadius: "10px",
                    float: 'left',
                    my: -1,

                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} md={7} sx={{ textAlign: 'left', p: 2, marginTop: '20px' }}>
                <Typography sx={{
                  borderColor: colors.primary,
                  borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                }}>
                  Name:
                  <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {customerDetail[0]?.userF_Name ?
                      customerDetail[0]?.userF_Name + " " + customerDetail[1]?.userL_Name :
                      userData ? userData?.FirstName + " " + userData?.LastName : ''
                    }
                  </Typography>
                </Typography>
                <Typography sx={{
                  borderColor: colors.primary,
                  borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                }}>
                  Email:
                  <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {customerDetail[2]?.userEmail ? customerDetail[2]?.userEmail : userData?.Email}
                  </Typography>
                </Typography>
                <Typography sx={{
                  borderColor: colors.primary,
                  borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                }}>
                  Service Name:
                  <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {location.state.serviceName}
                  </Typography>
                </Typography>
                <Typography sx={{
                  borderColor: colors.primary,
                  borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                }}>
                  Service Charges:
                  <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {location.state.serviceCharges}
                  </Typography>
                </Typography>
                <Typography sx={{
                  borderColor: colors.primary,
                  borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                }}>
                  State of Formation:
                  <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {location.state.serviceState}
                  </Typography>
                </Typography>
                <Typography sx={{
                  borderColor: colors.primary,
                  borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                }}>
                  Phone:
                  <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {customerDetail[3]?.phone ? customerDetail[3]?.phone : userData?.Phone}
                  </Typography>
                </Typography>

                <Typography
                  sx={{
                    borderColor: colors.primary,
                    borderRadius: '5px', py: 0.75, color: colors.black, width: "100%"
                  }}
                >
                  Buiness/Residential Address:
                  <Typography component="span"
                    style={{ wordWrap: "break-word", paddingLeft: '6px', color: colors.secondary }}>
                    {customerDetail[5]?.add ? customerDetail[5]?.add : userData?.Address}
                  </Typography>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <Typography sx={{
                    borderColor: colors.primary,
                    borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                  }}>
                    Country:
                    <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '15px', color: colors.secondary }}>
                      {customerDetail[8]?.country ? customerDetail[8]?.country : userData?.Country}
                    </Typography>
                  </Typography>
                  <Typography sx={{
                    borderColor: colors.primary,
                    borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                  }}>
                    State:
                    <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '15px', color: colors.secondary }}>
                      {customerDetail[7]?.state ? customerDetail[7]?.state : userData?.State}
                    </Typography>
                  </Typography>
                  <Typography sx={{
                    borderColor: colors.primary,
                    borderRadius: '5px', py: 0.75, width: '100%', color: colors.black
                  }}>
                    City:
                    <Typography component="span" style={{ wordWrap: "break-word", paddingLeft: '15px', color: colors.secondary }}>
                      {customerDetail[6]?.city ? customerDetail[6]?.city : userData?.City}
                    </Typography>
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                  <Button variant="contained"
                    onClick={handleNext}
                    sx={{
                      borderRadius: '15px', mt: 2,
                    }}>
                    Continue to Payment
                  </Button>
                  <Button variant="outlined"
                    // onClick={successAlert}
                    onClick={serviceOrder}
                    sx={{
                      borderRadius: '15px', mt: 2, height: '50px'
                    }}>
                    Confirm Order
                  </Button>

                </Box>

              </Grid>

            </Grid>
          }
          {activeStep === 3 &&
            <Grid container xs={12} md={12} justifyContent="space-around">

              <Grid item xs={12} md={4} sx={{ padding: '6px' }}>
                <img src={Images.creditCard} alt='Credit Card' style={{
                  width: '350px',
                  textAlign: "center", margin: 'auto', marginTop: '20px', borderRadius: '12px'
                }} />
                <Button
                  variant="text"
                  onClick={handleBack}
                  sx={{
                    borderRadius: "10px",
                    float: 'left',
                    my: 1,

                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} md={5}
                sx={{
                  borderRadius: '14px', my: 4
                }}>
                <Box component={"form"} onSubmit={handleSubmit(cardInfo)}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Card Number"
                      type="number"
                      sx={{ width: '350px' }}
                      error={errors?.card_num?.message && (true)}
                      {...register("card_num", {
                        required: 'Please enter your credit card number.',
                      })}
                    />
                    {errors?.card_num?.message && (
                      <Error message={errors?.card_num?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Name"
                      type="text"
                      sx={{ width: '350px' }}
                      error={errors?.owner_name?.message && (true)}
                      {...register("owner_name", {
                        required: 'Please enter your name.',
                      })}
                    />
                    {errors?.owner_name?.message && (
                      <Error message={errors?.owner_name?.message} />
                    )}
                  </FormControl>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Amount"
                      type="number"
                      sx={{ width: '350px' }}
                      error={errors?.payment?.message && (true)}
                      {...register("payment", {
                        required: 'Please enter your payment.',
                      })}
                    />
                    {errors?.payment?.message && (
                      <Error message={errors?.payment?.message} />
                    )}
                  </FormControl>
                  <Grid container spacing={2} item xs={12} md={12}>

                    <Grid item xs={12} md={5}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          variant="outlined"
                          label="CVC"
                          type="number"
                          min="0"
                          value={CvvValue && Math.max(0, CvvValue)}
                          onChange={e => setCvvValue(e.target.value ? Number(e.target.value) : e.target.value)}
                          error={errors?.cvc?.message && (true)}
                          {...register("cvc", {
                            required: 'Please enter your cvc.',
                            validate: {
                              maxValue: value => (value >= 1 && value.length === 3) || 'CVC must be 3-digit positive number',
                            }
                          })}
                        />
                        {errors?.cvc?.message && (
                          <Error message={errors?.cvc?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}>

                          <DatePicker
                            variant="outlined"
                            label="Expiry Through"
                            value={dateValue}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                              setValue("expiry_through", newValue);
                            }}
                            renderInput={(params) => <TextField {...params}
                              sx={{ color: 'transparent' }}
                              error={errors?.expiry_through?.message && (true)}
                              {...register("expiry_through", {
                                required: 'Please enter your expiry.',
                              })}
                            />}
                          />
                          {errors?.expiry_through?.message && (
                            <Error message={errors?.expiry_through?.message} />
                          )}
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained"
                      // onClick={successAlert}
                      type='submit'
                      sx={{
                        borderRadius: '15px', mt: 2, height: '50px', width: '200px'
                      }}>
                      Pay
                    </Button>
                  </Box>
                </Box>

              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </Layout>
  );
}

export default ServicePayment
