import React from "react";
import {
  AllInboxTwoTone,
  CheckCircle,
  AccessAlarmTwoTone,
  AccountBalanceWalletTwoTone,
  MarkEmailReadTwoTone,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

//* Imported Components
import colors from "app/style/colors";

function PackageGold() {
  return (
    <Box>
      <Box sx={{ borderRadius: "12px", boxShadow: 3, py: 4, px: 5, my: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AllInboxTwoTone
            sx={{ color: colors.primary, fontSize: "36px", mr: 2 }}
          />
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Premium Service Package
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary }}>
          Upgrade for only $149 to receive these 3 premium services
          <span style={{ fontWeight: "bold", color: colors.black }}>
            — up to $220 value!
          </span>
        </Typography>
        <Box
          sx={{
            my: 3,
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            p: 4,
            boxSizing: "border-box",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
            <AccessAlarmTwoTone
              sx={{ fontSize: "42px", color: colors.TealishGreen, mr: 3 }}
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Expedited Filing Service{" "}
                  <span style={{ color: colors.primary, marginLeft: "10px" }}>
                    FREE
                  </span>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      textDecorationLine: "line-through",
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    $50
                  </Typography>
                  <CheckCircle sx={{ ml: 2, color: colors.lightPrimary }} />
                </Box>
              </Box>
              <Typography sx={{ color: colors.secondary, my: 2 }}>
                The typical state filing time for Alaska is{" "}
                <span style={{ fontWeight: "bold" }}> 3 Weeks </span> Receive
                expedited service for your filing in{" "}
                <span style={{ fontWeight: "bold" }}>1 Business Day</span>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <AccountBalanceWalletTwoTone
              sx={{ fontSize: "48px", color: colors.LavenderMist, mr: 3 }}
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Business Contract Templates{" "}
                  <span style={{ color: colors.primary, marginLeft: "10px" }}>
                    FREE
                  </span>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      textDecorationLine: "line-through",
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    $40
                  </Typography>
                  <CheckCircle sx={{ ml: 2, color: colors.lightPrimary }} />
                </Box>
              </Box>
              <Typography sx={{ color: colors.secondary, my: 2 }}>
                Your search for the right legal template stops here. Get access
                to
                <span
                  style={{
                    color: colors.LavenderMist,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  25+ high-quality legal documents
                </span>{" "}
                written by a business attorney to help keep your business
                contractually protected.
              </Typography>
              <Typography sx={{ color: colors.secondary, my: 2 }}>
                <span style={{ fontWeight: "bold" }}>Why You Need It:</span>{" "}
                It’s important to keep your business contractually protected,
                but it’s hard to know where to go to get an agreement or legal
                form and paying an attorney to draft them can be expensive.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <MarkEmailReadTwoTone
              sx={{ fontSize: "48px", color: colors.JaggedIce, mr: 3 }}
            />
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Domain Name + Business Email Address
                  <span style={{ color: colors.primary, marginLeft: "10px" }}>
                    FREE
                  </span>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      textDecorationLine: "line-through",
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    $150
                  </Typography>
                  <CheckCircle sx={{ ml: 2, color: colors.lightPrimary }} />
                </Box>
              </Box>
              <Typography sx={{ color: colors.secondary, mt: 2 }}>
                Increase your credibility with a professional email address. 65%
                of consumers believe a company branded email is more credible
                than a business using a free email account.
              </Typography>
              <Typography sx={{ color: colors.secondary, my: 2 }}>
                Get 1 full year of{" "}
                <span style={{ fontWeight: "bold" }}>FREE</span> domain name &
                email service. At the end of the year, you will have the option
                to renew or cancel service.
              </Typography>
              <Typography sx={{ color: colors.secondary, my: 2 }}>
                <span style={{ fontWeight: "bold" }}>Why You Need It:</span>
                Every website needs its own domain, so this will save you a
                necessary expense and help your business appear more official by
                using a matching domain email versus an email address from an
                automated service like Gmail or Hotmail.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button component={Link} to="/company-information" variant="outlined">
          Back
        </Button>
        <Button
          component={Link}
          to="/process-members"
          variant="contained"
          //onClick={handleSubmit}
        >
          Get Upgrade & Bonus
        </Button>
      </Box>
      <Typography
        sx={{
          float: "right",
          textDecoration: "none",
          width: "210px",
          color: colors.primary,
          mb: 4,
        }}
        component={Link}
        to="/process-members"
        //variant="outlined"
        //onClick={handleSubmit}
      >
        No thanks, I will pass on this opportunity.
      </Typography>
    </Box>
  );
}

export default PackageGold;
