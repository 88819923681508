import React, { useState } from 'react';
import { AddToPhotos, ArrowForward, } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

//*Import Components
import Layout from 'app/layout/Layout'
import colors from 'app/style/colors'
import Images from 'assets/Images'

function VirtualBankAccount() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (

    <Layout>
      <Container>
        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.learningSectionBanner})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
        }
        }>


          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            Virtual Bank Account Formation
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '140px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '100px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>
        <Grid sx={{ my: 4 }}>
          <Typography variant="h6" sx={{ color: colors.black, fontWeight: 'bold', textAlign: 'center', my: 2 }}></Typography>
          <Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="space-evenly" sx={{ my: 0 }}>
              <Grid item md={3.6} sx={{
                textAlign: 'center',
                minHeight: 300,
                '&:hover': {
                  borderRadius: '14px',
                  boxShadow: 3,
                  cursor: "pointer"
                }
              }}>

                <img src={Images.debt} alt="Keeping Liability intact"
                  style={{ width: '80px', cursor: 'pointer' }}
                />
                <Box sx={{ py: 1 }}>
                  <Typography variant="h6" sx={{ my: 1.5, fontWeight: 700 }} >Keeps your liability intact!</Typography>
                  <Typography variant="body1" sx={{ color: colors.textPrimary, mx: 2 }} >A business Bank account keeps your business finances separate from personal and not liable to any business debt.</Typography>
                </Box>
              </Grid>
              <Grid item md={3.6} sx={{
                textAlign: 'center',
                minHeight: 300,
                '&:hover': {
                  borderRadius: '14px',
                  boxShadow: 3,
                  cursor: "pointer"
                }
              }}>
                <img src={Images.bars} alt="Business Like Pro"
                  style={{ width: '80px', cursor: 'pointer' }}
                />
                <Box sx={{ py: 1 }}>
                  <Typography variant="h6" sx={{
                    my: 1.5, fontWeight: 700
                  }} >Present like a Pro Business already!</Typography>
                  <Typography variant="body1" sx={{ color: colors.textPrimary, mx: 2 }} >Paying suppliers and contractors through a business bank account makes you look more credible to develop long-term business relations.</Typography>
                </Box>
              </Grid>
              <Grid item md={3.6} sx={{
                textAlign: 'center',
                minHeight: 300,
                '&:hover': {
                  borderRadius: '14px',
                  boxShadow: 3,
                  cursor: "pointer"
                }
              }}>
                <img src={Images.security} alt="Pay Taxes Easire"
                  style={{ width: '80px', cursor: 'pointer' }}
                />
                <Box sx={{ py: 1 }}>
                  <Typography variant="h6" sx={{ my: 1.5, fontWeight: 700 }} >Makes it easier to manage & pay taxes! </Typography>
                  <Typography variant="body1" sx={{ color: colors.textPrimary, mx: 2 }} >Business purchases and transactions from a dedicated bank account will facilitate tax filing with the IRS.</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* ========== Header Lower Section ========== */}
        <Grid container sx={{ bgcolor: colors.lightGray, justifyContent: 'space-between' }}>
          <Grid item xs={12} md={6}
          >
            <Typography variant="h4" sx={{
              color: colors.black, textAlign: 'center', mt: 10,
              fontWeight: 'bold',
            }}>
              Virtual Bank Account Formation
            </Typography>
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '140px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px',
                mb: 1
              }}
            />
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '100px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px'
              }}
            />
            <Typography sx={{ my: 3, ml: 4 }}>Once you have decided to go for an e-commerce business and have your Company registered in the country and state of your choice, you should get a business bank account and second step. Creating an online bank account for your business is extremely important to strengthen your business and keep your business and personal finances separate. Using the same bank account for personal and business funds will put your personal assets on the stack in case of any business debts, no matter what business structure you had opted for. With a business bank account, you can keep a record of your business finances and monitor your budget and spending in a better way. </Typography>

            <Button
              variant="contained"
              endIcon={<ArrowForward />}
              sx={{ my: 3, borderRadius: 8, mx: 8, py: 2, fontWeight: 'bold', fontSize: '16px', width: '340px' }}
            >
              Open Your Bank Account
            </Button>

          </Grid>
          <Grid item xs={12} md={5} sx={{
            background: `url(${Images.bankAccountFormation
              })`, mr: 4,
            position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
          }
          } />

        </Grid>

        <Grid sx={{ my: 7 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1, color: colors.black }}>There are a few good online bank options if you do not live in the country where your business is registered.</Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '200px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
          <Box>
            <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
              <Grid item xs={12} md={3.5}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1, height: '150px'
                }}>
                <Box >
                  <Box sx={{ textAlign: 'justify', mt: 3 }}>
                    <List component="nav" >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 0)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Bank Account formation in the US" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 1)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Bank Account formation in the UK" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1
                }}
              >
                {
                  selectedIndex === 0 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 4 }}
                    >Bank Account formation in the USA</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mb: 4 }}>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>A US bank account for your business is important if you are starting your business in the US market and e-commerce platforms like Amazon, eBay, and Etsy. To pay your suppliers in the US and receive funds from your buyers having a US bank account is necessary. The USA has strict laws to regulate how banks can operate for the non-residents and businesses that are operated from outside the country to prevent money laundering and any international criminal action. Opening a physical bank account in the USA is a bit complex for non-residents but there are multiple virtual banks you can open your business account with, and we can help you out with that. </Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, my: 2, fontSize: "14px" }}>What are the requirements to open a virtual bank in the US?</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>The photo ID of the Business Director (Passport/ Driver’s License)</li>
                        <li>Proof of personal address</li>
                        <li>Article of incorporation/organization</li>
                        <li>Proof of business address (Asked by certain banks)</li>
                        <li>EIN confirmation/verification from IRS</li>
                      </ul>
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>The online banks that offer business account to non-residents include:</Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>1.	Mercury  </Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '100px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>Mercury is the most recommended virtual bank that offers business account to non-residents also. The benefits of having a mercury account include:</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, my: 2, fontSize: "14px" }}>
                        <li>Incoming and outgoing wires, both local and international </li>
                        <li>Virtual debit cards</li>
                        <li>No monthly fee</li>
                        <li>User-friendly mobile app</li>
                        <li>No SSN or ITIN required (Best for non-US residents)</li>
                        <li>Free ACH transfers</li>
                      </ul>
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>You can open a mercury account if you have the Basic required documents in hand, and it takes around 5-7 business days to get your mercury business account activated. Although it’s easier to sign up for a mercury account, we recommend getting help from someone with experience to avoid application rejection.</Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>2.	TransferWise</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '150px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>Opening a virtual business and a personal account with Wise is very easy and hardly takes a few minutes. But to activate it and get account details, you’ll have to pay a small fee. It takes around 5-7 business days to get an online business account with Wise. </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Requirements to open a Wise account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>ID document of the applicant (Passport or Driving License)</li>
                        <li>Proof of residential and business address (Required in certain cases)</li>
                        <li>Business and personal details</li>
                        <li>US phone number</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Wise</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Virtual debit card</li>
                        <li>Option to open and hold 40 currencies in the same account</li>
                        <li>Easy to operate</li>
                        <li>Great customer support</li>
                        <li>You can transfer funds in a wise account using your local debit/credit card or through a wire transfer.</li>
                      </ul>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>3.	Brex</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '70px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>Brex offers an online bank account to manage your start-up business finances. Its easier to open an account with Brex if you have the required documents and information in hand. </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>The requirement to open a Brex account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>ID document of the applicant (Passport or Driving License)</li>
                        <li>Proof of residential and business address (Required in certain cases)</li>
                        <li>Business and personal details</li>
                        <li>EIN Number</li>
                        <li>Article of organization</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Brex</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Virtual credit cards</li>
                        <li>Easy deposit and payments</li>
                        <li>No transaction or account fee</li>
                        <li>You can instantly access your revenue from e-commerce marketplaces</li>
                        <li>Easy tracking of expenses</li>
                        <li>Pay bills or invoices automatically without a fee</li>
                        <li>You can integrate it with QuickBooks, Slack, and many more</li>
                        <li>User-friendly app to manage your account quickly</li>
                      </ul>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>4.	Payoneer</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '110px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>For online sellers and small businesses operating outside of the US, virtual bank accounts like Payoneer are the best solution. It offers business and personal online bank accounts and you can get it verified in just a few days. </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>The requirement to open a Payoneer account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, my: 2, fontSize: "14px" }}>
                        <li>Name and contact details</li>
                        <li>ID document of the applicant (Passport, National ID, or Driving License)</li>
                        <li>Local Bank account details</li>
                        <li>Article of organization</li>
                        <li>IRS including EIN</li>
                        <li>Business license</li>
                        <li>Proof of Business and personal address</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Payoneer</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, my: 2, fontSize: "14px" }}>
                        <li>Get a virtual card just as you deposit $100 in your account</li>
                        <li>Fast payment between buyer and seller in multiple currencies</li>
                        <li>Supported in more than 200 countries</li>
                        <li>Make payments to suppliers or contractors directly through your Payoneer account.</li>
                      </ul>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>5.	Hyperwallet</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '135px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>Hyperwallet is another regulated money service that has partnered up with Amazon. It receives payments from Amazon into the Hyperwallet account, from where it is directly transferred to your local bank account.</Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>The requirement to open a Hyperwallet account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Business and personal details</li>
                        <li>EIN Number</li>
                        <li>Article of organization</li>
                        <li>US phone number</li>
                        <li>Local bank IBAN and Swift code</li>
                        <li>Need to link your US Amazon account to create an account with Hyperwallet</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Hyperwallet</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Deposit payments directly from Amazon to your local account</li>
                        <li>No account fees</li>
                        <li>Falls in Amazon’s approved bank list</li>
                        <li>Easy to create Hyperwallet account</li>
                        <li>Offers account number and routing number that could be used in Amazon sign up</li>
                      </ul>
                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 1 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 4 }}
                    >Bank Account formation in the UK</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mb: 4, fontSize: "14px" }}>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>To open a business bank account in the UK as a non-resident must be a company director and need to have your business registered with UK companies house. As a non-UK resident, there are some online banks that you can open an account with, including: </Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>1.	TransferWise</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '150px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>The process of opening a business or personal Wise account is the same in UK or US. To get account details and order a debit card, you’ll have to pay a small fee to Wise. It takes around 5-7 business days to get an online business account with Wise activated. </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Requirements to open a Wise account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>ID document of the applicant (Passport or Driving License)</li>
                        <li>Proof of residential and business address (Required in certain cases)</li>
                        <li>Business and personal details</li>
                        <li>UK phone number</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Wise</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Multiple Virtual debit cards</li>
                        <li>Option to open and hold 40 currencies in the same account</li>
                        <li>Easy to operate</li>
                        <li>Great customer support</li>
                        <li>You can transfer funds in a wise account using your local debit/credit card or through a wire transfer.</li>
                      </ul>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>2.	Payoneer</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '110px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>For online sellers and small businesses operating from outside of the UK, a virtual bank account like Payoneer is the best solution. It offers business and personal online bank accounts and you can have it opened in just a few days and offers usability of any physical bank. </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>The requirement to open a Payoneer account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, my: 2, fontSize: "14px" }}>
                        <li>Name and contact details</li>
                        <li>ID document of the applicant (Passport, National ID, or Driving License)</li>
                        <li>Local Bank account details</li>
                        <li>Certification of incorporation</li>
                        <li>Proof of Business and personal address</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Payoneer</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, my: 2, fontSize: "14px" }}>
                        <li>Get a virtual card just as you deposit $100 in your account</li>
                        <li>Fast payment between buyer and seller in multiple currencies</li>
                        <li>Supported in more than 200 countries</li>
                        <li>Make payments to suppliers or contractors directly through your Payoneer account.</li>
                      </ul>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, mt: 2 }}>3.	Hyperwallet</Typography>
                      <Divider
                        sx={{
                          bgcolor: colors.primary,
                          width: '135px',
                          padding: '1px',
                          fontSize: '28px'
                        }}
                      />
                      <Typography sx={{ color: colors.textPrimary, my: 2, fontSize: "14px" }}>Hyperwallet is another regulated money service that has partnered up with Amazon. It receives payments from Amazon into the Hyperwallet account, from where it is directly transferred to your local bank account.</Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>The requirement to open a Hyperwallet account</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Business and personal details</li>
                        <li>Certificate of incorporation</li>
                        <li>UK phone number</li>
                        <li>Local bank IBAN and Swift code</li>
                      </ul>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Benefits of opening an account with Hyperwallet</Typography>
                      <ul style={{ lineHeight: 1.5, color: colors.textPrimary, fontSize: "14px" }}>
                        <li>Deposit payments directly from Amazon to your local account</li>
                        <li>No account fees</li>
                        <li>Falls in Amazon’s approved bank list</li>
                        <li>The account can be created with ease</li>
                        <li>It offers an account number and routing number that you can link with your Amazon account.</li>
                      </ul>
                    </Box>
                  </Box>
                }
              </Grid>

            </Grid>
          </Box>
        </Grid >
      </Container >
    </Layout >
  )
}

export default VirtualBankAccount