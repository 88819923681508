//* Import from MUI
import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  CardActions,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Done,
  HelpOutlineRounded,
  LooksOneRounded,
  LooksTwoRounded,
  StarBorderPurple500,
  StarBorderPurple500Rounded,
} from "@mui/icons-material/";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

//* Import Component

import colors from "app/style/colors";
import { BasicPlan, Checkmark, PremiumPlan, StarterPlan } from "assets/Images";
import Images, { Loader } from "assets/Images";
import { Link } from "react-router-dom";
import Layout from "app/layout/Layout";
import { Service } from "app/config/service";
// import Pricing from "app/components/Pricing/Pricing";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

// const packageIncludes = [
//   'Preparing & Filing the Articles of Organization',
//   'Unlimited Name Searches',
//   'FREE Registered Agent Service for a year!',
//   'EIN Business Tax Number',
//   'IRS Form 2553',
//   'Operating Agreement',
// ];

const useStyles = makeStyles({
  text: {
    textDecoration: "none",
    color: "black",
    "&:hover": { color: colors.WaterBlue },
  },
});

// const goldFeatures = [
//   "Preparing and filing Article of Organization.",
//   "One-year free Registered Agent Service",
//   "Operating Agreement",
//   "IRS Form 2553 ",
//   "Online Dashboard Access",
//   "Lifelong Company Alerts ",
//   "Business bank account",
//   "Consultation on Business Tax",
//   "Banking Resolution",
//   "Unlimited Phone and Email support",
//   "EIN",
// ];
// const platinumFeatures = [
//   "Preparing and filing Article of Organization.",
//   "One-year free Registered Agent Service",
//   "Operating Agreement",
//   "IRS Form 2553 ",
//   "Online Dashboard Access",
//   "Lifelong Company Alerts ",
//   "Business bank account",
//   "Consultation on Business Tax",
//   "Banking Resolution",
//   "Unlimited Phone and Email support",
//   "EIN",
//   "Templates of Business contract",
//   "Expedited Company Filing",
//   "Business Email and Domain Name",
// ];

// Order Summary Details for selected package
const silverPackageDetails = [["Electronic Delivery", true]];

const goldPackageDetails = [
  ["Electronic Delivery", true],
  ["EIN / Tax ID #", true],
  ["Operation Agreement", true],
  ["Banking Resolution", true],
];

const platinumPackageDetails = [
  ["Electronic Delivery", true],
  ["EIN / Tax ID #", true],
  ["Operation Agreement", true],
  ["Banking Resolution", true],
  ["Domain & Email Service", "Free"],
  ["Expedited Filing", true],
  ["Business Contract Templates", true],
];

function StartBusiness() {
  const classes = useStyles();

  const [stateData, setStateData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceDetailsData, setServiceDetailsData] = useState([]);

  //* State for extracting Description Data from Api
  const [silverDescriptionData, setSilverDescriptionData] = useState([]);
  const [goldDescriptionData, setGoldDescriptionData] = useState([]);
  const [platinumDescriptionData, setPlatinumDescriptionData] = useState([]);

  //* State for extracting Requirement Data from Api
  // const [requirementsData, setRequirementsData] = useState([]);

  const [corporationName, setCorporationName] = useState("");
  const [stateName, setStateName] = useState("");

  const [selectedPackage, setSelectedPackage] = useState({
    ServiceId: "",
    StateId: "",
  });

  const [loader, setLoader] = useState(false);

  //* Get Service Details API
  const getServiceDetails = async (serviceID, stateID) => {
    try {
      setLoader(true);
      const { Data } = await Service.getServiceDetails(stateID, serviceID);
      setServiceDetailsData(Data);
      const arrSilverDataDescription = Data[0]?.Description.split(".");
      setSilverDescriptionData(arrSilverDataDescription);
      const arrGoldDataDescription = Data[0]?.Gold_Description.split(".");
      setGoldDescriptionData(arrGoldDataDescription);
      const arrPlatinumDataDescription =
        Data[0]?.Platinium_Description.split(".");
      setPlatinumDescriptionData(arrPlatinumDataDescription);

      // Pre-Requisite Array
      // const arrDataRequirements = Data[0]?.PreRequisities.split(".");
      // setRequirementsData(arrDataRequirements);
    } catch (error) {
      console.log(
        "file: StartBusiness.js => line 118 => getServiceDetails => error",
        error
      );
    } finally {
      setLoader(false);
    }
  };

  const handleCorporation = (data) => {
    localStorage.setItem("ServiceName", data.ServiceName);
    setCorporationName(data.ServiceName);
    setSelectedPackage({
      ServiceId: data.Id,
      StateId: selectedPackage.StateId ? selectedPackage.StateId : "",
    });
    if (selectedPackage?.StateId) {
      getServiceDetails(data.Id, selectedPackage.StateId);
    }
  };
  const handleState = (data) => {
    setStateName(data.StateName);
    setSelectedPackage({
      ServiceId: selectedPackage.ServiceId,
      StateId: data.Id,
    });
    if (selectedPackage?.ServiceId) {
      getServiceDetails(selectedPackage.ServiceId, data.Id);
    }
  };

  //* Get State API
  const getStates = async () => {
    try {
      const { Data } = await Service.getStates();
      setStateData(Data);
    } catch (error) {
      console.log(
        "file: StartBusiness.js => line 118 => getStates => error",
        error
      );
    }
  };

  //* Get Service API
  const getServices = async () => {
    try {
      const { Data } = await Service.getServices();
      setServiceData(Data);
    } catch (error) {
      console.log(
        "file: StartBusiness.js => line 118 => getServices => error",
        error
      );
    }
  };

  useEffect(() => {
    getServices();
    getStates();
  }, []);

  //* Scroll Top Service Card Package
  useEffect(() => {
    if (stateName && corporationName) {
      const element = document.getElementById("pck");
      element && element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [stateName, corporationName]);

  // Dis-patch Function to hold the array(details) of selected package
  const dispatch = useDispatch();

  return (
    <Layout>
      <Box textAlign="center" backgroundColor={colors.lightPrimary}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 900,
            pt: 6,
            color: colors.black,
          }}
        >
          Start your business with confidence
        </Typography>
        <Typography variant="h6" sx={{ my: 2 }}>
          Join over 500,000 happy business owners. Get started by choosing your
          entity type and state of formation.
        </Typography>

        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              alignItems: "center",
              marginTop: 25,
            }}
          >
            <img
              src={Images.review}
              alt="Review"
              style={{ borderRadius: "50%", width: "200px", height: "200px" }}
            />

            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                component={Link}
                to="/start-business"
                className={classes.text}
                sx={{ display: "block", ml: 2 }}
              >
                More trusted, Verified reviews
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <StarBorderPurple500Rounded sx={{ color: colors.WaterBlue }} />
                <StarBorderPurple500Rounded sx={{ color: colors.WaterBlue }} />
                <StarBorderPurple500Rounded sx={{ color: colors.WaterBlue }} />
                <StarBorderPurple500Rounded sx={{ color: colors.WaterBlue }} />
                <StarBorderPurple500Rounded sx={{ color: colors.WaterBlue }} />
              </Box>
              <Typography sx={{ pl: 1 }}>27,303 ratings</Typography>
            </Grid>
          </Grid>
          {/* selector section */}
          <Grid item xs={10} md={4} sx={{ my: "auto" }}>
            {/* Corporation Drop Down */}
            <FormControl fullWidth sx={{ mt: 3 }}>
              <Select
                displayEmpty
                value={corporationName}
                // onChange={handleCorporation}
                input={
                  <OutlinedInput
                    sx={{ backgroundColor: "white", fontSize: "1.2rem" }}
                  />
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Box sx={{ display: "flex" }}>
                        <LooksOneRounded
                          sx={{ fontSize: "2rem", color: colors.primary }}
                        />
                        <Typography
                          sx={{
                            fontSize: "1.3rem",
                            pl: 2,
                            color: colors.textPrimary,
                          }}
                        >
                          Select Service
                        </Typography>
                      </Box>
                    );
                  }
                  return (
                    <Box sx={{ display: "flex" }}>
                      <LooksOneRounded
                        sx={{ fontSize: "2rem", color: colors.primary }}
                      />
                      <Typography sx={{ fontSize: "1.3rem", pl: 2 }}>
                        {selected}
                      </Typography>
                    </Box>
                  );
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {serviceData.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.ServiceName}
                    onClick={() => handleCorporation(item)}
                    //style={getStyles(name, corporationName, theme)}
                  >
                    {item.ServiceName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {/* State Drop Down */}
            <FormControl fullWidth sx={{ mt: 3 }}>
              <Select
                displayEmpty
                value={stateName}
                input={
                  <OutlinedInput
                    sx={{ backgroundColor: "white", fontSize: "1.2rem" }}
                  />
                }
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Box sx={{ display: "flex" }}>
                        <LooksTwoRounded
                          sx={{ fontSize: "2rem", color: colors.primary }}
                        />
                        <Typography
                          sx={{
                            fontSize: "1.3rem",
                            pl: 2,
                            color: colors.textPrimary,
                          }}
                        >
                          State of Formation
                        </Typography>
                      </Box>
                    );
                  }
                  return (
                    <Box sx={{ display: "flex" }}>
                      <LooksTwoRounded
                        sx={{ fontSize: "2rem", color: colors.primary }}
                      />
                      <Typography sx={{ fontSize: "1.3rem", pl: 2 }}>
                        {selected}
                      </Typography>
                    </Box>
                  );
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                {stateData.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.StateName}
                    onClick={() => handleState(item)}
                    // style={getStateStyles(item.StateName, selectedData, theme)}
                  >
                    {item.StateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ py: "30px" }}>
          State-specific pricing will display below once
          <br />
          your selection has been made
        </Typography>
      </Box>
      {stateName && (
        <Fragment>
          {/* ========== Pricing Card ========== */}
          <span id="pck"></span>
          {loader ? (
            <Box sx={{ textAlign: "center" }}>
              <Loader />
            </Box>
          ) : serviceDetailsData.length > 0 ? (
            // <Grid container sx={{
            //   display: 'flex',
            //   mt: '30px', boxShadow: 3,
            //   borderRadius: '30px', p: 3, width: '70%', mx: "auto"
            // }}>
            //   <Grid item xs={12} md={5} sx={{ textAlign: 'center', p: 2, my: 'auto' }} >
            //     <Typography variant='h4' sx={{ fontWeight: 'bold', color: colors.black }}>Featured</Typography>
            //     <Typography variant='body1' sx={{ color: colors.textPrimary }}>Full service features<br />
            //       at the best value</Typography>
            //     <img src={Images.pricing} alt="Review"
            //       style={{
            //         width: '150px', borderRadius: '50%',
            //         textAlign: "left", marginTop: '30px', marginLeft: '10px'
            //       }} />
            //     <Typography variant="h4" sx={{ fontWeight: 'bold', color: colors.primary }}>${serviceDetailsData[0]?.ServiceCharge}</Typography>
            //     <Typography sx={{ color: colors.textPrimary }}>One time payment</Typography>
            //     <Button variant="contained" component={Link} to="/service-payment"
            //       state={{
            //         serviceId: serviceDetailsData[0]?.Id,
            //         serviceState: stateName,
            //         serviceName: corporationName,
            //         serviceCharges: serviceDetailsData[0]?.ServiceCharge
            //       }}
            //       sx={{ borderRadius: '15px', mt: 2, height: '50px' }}>
            //       Get the Service Package
            //     </Button>
            //      <Typography sx={{ my: 3 }}>
            //   <li
            //     style={{ display: 'flex', justifyContent: 'space-between', listStyle: 'none', marginTop: 10 }}>
            //     <HelpOutlineRounded sx={{ color: colors.primary }} />
            //     Package Fee
            //     <span >$299</span>
            //   </li>
            //   <li
            //     style={{ display: 'flex', justifyContent: 'space-between', listStyle: 'none', marginTop: 10 }}>
            //     <HelpOutlineRounded sx={{ color: colors.primary }} />
            //     State Fee
            //     <span >$247</span>
            //   </li>
            // </Typography>

            //   </Grid>

            //   <Grid item xs={12} md={7} sx={{ textAlign: 'center', p: 2 }}>
            //     <Typography sx={{ backgroundColor: colors.primary, color: colors.white, borderRadius: '5px', py: 0.75 }}>
            //       Package Includes:</Typography>
            //     <Typography variant="body1" sx={{ textAlign: 'left', my: 3, color: colors.textPrimary }}>
            //       {descriptionData.map((item, index) => (
            //         <li key={index} style={{ listStyleType: "none" }}>
            //           <Done style={{ color: colors.primary, paddingRight: '12px', p: 1 }} />
            //           {item}
            //         </li>
            //       ))}
            //       {serviceDetailsData[0]?.Description}
            //     </Typography>
            //     <Typography sx={{
            //       backgroundColor: colors.primary, mt: 3,
            //       color: colors.white, borderRadius: '5px', py: 0.75
            //     }}>
            //       Requirements:</Typography>
            //     <Typography variant="body1" sx={{ textAlign: 'left', my: 3, color: colors.textPrimary }}>
            //       {requirementsData.map((item, index) => (
            //         <li key={index} style={{ listStyleType: "none", color: colors.textPrimary }}>
            //           <Done style={{ color: colors.primary, paddingRight: '12px', p: 1 }} />
            //           {item}
            //         </li>
            //       ))}
            //       {serviceDetailsData[0]?.PreRequisities}
            //     </Typography>

            //   </Grid>
            // </Grid>
            // <Pricing />
            //  Getting Pricing Code
            <Grid
              container
              spacing={2.5}
              justifyContent="center"
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Grid item md={4}>
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: "-4px 4px 24px 0 rgb(145 158 171 / 8%)",
                    p: 3,
                    pt: 5,
                    cursor: "pointer",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          color="primary"
                          gutterBottom
                          sx={{ fontFamily: "Barlow", fontWeight: 600, mb: 2 }}
                        >
                          Silver
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ fontWeight: 600, fontFamily: "Barlow" }}
                        >
                          ${serviceDetailsData[0]?.ServiceCharge}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ fontWeight: "bold", color: colors.secondary }}
                        >
                          One time payment
                        </Typography>
                      </Box>
                      <BasicPlan />
                    </Box>
                    <Typography sx={{ my: 3 }}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        <HelpOutlineRounded sx={{ color: colors.primary }} />
                        Package Fee
                        <span>${serviceDetailsData[0]?.PackageFee}</span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        <HelpOutlineRounded sx={{ color: colors.primary }} />
                        State Fee
                        <span>${serviceDetailsData[0]?.StateFee}</span>
                      </li>
                    </Typography>
                    <Typography
                      sx={{
                        my: 1.5,
                        fontWeight: "bold",
                        backgroundColor: colors.antiqueWhite,
                        borderRadius: "8px",
                        p: 2,
                        textAlign: "center",
                      }}
                    >
                      Includes:
                    </Typography>
                    <Box sx={{ pt: 1, my: 2 }}>
                      {/* <Typography sx={{ my: 1.2 }} color="secondary">
                        <Checkmark />
                        LLC (No LLC fee, pay the State fee only)
                        <ul style={{ fontSize: "14px" }}>
                          <li>Preparing and filing Article of Organization.</li>
                          <li>One-year free Registered Agent Service</li>
                        </ul>
                      </Typography>
                      <Typography sx={{ my: 1.2 }} color="secondary">
                        <Checkmark />
                        EIN
                      </Typography>
                      <Typography sx={{ my: 1.2 }} color="secondary">
                        <Checkmark />
                        Reseller Certificate
                      </Typography>
                      <Typography sx={{ my: 1.2 }} color="secondary">
                        <Checkmark />
                        Business Bank account
                      </Typography>
                      <Typography sx={{ my: 1.2 }} color="secondary">
                        <Checkmark />
                        Amazon Account creation
                      </Typography> */}
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "left",
                          my: 3,
                          color: colors.textPrimary,
                        }}
                      >
                        {silverDescriptionData.map((item, index) => (
                          <li key={index} style={{ listStyleType: "none" }}>
                            <Checkmark
                              style={{
                                color: colors.primary,
                                paddingRight: "12px",
                                p: 1,
                              }}
                            />
                            {item}
                          </li>
                        ))}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={Link}
                      to="/process-contact"
                      onClick={() => {
                        localStorage.setItem("package", "silver");
                        localStorage.setItem(
                          "packagePriceDetails",
                          JSON.stringify([
                            serviceDetailsData[0]?.ServiceCharge,
                            serviceDetailsData[0]?.PackageFee,
                            serviceDetailsData[0]?.StateFee,
                          ])
                        );
                        dispatch(
                          processContactDetails({
                            details: silverPackageDetails,
                          })
                        );
                      }}
                      variant="contained"
                      fullWidth
                      sx={{
                        py: 1.5,
                        borderRadius: 2,
                        fontWeight: 600,
                      }}
                    >
                      Get Started
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: "-16px 16px 56px -8px rgb(145 158 171 / 16%)",
                    p: 3,
                    pt: 5,
                  }}
                >
                  <CardContent>
                    <Chip
                      label="POPULAR"
                      sx={{
                        mb: 2,
                        borderRadius: 2,
                        mt: "-10px",
                        fontWeight: 600,
                        fontFamily: "Poppins",
                        color: "#0067AA",
                        backgroundColor: "rgba(0, 177, 237, 0.16)",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          color="primary"
                          gutterBottom
                          sx={{ fontFamily: "Barlow", fontWeight: 600, mb: 2 }}
                        >
                          Gold
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ fontWeight: 600, fontFamily: "Barlow" }}
                        >
                          ${serviceDetailsData[0]?.Gold_ServiceCharge}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ fontWeight: "bold", color: colors.secondary }}
                        >
                          One time payment
                        </Typography>
                      </Box>
                      <StarterPlan />
                    </Box>
                    <Typography sx={{ my: 3 }}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        <HelpOutlineRounded sx={{ color: colors.primary }} />
                        Package Fee
                        <span>${serviceDetailsData[0]?.Gold_PackageFee}</span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        <HelpOutlineRounded sx={{ color: colors.primary }} />
                        State Fee
                        <span>${serviceDetailsData[0]?.Gold_StateFee}</span>
                      </li>
                    </Typography>
                    <Typography
                      sx={{
                        my: 1.5,
                        fontWeight: "bold",
                        backgroundColor: colors.antiqueWhite,
                        borderRadius: "8px",
                        p: 2,
                        textAlign: "center",
                      }}
                    >
                      Includes:
                    </Typography>
                    <Box sx={{ pt: 1, my: 2 }}>
                      {/* <Typography
                        component={"div"}
                        sx={{ my: 1.5 }}
                        color="secondary"
                      >
                        <Checkmark />
                        LLC (LLC + State fee)
                        <ul>
                          {goldFeatures.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </Typography>
                      <Typography sx={{ my: 1.5 }} color="secondary">
                        <Checkmark />
                        Reseller Certificate
                      </Typography>
                      <Typography sx={{ my: 1.5 }} color="secondary">
                        <Checkmark />
                        Business Bank Account
                      </Typography>
                      <Typography sx={{ my: 1.5 }} color="secondary">
                        <Checkmark />
                        Amazon Account Creation
                      </Typography> */}
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "left",
                          my: 3,
                          color: colors.textPrimary,
                        }}
                      >
                        {goldDescriptionData.map((item, index) => (
                          <li key={index} style={{ listStyleType: "none" }}>
                            <Checkmark
                              style={{
                                color: colors.primary,
                                paddingRight: "12px",
                                p: 1,
                              }}
                            />
                            {item}
                          </li>
                        ))}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      component={Link}
                      to="/process-contact"
                      onClick={() => {
                        localStorage.setItem("package", "gold");
                        localStorage.setItem(
                          "packagePriceDetails",
                          JSON.stringify([
                            serviceDetailsData[0]?.Gold_ServiceCharge,
                            serviceDetailsData[0]?.Gold_PackageFee,
                            serviceDetailsData[0]?.Gold_StateFee,
                          ])
                        );
                        dispatch(
                          processContactDetails({ details: goldPackageDetails })
                        );
                      }}
                      fullWidth
                      sx={{
                        py: 1.5,
                        borderRadius: 2,
                        fontWeight: 600,
                      }}
                    >
                      Get Started
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card
                  sx={{
                    borderRadius: 3,
                    boxShadow: "-16px 16px 56px -8px rgb(145 158 171 / 16%)",
                    p: 3,
                    pt: 5,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          color="primary"
                          gutterBottom
                          sx={{ fontFamily: "Barlow", fontWeight: 600, mb: 2 }}
                        >
                          Platinum
                        </Typography>
                        <Typography
                          variant="h4"
                          component="div"
                          sx={{ fontWeight: 600, fontFamily: "Barlow" }}
                        >
                          ${serviceDetailsData[0]?.Platinium_ServiceCharge}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          sx={{ fontWeight: "bold", color: colors.secondary }}
                        >
                          One time payment
                        </Typography>
                      </Box>
                      <PremiumPlan />
                    </Box>
                    <Typography sx={{ my: 3 }}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        <HelpOutlineRounded sx={{ color: colors.primary }} />
                        Package Fee
                        <span>
                          ${serviceDetailsData[0]?.Platinium_PackageFee}
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          listStyle: "none",
                          marginTop: 10,
                        }}
                      >
                        <HelpOutlineRounded sx={{ color: colors.primary }} />
                        State Fee
                        <span>
                          ${serviceDetailsData[0]?.Platinium_StateFee}
                        </span>
                      </li>
                    </Typography>
                    <Typography
                      sx={{
                        my: 1.5,
                        fontWeight: "bold",
                        backgroundColor: colors.antiqueWhite,
                        borderRadius: "8px",
                        p: 2,
                        textAlign: "center",
                      }}
                    >
                      Includes:
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: colors.lightGray,
                        borderRadius: "8px",
                        p: 3,
                        my: 3,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <StarBorderPurple500 sx={{ mx: 1 }} />
                        Business Contract Templates
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <StarBorderPurple500 sx={{ mx: 1 }} />
                        Expedited Filing
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <StarBorderPurple500 sx={{ mx: 1 }} />
                        Domain Name + Business Email
                      </Typography>
                    </Box>
                    <Box sx={{ pt: 1, my: 2 }}>
                      {/* <Typography
                        component={"div"}
                        sx={{ my: 1.5 }}
                        color="secondary"
                      >
                        <Checkmark />
                        LLC (LLC + State fee)
                        <ul>
                          {platinumFeatures.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </Typography>
                      <Typography sx={{ my: 1.5 }} color="secondary">
                        <Checkmark />
                        Reseller Certificate
                      </Typography>
                      <Typography sx={{ my: 1.5 }} color="secondary">
                        <Checkmark />
                        Business Bank account
                      </Typography>
                      <Typography sx={{ my: 1.5 }} color="secondary">
                        <Checkmark />
                        Amazon Account creation
                      </Typography> */}
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "left",
                          my: 3,
                          color: colors.textPrimary,
                        }}
                      >
                        {platinumDescriptionData.map((item, index) => (
                          <li key={index} style={{ listStyleType: "none" }}>
                            <Checkmark
                              style={{
                                color: colors.primary,
                                paddingRight: "12px",
                                p: 1,
                              }}
                            />
                            {item}
                          </li>
                        ))}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      fullWidth
                      component={Link}
                      to="/process-contact"
                      onClick={() => {
                        localStorage.setItem("package", "platinum");
                        localStorage.setItem(
                          "packagePriceDetails",
                          JSON.stringify([
                            serviceDetailsData[0]?.Platinium_ServiceCharge,
                            serviceDetailsData[0]?.Platinium_PackageFee,
                            serviceDetailsData[0]?.Platinium_StateFee,
                          ])
                        );
                        dispatch(
                          processContactDetails({
                            details: platinumPackageDetails,
                          })
                        );
                      }}
                      sx={{
                        py: 1.5,
                        borderRadius: 2,
                        fontWeight: 600,
                      }}
                    >
                      Get Started
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          ) : (
            // End Pricing Code
            <Box
              sx={{
                mt: "30px",
                boxShadow: 3,
                borderRadius: "30px",
                p: 3,
                width: "70%",
                mx: "auto",
                textAlign: "center",
              }}
            >
              <img
                src={Images.notFound}
                alt="Item not found"
                width="170px"
                style={{ textAlign: "center" }}
              />
              <Typography variant="h4" color={colors.primary}>
                No Package Found!
              </Typography>
            </Box>
          )}
        </Fragment>
      )}
    </Layout>
  );
}

export default StartBusiness;
