import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import {
  LocationOn,
  Lightbulb,
  BusinessTwoTone,
  ShareLocationTwoTone,
} from "@mui/icons-material";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// *Import Component
import colors from "app/style/colors";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Error } from "../UI/Error";

function CompanyInfo() {
  // *To handle Email Validation
  // const { register, handleSubmit, formState: { errors }, } = useForm();

  //* Auto Complete State Select For Mailing Address
  const designatorSelect = [
    { label: "LLC" },
    { label: "L.L.C." },
    { label: "LIMITED LIABILITY COMPANY" },
    { label: "LIMITED LIABILITY CO." },
    { label: "LTD LIABILITY COMPANY" },
  ];

  //* Auto Complete State Select For No of Members
  const noOfMemberSelect = [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
  ];

  //* Auto Complete State Select7 For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ContactDetails.js ~ line 31 ~ ContactDetails ~ companyData",
    companyDetails
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // package name
  const packName = localStorage.getItem("package");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const customerInfo = async (data) => {
    // setLoading(true)
    try {
      let obj = {
        ...companyDetails,
        llc_name: data.llc_name,
        designator: designatorValue,
        no_of_members: selectNoOfMember,
        business_purpose: data.business_purpose,
        industry_keyword: data.industry_keyword,
        companyStreetAddress: data.companyStreetAddress,
        companyAddress: data.companyAddress,
        companyCity: data.companyCity,
        companyState: selectState,
        companyZipCode: data.companyZipCode,
      };
      // setData(obj);
      // localStorage.setItem("contactDetail", JSON.stringify(obj));
      dispatch(processContactDetails(obj));
      navigate(
        `${
          packName === "silver"
            ? "/process-kit-invite/silver"
            : packName === "gold"
            ? "/process-kit-invite/gold"
            : "/process-kit-invite/premium"
        }`
      );
    } catch (error) {
      console.log(
        "file: COntactDetails.js => line 50 => Contact Details => error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  // LLC Name
  const [llcName, setLLCName] = useState("");

  const [llcNameHint, setLLCNameHint] = useState(false);

  const handleChange = (event) => {
    setLLCName(event.target.value);
    setLLCNameHint(true);
  };

  //* Autocomplete Selected Value
  // const [designatorValue, setDesignatorValue] = useState(designatorSelect[0]);

  //* State for Basic Select
  const [designatorValue, setDesignatorValue] = useState("");
  const [selectState, setSelectState] = useState("");
  const [selectNoOfMember, setSelectNoOfMember] = useState("");

  const handleDesignationSelect = (event) => {
    setDesignatorValue(event.target.value);
  };

  const handleBasicSelect = (event) => {
    setSelectState(event.target.value);
  };

  const handleNoOfMember = (event) => {
    setSelectNoOfMember(event.target.value);
  };

  const [checkBox, setCheckBox] = useState(false);

  const handleCheckBoxValue = () => {
    if (!checkBox) {
      setValue("companyStreetAddress", companyDetails?.streetAddress);
      setValue("companyAddress", companyDetails?.address);
      setValue("companyCity", companyDetails?.city);
      setValue("companyState", companyDetails?.state);
      setValue("companyZipCode", companyDetails?.zipCode);
    } else {
      setValue("companyStreetAddress", "");
      setValue("companyAddress", "");
      setValue("companyCity", "");
      setValue("companyState", "");
      setValue("companyZipCode", "");
    }
    setCheckBox(!checkBox);
  };

  // Get Service Name For Text Field
  const serviceName = localStorage.getItem("ServiceName");
  return (
    <Fragment>
      <Box component={"form"} onSubmit={handleSubmit(customerInfo)}>
        {/* Company Information */}
        <Box sx={{ borderRadius: "12px", boxShadow: 3, py: 4, px: 5, my: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <BusinessTwoTone
              sx={{ color: colors.WarmPurple, fontSize: "40px", mr: 2 }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: colors.black }}
            >
              Company Information
            </Typography>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Grid
              container
              sx={{ justifyContent: "space-between" }}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ my: 1 }}
                  onChange={handleChange}
                  onBlur={() => setLLCNameHint(false)}
                >
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label={serviceName}
                    variant="outlined"
                    // onChange={handleChange}
                    // onBlur={() => setLLCNameHint(false)}
                    error={errors?.llc_name?.message && true}
                    {...register("llc_name", {
                      required: "Please enter your llc name.",
                    })}
                  />
                  {errors?.llc_name?.message && (
                    <Error message={errors?.llc_name?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ my: 1 }}>
                  {/* <Autocomplete
                    defaultValue={designatorValue}
                    onChange={(event, newValue) => {
                      setDesignatorValue(newValue);
                    }}
                    id="combo-box-demo"
                    options={designatorSelect}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Designator"
                        error={errors?.designator?.message && true}
                        {...register("designator", {
                          required: "Please enter your designation.",
                        })}
                      />
                    )}
                  />
                  {errors?.designator?.message && (
                    <Error message={errors?.designator?.message} />
                  )} */}
                  {/* Select State */}
                  <InputLabel id="demo-simple-select-label">
                    Designator
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={designatorValue}
                    label="Designator"
                    error={errors?.designator?.message && true}
                    {...register("designator", {
                      required: "Please enter your designation.",
                    })}
                    onChange={handleDesignationSelect}
                  >
                    {designatorSelect.map((item, index) => (
                      <MenuItem value={item.label}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  {errors?.designator?.message && (
                    <Error message={errors?.designator?.message} />
                  )}
                </FormControl>
              </Grid>
              {llcNameHint === true && (
                <Box
                  sx={{
                    backgroundColor: colors.portlandOrange,
                    borderRadius: "8px",
                    p: 3,
                    display: "flex",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  <Lightbulb
                    sx={{ mr: 1, fontSize: "44px", color: colors.black }}
                  />
                  <Box>
                    <Typography
                      sx={{
                        color: colors.black,
                        fontWeight: "bold",
                        mb: 1,
                        fontSize: "16px",
                      }}
                    >
                      What if the company name I select is not available?
                    </Typography>
                    <Typography
                      sx={{ color: colors.textPrimary, fontSize: "14px" }}
                    >
                      In the event the company name is unavailable, we will
                      contact you to request an alternative company name. We
                      will continue to work with you until we find a name that
                      is accepted by the companyState.
                    </Typography>
                  </Box>
                </Box>
              )}
              {llcName.length > 0 && (
                <Grid item xs={12} md={6} sx={{ mr: 4, cursor: "pointer" }}>
                  <Typography
                    sx={{ color: colors.secondaryLight, fontSize: "14px" }}
                  >
                    Your Official Name Would Be Display As:
                  </Typography>
                  <Typography
                    sx={{
                      p: 1.5,
                      fontWeight: "bold",
                      backgroundColor: colors.primary,
                      color: colors.white,
                      borderRadius: "8px",
                    }}
                  >{`${llcName.toUpperCase()}  ${designatorValue}`}</Typography>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <FormControl fullWidth sx={{ my: 1 }}>
                  {/* Select State */}
                  <InputLabel id="demo-simple-select-label">
                    No. Of Members/Owners
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectNoOfMember}
                    label="No. Of Members/Owners"
                    error={errors?.no_of_members?.message && true}
                    {...register("no_of_members", {
                      required: "Please enter number of members.",
                    })}
                    onChange={handleNoOfMember}
                  >
                    {noOfMemberSelect.map((item, index) => (
                      <MenuItem value={item.label}>{item.label}</MenuItem>
                    ))}
                  </Select>
                  {errors?.no_of_members?.message && (
                    <Error message={errors?.no_of_members?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-textarea"
                    label="Business Purpose (You Have 100 Characters Left!)"
                    placeholder="Please provide brief description of Business Purpose"
                    multiline
                    error={errors?.business_purpose?.message && true}
                    {...register("business_purpose", {
                      required: "Please enter your business purpose.",
                    })}
                  />
                  {errors?.business_purpose?.message && (
                    <Error message={errors?.business_purpose?.message} />
                  )}
                </FormControl>
              </Grid>
              <Typography
                variant="body2"
                sx={{ color: colors.secondary, mx: 3, mt: 2 }}
              >
                Tell us what industry your business is in?
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: colors.secondary, mx: 3, mb: 1 }}
              >
                ie. hair salon, accountant, bar owner, social media…
                Understanding what industry you're in can be helpful when filing
                certain companyState forms, however it can also help you when
                applying for financing, competing for government contracts, and
                can potentially help in identifying tax benefits.
              </Typography>
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Industry Keyword"
                    variant="outlined"
                    error={errors?.industry_keyword?.message && true}
                    {...register("industry_keyword", {
                      required: "Please enter your industry keyword.",
                    })}
                  />
                  {errors?.industry_keyword?.message && (
                    <Error message={errors?.industry_keyword?.message} />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* Company Address Information */}
        <Box sx={{ borderRadius: "12px", boxShadow: 3, py: 4, px: 5, my: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ShareLocationTwoTone
              sx={{ color: colors.WaterBlue, fontSize: "40px", mr: 2 }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: colors.black }}
            >
              Company Address Information
            </Typography>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Typography sx={{ color: colors.textSecondary, mb: 2 }}>
            Alaska guidelines do not require businesses to use an
            in-companyState companyAddress. USPS PO Boxes are not allowed.
          </Typography>
          <Typography sx={{ color: colors.textSecondary, mb: 2 }}>
            Please provide the companyAddress you would like listed as your
            company companyAddress.
          </Typography>
          <FormGroup sx={{ my: 3 }}>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckBoxValue} />}
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: colors.secondary,
                  }}
                >
                  <LocationOn />
                  {companyDetails.streetAddress},{companyDetails.address},
                  {companyDetails.state},{companyDetails.zipCode}
                </span>
              }
            />
          </FormGroup>
          <Box>
            <Grid
              container
              sx={{ justifyContent: "space-between" }}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    // defaultValue={checkBox ? companyDetails?.streetAddress : ""}
                    label="Street Address"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.companyStreetAddress?.message && true}
                    {...register("companyStreetAddress", {
                      required: "Please enter your street address.",
                    })}
                  />
                  {errors?.companyStreetAddress?.message && (
                    <Error message={errors?.companyStreetAddress?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Address (Cont)"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.companyAddress?.message && true}
                    {...register("companyAddress", {
                      required: "Please enter your address.",
                    })}
                  />
                  {errors?.companyAddress?.message && (
                    <Error message={errors?.companyAddress?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={errors?.companyCity?.message && true}
                    {...register("companyCity", {
                      required: "Please enter your city.",
                    })}
                  />
                  {errors?.companyCity?.message && (
                    <Error message={errors?.companyCity?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={mailStateSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={errors?.companyState?.message && true}
                          {...register("companyState", {
                            required: "Please enter your state.",
                          })}
                        />
                      )}
                    />
                    {errors?.companyState?.message && (
                      <Error message={errors?.companyState?.message} />
                    )} */}
                    {/* Select State */}
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectState}
                      label="State"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.companyState?.message && true}
                      {...register("companyState", {
                        required: "Please enter your state.",
                      })}
                      onChange={handleBasicSelect}
                    >
                      {mailStateSelect.map((item, index) => (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    {errors?.companyState?.message && (
                      <Error message={errors?.companyState?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Zip Code"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.companyZipCode?.message && true}
                      {...register("companyZipCode", {
                        required: "Please enter your zip code.",
                      })}
                    />
                    {errors?.companyZipCode?.message && (
                      <Error message={errors?.companyZipCode?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Button
            component={Link}
            to={
              packName === "platinum" ? "/process-contact" : "/state-expedite"
            }
            variant="outlined"
          >
            Back
          </Button>
          <Button
            // component={Link} to={packName === "silver"
            //     ? "/process-kit-invite/silver"
            //     : packName === "gold"
            //     ? "/process-kit-invite/gold"
            //     : "/process-kit-invite/premium"
            // }
            type="submit"
            variant="contained"
            //onClick={handleSubmit}
            sx={{ px: 4 }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}

export default CompanyInfo;
