import React from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import { CurrencyExchange, DeleteForever, EventNote, WorkspacePremium } from '@mui/icons-material'

// *Imported Component
import colors from 'app/style/colors'

const useStyle = makeStyles({
  textColor: {
    color: colors.LavenderMist,
    cursor: "pointer",
    "&:hover": {
      color: colors.primary,
    }
  },
})


function WhyUseUsAsAgent() {
  const classes = useStyle()
  return (
    <Box sx={{
      boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`, borderRadius: "6px", p: 4, my: 4
    }}>
      <Typography variant="h6" sx={{ color: colors.black, fontWeight: "bold", my: 2 }}>Why Use Us As Your Registered Agent?</Typography>
      <Box>
        <Box sx={{ display: "flex", my: 3 }}>
          <EventNote sx={{ color: colors.WarmPurple, fontSize: "42px", mr: 3 }} />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: colors.black }}>Free First Years</Typography>
            <Typography variant="body2" sx={{ color: colors.secondary }}>Every new order for incorporation service includes <span style={{ fontWeight: "bold" }}>1 year of FREE Registered Agent service.</span> Service automatically renews each year, but you may cancel at any time by changing your agent and then contacting us.
              <span className={classes.textColor} >View Terms</span> </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", my: 3 }}>
          <WorkspacePremium sx={{ color: colors.GreenyBlue, fontSize: "46px", mr: 3 }} />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: colors.black }}>Guaranteed Rates</Typography>
            <Typography variant="body2" sx={{ color: colors.secondary }}>After the first year your renewal rate will remain $119 per year and is guaranteed for the life of the service. When your renewal is due you will be notified and be allowed to decide if renewing the service is in your best interests.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", my: 3 }}>
          <CurrencyExchange sx={{ color: colors.WaterBlue, fontSize: "38px", mr: 3 }} />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: colors.black }}>All-Inclusive</Typography>
            <Typography variant="body2" sx={{ color: colors.secondary }}>We never charge a dime in additional fees for postage paid to deliver you your company's important documents.</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", my: 3 }}>
          <DeleteForever sx={{ color: colors.FrenchRose, fontSize: "42px", mr: 3 }} />
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold", color: colors.black }}>Reduce Junk Mail</Typography>
            <Typography variant="body2" sx={{ color: colors.secondary }}>Many companies will acquire distribution lists of new companies and use the registered agent address to send annoying solicitation mail. By having a registered agent address you can reduce the amount of junk mail received.</Typography>
          </Box>
        </Box>
      </Box>

    </Box >
  )
}

export default WhyUseUsAsAgent