import { AccessAlarmTwoTone } from "@mui/icons-material";
import { Box, Button, Divider, Radio, Typography } from "@mui/material";
import colors from "app/style/colors";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

function StateFilingProcess() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: StateFilingProcess.js ~ line 17 ~ StateFilingProcess ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // *Getting Package name from Local Storage
  let packageName = localStorage.getItem("package");

  const { handleSubmit } = useForm();

  // const { state } = useLocation();
  // console.log(
  //   "🚀 ~ file: StateFilingProcess.js ~ line 11 ~ StateFilingProcess ~ state",
  //   state
  // );

  const [selectedRadioValue, setSelectedRadioValue] = useState("3Weeks");

  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    const indexExpeditedValue = newDetailsData.findIndex(
      (item) => item[0] === "Expedited Filing"
    );
    if (indexExpeditedValue === -1) {
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["Expedited Filing", true]],
      };
      dispatch(processContactDetails(obj));
    } else {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "Expedited Filing"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: duplicate,
      };
      dispatch(processContactDetails(obj));
    }
  };

  const handleChangeBox = (value) => {
    if (value != selectedRadioValue) {
      setSelectedRadioValue(value);
      const newDetailsData = companyDetails.details;
      const duplicate = [...newDetailsData];
      const indexExpeditedValue = duplicate.findIndex(
        (item) => item[0] === "Expedited Filing"
      );
      if (indexExpeditedValue === -1) {
        let obj = {
          ...companyDetails,
          details: [...duplicate, ["Expedited Filing", true]],
        };
        dispatch(processContactDetails(obj));
      } else {
        const indexExpeditedValue = companyDetails.details.findIndex(
          (item) => item[0] === "Expedited Filing"
        );
        const data = duplicate.splice(indexExpeditedValue, 1);
        let obj = {
          ...companyDetails,
          details: duplicate,
        };
        dispatch(processContactDetails(obj));
      }
    }
  };

  // *Subsection 1
  const [customerData, setData] = useState("");
  console.log(
    "🚀 ~ file: StateFilingProcess.js ~ line 34 ~ StateFilingProcess ~ customerData",
    customerData
  );
  const stateFilling = async (data) => {
    // setLoading(true)
    try {
      //setData("");
      let obj = { ...companyDetails, stateFillingTime: selectedRadioValue };
      //setData(obj);
      // localStorage.setItem("contactDetail", JSON.stringify(obj));
      dispatch(processContactDetails(obj));
      navigate("/company-information", { state: obj });
    } catch (error) {
      console.log(
        "file: StateFilling.js => line 50 => StateFilling => error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(stateFilling)}>
      <Box sx={{ borderRadius: "12px", boxShadow: 3, my: 4, p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccessAlarmTwoTone
            sx={{ color: colors.FrenchRose, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            State Filing Time
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary, mb: 2 }}>
          The typical state filing time for Alaska is 3 weeks. In a hurry?
          Alaska offers an expedited filing option as well. Select the filing
          time that best meets your needs.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 3,
              borderRadius: "8px",
              p: 2,
              mx: "2",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "280px",
              cursor: "pointer",
              height: "100px",
            }}
            onClick={() => handleChangeBox("3Weeks")}
          >
            <Radio
              checked={selectedRadioValue === "3Weeks"}
              // onChange={handleChange}
              value="3Weeks"
              name="radio-buttons"
            />
            <Box sx={{ mx: 1 }}>
              <Typography variant="body1" sx={{ color: colors.black }}>
                <span style={{ fontWeight: "bold" }}>3 weeks</span> filing time.
              </Typography>
              <Typography
                sx={{ color: colors.textSecondary, fontSize: "12px" }}
              >
                Estimated Formation Date:
              </Typography>
              <Typography
                sx={{
                  color: colors.textSecondary,
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Thursday, July 07
              </Typography>
            </Box>
            <Typography sx={{ fontWeight: "bold", mx: 2 }}>$0</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 3,
              borderRadius: "8px",
              p: 2,
              mx: "2",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "280px",
              cursor: "pointer",
              height: "100px",
            }}
            onClick={() => handleChangeBox("1Day")}
          >
            <Radio
              checked={selectedRadioValue === "1Day"}
              // onChange={handleChange}
              value="1Day"
              name="radio-buttons"
            />
            <Box sx={{ mx: 1 }}>
              <Typography
                sx={{
                  color: colors.primary,
                  border: `2px solid ${colors.primary}`,
                  px: 2,
                  py: 0.5,
                  borderRadius: "8px",
                  width: "40px",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: "-35px",
                  mb: 2,
                  backgroundColor: colors.white,
                  mx: "auto",
                }}
              >
                FAST
              </Typography>
              <Typography variant="body1" sx={{ color: colors.black }}>
                <span style={{ fontWeight: "bold" }}>1 business day</span>{" "}
                filing time.
              </Typography>
              <Typography
                sx={{ color: colors.textSecondary, fontSize: "12px" }}
              >
                Estimated Formation Date:
              </Typography>
              <Typography
                sx={{
                  color: colors.primary,
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Wednesday, June 08
              </Typography>
            </Box>
            <Typography sx={{ fontWeight: "bold", mx: 2 }}>$50</Typography>
          </Box>
        </Box>
        <Typography sx={{ color: colors.secondary, my: 2 }}>
          *These dates are estimations based on current state turnaround times
          and are subject to change based on state processing.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
        }}
      >
        <Button
          component={Link}
          to="/process-contact"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          // onClick={handleSubmit}
          // component={Link} to="/company-information"
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default StateFilingProcess;
