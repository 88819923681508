import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
//* Import Component
import Layout from 'app/layout/Layout';
import colors from 'app/style/colors';
import Images from 'assets/Images';




function Service() {

  return (

    <Layout>
      {/* ========== Service Banner ========== */}
      <Box sx={{
        marginTop: '10px', borderRadius: '14px',
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.serviceSectionBanner})`,
        height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat'
      }
      }>
        <Typography variant="h2" sx={{
          color: colors.white,
          textAlign: 'center',
          marginTop: '90px',
          fontWeight: 'bold',
        }}>
          Our Services
        </Typography>
        <Divider
          sx={{
            bgcolor: colors.primary,
            width: '180px',
            padding: '1.5px',
            margin: 'auto',
            mb: 1
          }}
        />

        <Divider
          sx={{
            bgcolor: colors.primary,
            width: '140px',
            padding: '1.5px',
            margin: 'auto',
          }}
        />
      </Box>
      {/* Services card section */}
      <Box container
        sx={{ marginTop: '50px' }}>
        <Grid container item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card item md={4}
            sx={{
              width: '350px', borderRadius: '14px', boxShadow: 3,
              color: colors.white, mx: 2, my: 2, position: 'relative',
              transition: "all 0.3s ease-in",
              '&:hover': {
                cursor: 'pointer',
                boxShadow: 5,
                transform: 'scale(1.05)',
                '.MuiButton-root': {
                  display: 'block',
                  cursor: 'pointer',
                  marginTop: '120px',
                  mx: 'auto'
                },
                '.MuiTypography-root': {
                  opacity: 0.5,
                  backdropFilter: 'saturate(200%) blur(30px)',
                },
              }
            }}>
            <CardContent sx={{ color: colors.black }}>
              <img src={Images.itService} width="100%" alt="" style={{ borderRadius: '14px' }} />
              <Typography variant='h6'
                sx={{
                  textAlign: 'center', color: colors.white, mb: 1, bgcolor: colors.primary,
                  fontWeight: 600,
                }}
              >
                IT Service</Typography>
              <Typography sx={{ textAlign: 'center', color: colors.secondary }}>It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. .
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%", position: 'absolute', top: 0, left: 0, }}>
                <Button
                  variant='contained'
                  component={Link} to='/llc-service'
                  sx={{
                    m: 'empty', display: 'none', mb: -20,
                  }}>
                  Know More</Button>
              </Box>
            </CardContent>
          </Card>
          <Card item md={4}
            sx={{
              width: '350px', borderRadius: '14px', boxShadow: 3,
              color: colors.white, mx: 2, my: 2, position: 'relative',
              transition: "all 0.3s ease-in",
              '&:hover': {
                cursor: 'pointer',
                boxShadow: 5,
                transform: 'scale(1.05)',
                '.MuiButton-root': {
                  display: 'block',
                  cursor: 'pointer',
                  marginTop: '120px',
                  mx: 'auto'
                },
                '.MuiTypography-root': {
                  opacity: 0.5,
                  backdropFilter: 'saturate(200%) blur(30px)',
                },
              }
            }}>
            <CardContent sx={{ color: colors.black }}>
              <img src={Images.idCreation} width="100%" alt="" style={{ borderRadius: '14px' }} />
              <Typography variant='h6'
                sx={{
                  textAlign: 'center', color: colors.white, mb: 1, bgcolor: colors.primary,
                  fontWeight: 600,
                }}
              >
                ID Creation</Typography>
              <Typography sx={{ textAlign: 'center', color: colors.secondary }}>It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. .
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%", position: 'absolute', top: 0, left: 0, }}>
                <Button
                  variant='contained'
                  component={Link} to='/llc-service'
                  sx={{
                    m: 'empty', display: 'none', mb: -20,
                  }}>
                  Know More</Button>
              </Box>
            </CardContent>
          </Card>
          <Card item md={4}
            sx={{
              width: '350px', borderRadius: '14px', boxShadow: 3,
              color: colors.white, mx: 2, my: 2, position: 'relative',
              transition: "all 0.3s ease-in",
              '&:hover': {
                cursor: 'pointer',
                boxShadow: 5,
                transform: 'scale(1.05)',
                '.MuiButton-root': {
                  display: 'block',
                  cursor: 'pointer',
                  marginTop: '120px',
                  mx: 'auto'
                },
                '.MuiTypography-root': {
                  opacity: 0.5,
                  backdropFilter: 'saturate(200%) blur(30px)',
                },
              }
            }}>
            <CardContent sx={{ color: colors.black }}>
              <img src={Images.llcFormation} width="100%" alt="" style={{ borderRadius: '14px' }} />
              <Typography variant='h6'
                sx={{
                  textAlign: 'center', color: colors.white, mb: 1, bgcolor: colors.primary,
                  fontWeight: 600,
                }}
              >
                LLC Formation</Typography>
              <Typography sx={{ textAlign: 'center', color: colors.secondary }}>It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. .
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%", position: 'absolute', top: 0, left: 0, }}>
                <Button
                  variant='contained'
                  component={Link} to='/llc-service'
                  sx={{
                    m: 'empty', display: 'none', mb: -20,
                  }}>
                  Know More</Button>
              </Box>
            </CardContent>
          </Card>
          <Card item md={4}
            sx={{
              width: '350px', borderRadius: '14px', boxShadow: 3,
              color: colors.white, mx: 2, my: 2, position: 'relative',
              transition: "all 0.3s ease-in",
              '&:hover': {
                cursor: 'pointer',
                boxShadow: 5,
                transform: 'scale(1.05)',
                '.MuiButton-root': {
                  display: 'block',
                  cursor: 'pointer',
                  marginTop: '120px',
                  mx: 'auto'
                },
                '.MuiTypography-root': {
                  opacity: 0.5,
                  backdropFilter: 'saturate(200%) blur(30px)',
                },
              }
            }}>
            <CardContent sx={{ color: colors.black }}>
              <img src={Images.itService} width="100%" alt="" style={{ borderRadius: '14px' }} />
              <Typography variant='h6'
                sx={{
                  textAlign: 'center', color: colors.white, mb: 1, bgcolor: colors.primary,
                  fontWeight: 600,
                }}
              >
                IT Service</Typography>
              <Typography sx={{ textAlign: 'center', color: colors.secondary }}>It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. .
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%", position: 'absolute', top: 0, left: 0, }}>
                <Button
                  variant='contained'
                  component={Link} to='/llc-service'
                  sx={{
                    m: 'empty', display: 'none', mb: -20,
                  }}>
                  Know More</Button>
              </Box>
            </CardContent>
          </Card>
          <Card item md={4}
            sx={{
              width: '350px', borderRadius: '14px', boxShadow: 3,
              color: colors.white, mx: 2, my: 2, position: 'relative',
              transition: "all 0.3s ease-in",
              '&:hover': {
                cursor: 'pointer',
                boxShadow: 5,
                transform: 'scale(1.05)',
                '.MuiButton-root': {
                  display: 'block',
                  cursor: 'pointer',
                  marginTop: '120px',
                  mx: 'auto'
                },
                '.MuiTypography-root': {
                  opacity: 0.5,
                  backdropFilter: 'saturate(200%) blur(30px)',
                },
              }
            }}>
            <CardContent sx={{ color: colors.black }}>
              <img src={Images.idCreation} width="100%" alt="" style={{ borderRadius: '14px' }} />
              <Typography variant='h6'
                sx={{
                  textAlign: 'center', color: colors.white, mb: 1, bgcolor: colors.primary,
                  fontWeight: 600,
                }}
              >
                ID Creation</Typography>
              <Typography sx={{ textAlign: 'center', color: colors.secondary }}>It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. .
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%", position: 'absolute', top: 0, left: 0, }}>
                <Button
                  variant='contained'
                  component={Link} to='/llc-service'
                  sx={{
                    m: 'empty', display: 'none', mb: -20,
                  }}>
                  Know More</Button>
              </Box>
            </CardContent>
          </Card>
          <Card item md={4}
            sx={{
              width: '350px', borderRadius: '14px', boxShadow: 3,
              color: colors.white, mx: 2, my: 2, position: 'relative',
              transition: "all 0.3s ease-in",
              '&:hover': {
                cursor: 'pointer',
                boxShadow: 5,
                transform: 'scale(1.05)',
                '.MuiButton-root': {
                  display: 'block',
                  cursor: 'pointer',
                  marginTop: '120px',
                  mx: 'auto'
                },
                '.MuiTypography-root': {
                  opacity: 0.5,
                  backdropFilter: 'saturate(200%) blur(30px)',
                },
              }
            }}>
            <CardContent sx={{ color: colors.black }}>
              <img src={Images.llcFormation} width="100%" alt="" style={{ borderRadius: '14px' }} />
              <Typography variant='h6'
                sx={{
                  textAlign: 'center', color: colors.white, mb: 1, bgcolor: colors.primary,
                  fontWeight: 600,
                }}
              >
                LLC Formation</Typography>
              <Typography sx={{ textAlign: 'center', color: colors.secondary }}>It is a long established fact that a reader will be distracted by the
                readable content of a page when looking at its layout. .
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", height: "100%", width: "100%", position: 'absolute', top: 0, left: 0, }}>
                <Button
                  variant='contained'
                  component={Link} to='/llc-service'
                  sx={{
                    m: 'empty', display: 'none', mb: -20,
                  }}>
                  Know More</Button>
              </Box>
            </CardContent>
          </Card>

        </Grid>

      </Box>
    </Layout >



  )
}

export default Service