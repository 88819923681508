//* Import From MUI
import React from 'react'
import { Grid } from '@mui/material'

//* Import Component
import Layout from 'app/layout/Layout'
import colors from 'app/style/colors'
import Images from 'assets/Images'

function Verified() {
  return (
    <Layout>
      <Grid sx={{ textAlign: "center" }}>
        <h1>Thank You !</h1>
        <h1 style={{ color: colors.primary }}>You have been verified!</h1>
        <img src={Images.verified} alt="Verified" />
      </Grid>
    </Layout>
  )
}

export default Verified