import React from 'react'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import Layout from 'app/layout/Layout'

//*Imported Component
import OrderSummary from 'app/components/OrderSummary/OrderSummary'
import PackageSilver from 'app/components/UpgradePackages/PackageSilver'
import PackageGold from 'app/components/UpgradePackages/PackageGold'
import MemberInfo from 'app/components/MemberInfo/MemberInfo'


function ProcessPckgUpgrade() {

  // *Getiing value from Local Storage
  let pathname = localStorage.getItem("package")



  return (
    <Layout>
      <Grid container spacing={3} sx={{ justifyContent: "space-evenly", p: 3 }}>
        <Grid item xs={12} md={7}>
          {pathname === "silver" &&
            <PackageSilver />
          }
          {pathname === "gold" &&
            <PackageGold />
          }
          {pathname === "platinum" &&
            <MemberInfo />
          }
        </Grid>
        <Grid item xs={12} md={4.5}>
          <OrderSummary value={45} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ProcessPckgUpgrade