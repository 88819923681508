//* Import From  MUI
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { LocationOn, Email, LocalPhone } from '@mui/icons-material/';
import { Box, Container, Divider, FormControl, Grid, TextField, Typography } from '@mui/material'
import 'react-toastify/dist/ReactToastify.css';

//* Import Component
import colors from 'app/style/colors';
import Images from 'assets/Images';
import { Error } from 'app/components/UI/Error';
import Layout from 'app/layout/Layout';





function ContactUs() {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  const customerContact = async (data) => {
    setLoading(true)
    try {
      let obj = {
        UserName: data.name,
        UserEmail: data.email,
        Subject: data.subject,
        Message: data.message
      }
      // console.log('file: ContactUs.js => line 39 => customerContact => obj', obj)

    } catch (error) {
      console.log('file: ContactUs.js => line 14 => ContactUs => error', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Layout>
      <Container>
        {/* ========== Contact Banner ========== */}
        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.contactBanner})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }
        }>


          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            Contact Us
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '140px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1,
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '100px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
            }}
          />
        </Box>

        {/* Contact Details Section */}
        <Grid container xs={12} md={10} sx={{ m: 'auto' }}>
          <Grid container item xs={12} md={12}
            sx={{ textAlign: 'center', mt: 5 }}>
            <Grid item xs={12} md={3}>
              <img src={Images.chatWithUs} width="100%" alt="Chat with us" />
            </Grid>
            <Grid item xs={12} md={5} sx={{ my: 'auto' }}>
              <Typography variant="h6"
                sx={{ textAlign: 'center', fontWeight: 'bold', }}>
                Chat with Us
              </Typography>
              <Divider
                sx={{
                  bgcolor: colors.primary,
                  width: '60px',
                  padding: '1px',
                  mx: 'auto',
                  my: 1,
                }}
              />
              <Typography>Monday - Friday</Typography>
              <Typography>We love to chat with you and answer your questions one on one. Clear all your doubts so there’s no further delay in your business start-up</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12}
            sx={{ textAlign: 'center', justifyContent: 'right' }}
          >
            <Grid item xs={12} md={5} sx={{ my: 'auto' }}>
              <Typography variant="h6"
                sx={{ textAlign: 'center', fontWeight: 'bold', }}>
                Give us a call!
              </Typography>
              <Divider
                sx={{
                  bgcolor: colors.primary,
                  width: '60px',
                  padding: '1px',
                  mx: 'auto',
                  my: 1,
                }}
              />
              <Typography>Monday -Friday</Typography>
              <Typography>3:00 PM-3:00 AM PST</Typography>
              <Typography>Our customer support team is all set to answer your calls and assist you with whatever questions you may have.</Typography>

            </Grid>
            <Grid item xs={12} md={3}>
              <img src={Images.callUs} width="100%" alt="Chat with us" />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12}
            sx={{ textAlign: 'center', mb: 5 }}
          >
            <Grid item xs={12} md={3}>
              <img src={Images.emailUs} width="100%" alt="Chat with us" />
            </Grid>
            <Grid item xs={12} md={5} sx={{ my: 'auto' }}>
              <Typography variant="h6"
                sx={{ textAlign: 'center', fontWeight: 'bold', }}>
                Email Us:
              </Typography>
              <Divider
                sx={{
                  bgcolor: colors.primary,
                  width: '60px',
                  padding: '1px',
                  mx: 'auto',
                  my: 1,
                }}
              />
              <Typography>info_us@themashr.com</Typography>
              <Typography>info_ca@themashr.com</Typography>
              <Typography>Send an email with your queries. We respond to most of the customers within 24 hours on a business day</Typography>
            </Grid>
          </Grid>

        </Grid>
        {/* Contact Section */}
        <Grid sx={{ textAlign: "center", justifyContent: "center", mx: "auto" }}>
          <Typography variant="h4" sx={{ fontWeight: '900' }}>Let's talk about everything!</Typography>
          <Typography variant='body1'>Our customer support team is just amazing to get some assistance from.</Typography>
          <Typography variant='body1' sx={{ fontWeight: "bold" }}>Give it a try!</Typography>
          <Grid container sx={{ my: 3, textAlign: "left", justifyContent: "center" }}>
            <Grid item xs={12} md={3} sx={{ p: 2 }} >
              <img style={{ width: 80, height: 80, borderRadius: 400 / 2 }} src={Images.americanFlag} alt="American Flag" />
              <Typography sx={{ fontWeight: "bold" }}>USA:</Typography>
              <Box >
                <Typography sx={{ wordBreak: "break-word" }}> <span style={{ fontWeight: "bold" }}>Address: </span>5015 Highland Crest Drive, Richmond, Texas, 77469</Typography>
                <Typography><span style={{ fontWeight: "bold" }}>Telephone: </span>+1 832 867 4442</Typography>
                <Typography><span style={{ fontWeight: "bold" }}>Email: </span>info_us@themashr.com</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ p: 2 }}>
              <img style={{ width: 80, height: 80, borderRadius: 400 / 2 }} src={Images.canadianFlag} alt="American Flag" />
              <Typography sx={{ fontWeight: "bold" }}>Canada:</Typography>
              <Box>
                <Typography sx={{ wordBreak: "break-word" }}> <span style={{ fontWeight: "bold" }}>Address: </span>1438 Everest cres Oakville, ON</Typography>
                <Typography><span style={{ fontWeight: "bold" }}>Telephone: </span>+1 (647) 961-2250</Typography>
                <Typography><span style={{ fontWeight: "bold" }}>Email: </span>info_ca@themashr.com</Typography>
              </Box>
            </Grid>

          </Grid>
        </Grid>
        <Grid container spacing={2} xs={12} md={12} justifyContent="center">

          <Grid item xs={12} md={4} sx={{ padding: '6px', mx: 14 }}>
            <img src={Images.contactUsSection} width="500px" alt="Contact Us Character" />

          </Grid>
          <Grid item xs={12} md={5} sx={{ borderRadius: '14px', mb: '70px', paddingTop: '16px', paddingBottom: '16px', marginTop: '30px' }}>
            <Box component={"form"} onSubmit={handleSubmit(customerContact)}>
              <FormControl variant="standard" fullWidth sx={{ my: 2 }}>
                <TextField
                  variant="outlined"
                  label="Name"
                  sx={{ width: '400px' }}
                  error={errors?.name?.message && (true)}
                  {...register("name", {
                    required: 'Please enter your name.',
                  })}
                />
                {errors?.name?.message && (
                  <Error message={errors?.name?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ my: 2 }}>
                <TextField
                  variant="outlined"
                  label="Email"
                  type="email"
                  sx={{ width: '400px' }}
                  error={errors?.email?.message && (true)}
                  {...register("email", {
                    required: 'Please enter your email.',
                  })}
                />
                {errors?.email?.message && (
                  <Error message={errors?.email?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ my: 2 }}>
                <TextField
                  variant="outlined"
                  label="Subject"
                  sx={{ width: '400px' }}
                  error={errors?.subject?.message && (true)}
                  {...register("subject", {
                    required: 'Please enter subject.',
                  })}
                />
                {errors?.subject?.message && (
                  <Error message={errors?.subject?.message} />
                )}
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ my: 2 }}>
                <TextField
                  variant="outlined"
                  label="Message"
                  multiline
                  maxRows={5}
                  sx={{ width: '400px' }}
                  error={errors?.message?.message && (true)}
                  {...register("message", {
                    required: 'Please enter message.',
                  })}
                />
                {errors?.message?.message && (
                  <Error message={errors?.message?.message} />
                )}
              </FormControl>

              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  width: "87%",
                  fontSize: '22',
                  borderRadius: "10px",
                  p: 1,
                  my: 2,
                  '&:hover': {
                    backgroundColor: colors.primaryLight,
                  },
                }}
              >
                Send
              </LoadingButton>
            </Box>

          </Grid>

        </Grid>

      </Container >
    </Layout>
  )
}

export default ContactUs