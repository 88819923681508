import React, { useState } from "react";
import { AddToPhotos } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

//*Import Components
import Layout from "app/layout/Layout";
import colors from "app/style/colors";
import Images from "assets/Images";

const shopify = [
  "Proof of identity",
  "Proof of address",
  "The store name",
  "Legal business name and address",
  "Billing information",
  "A default currency for your store",
  "Shipping settings",
  "Order delivery details",
  "Tax information",
  "Set up payment providers and gateways",
  "Domain set up",
];

function EcommerceStoreCreation() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <Layout>
      <Container>
        {/* ========== Header ========== */}

        <Box
          sx={{
            marginTop: 1.2,
            borderRadius: "14px",
            background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.learningSectionBanner})`,
            height: 300,
            position: "sticky",
            p: 4,
            opacity: 1,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: colors.white,
              textAlign: "center",
              marginTop: "100px",
              fontWeight: "bold",
            }}
          >
            eCommerce Store Creation
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: "170px",
              padding: "1.5px",
              margin: "auto",
              fontSize: "28px",
              mb: 1,
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: "120px",
              padding: "1.3px",
              margin: "auto",
              fontSize: "28px",
            }}
          />
        </Box>

        <Grid sx={{ my: 7 }}>
          <Typography
            variant="h4"
            sx={{
              mt: 4,
              color: colors.black,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Getting started with your eCommerce Store Creation
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: "160px",
              padding: "1.3px",
              margin: "auto",
              fontSize: "28px",
            }}
          />
          <Typography
            variant="h5"
            sx={{ my: 4, color: colors.black, fontWeight: "bold" }}
          >
            E-commerce platforms can put your business into the spotlight
            globally and boost your sale to double.
          </Typography>
          <Typography sx={{ mb: 1, color: colors.textPrimary }}>
            After getting a company registered, most people's ultimate goal
            should be to go for an e-commerce business and join some more
            prominent online marketplaces. Joining e-commerce platforms like
            Amazon, eBay, Etsy, or &nbsp;
            {/* Daraz  */}
            requires that a person registers with any of these by following some
            guidelines, filling out authentic information of the person and the
            business, and submitting the required documents.{" "}
          </Typography>
          <Box>
            <Grid container sx={{ mt: 4, justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                md={3.5}
                sx={{
                  bgcolor: colors.white,
                  textAlign: "center",
                  boxShadow: 2,
                  borderRadius: "12px",
                  mx: 1,
                  height: "260px", //300px For 5 options
                }}
              >
                <Box>
                  <Box sx={{ textAlign: "justify", mt: 3 }}>
                    <List component="nav">
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: colors.primary,
                            color: colors.white,
                            ".MuiSvgIcon-root": {
                              color: colors.white,
                            },
                          },

                          "&:hover": {
                            "&.Mui-selected": {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            ".MuiSvgIcon-root": {
                              color: colors.primary,
                            },
                          },
                        }}
                        onClick={(event) => handleListItemClick(event, 0)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Amazon Account Creation"
                          sx={{ ml: -1.5 }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: colors.primary,
                            color: colors.white,
                            ".MuiSvgIcon-root": {
                              color: colors.white,
                            },
                          },

                          "&:hover": {
                            "&.Mui-selected": {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            ".MuiSvgIcon-root": {
                              color: colors.primary,
                            },
                          },
                        }}
                        onClick={(event) => handleListItemClick(event, 1)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText
                          primary="eBay ID Creation"
                          sx={{ ml: -1.5 }}
                        />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 2}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: colors.primary,
                            color: colors.white,
                            ".MuiSvgIcon-root": {
                              color: colors.white,
                            },
                          },

                          "&:hover": {
                            "&.Mui-selected": {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            ".MuiSvgIcon-root": {
                              color: colors.primary,
                            },
                          },
                        }}
                        onClick={(event) => handleListItemClick(event, 2)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Etsy Store creation"
                          sx={{ ml: -1.5 }}
                        />
                      </ListItemButton>
                      {/* <ListItemButton
                        selected={selectedIndex === 3}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 3)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Daraz ID Creation" sx={{ ml: -1.5 }} />
                      </ListItemButton> */}
                      <ListItemButton
                        selected={selectedIndex === 4}
                        sx={{
                          "&.Mui-selected": {
                            bgcolor: colors.primary,
                            color: colors.white,
                            ".MuiSvgIcon-root": {
                              color: colors.white,
                            },
                          },

                          "&:hover": {
                            "&.Mui-selected": {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            ".MuiSvgIcon-root": {
                              color: colors.primary,
                            },
                          },
                        }}
                        onClick={(event) => handleListItemClick(event, 4)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Shopify Store Creation"
                          sx={{ ml: -1.5 }}
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  bgcolor: colors.white,
                  textAlign: "center",
                  boxShadow: 2,
                  borderRadius: "12px",
                  mx: 1,
                }}
              >
                {selectedIndex === 0 && (
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.black, fontWeight: "bold", my: 2 }}
                    >
                      1. Amazon Account Creation
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "justify",
                        mx: 3,
                        color: colors.textPrimary,
                      }}
                    >
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        Amazon is a leading e-commerce marketplace that attracts
                        millions of sellers and buyers from around the world.
                        Creating an account on amazon is simple yet a tricky
                        business. One should be mindful of all the requirements
                        and policies to avoid any complications or deactivation
                        of the account.{" "}
                      </Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        You can either create an individual or a business
                        account with Amazon.{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: colors.black, my: 2, fontWeight: "bold" }}
                      >
                        What is Required to create Amazon Account?
                      </Typography>
                      <ul
                        style={{
                          lineHeight: 2,
                          color: colors.textPrimary,
                          fontSize: "14px",
                        }}
                      >
                        <li>
                          ID Document (Passport, National ID, Driver’s license)
                        </li>
                        <li>
                          Business information (company name and registration
                          number as per documents)
                        </li>
                        <li>
                          Bank or credit card statement (of last three months)
                        </li>
                        <li>Bank account/credit cards details</li>
                        <li>Business and residential address</li>
                        <li>Phone number</li>
                      </ul>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        Once you have completed the signup, amazon asks sellers
                        to verify their identity through a short video call that
                        can be scheduled on any business day. Besides, it also
                        requires you to complete business address verification
                        if you’ve signed up for a US account. In case there’s a
                        minor ambiguity in the documents or the information
                        provided while doing the signup, Amazon can suspend your
                        account or ask you to provide the documents for a
                        re-verification. There’s a $39 monthly fee for its
                        professional selling plan if you sign up for a business
                        account.
                      </Typography>
                    </Box>
                  </Box>
                )}
                {selectedIndex === 1 && (
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.black, fontWeight: "bold", my: 2 }}
                    >
                      2. eBay ID Creation
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "justify",
                        mx: 3,
                        color: colors.textPrimary,
                      }}
                    >
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        After Amazon, eBay is the best platform to go with your
                        e-commerce business journey. Creating an eBay account is
                        easy if you have your business details and you are from
                        a country not on the eBay restricted list. There’s no
                        account creation fee for eBay, but it deducts some
                        percentage every time you make a sale.
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: colors.black, my: 2, fontWeight: "bold" }}
                      >
                        What is Required to create eBay Account?
                      </Typography>
                      <ul
                        style={{
                          lineHeight: 2,
                          color: colors.textPrimary,
                          fontSize: "14px",
                        }}
                      >
                        <li>
                          ID Document (Passport, National ID, Driver’s license)
                        </li>
                        <li>
                          Business information (company name, registration
                          number as per documents)
                        </li>
                        <li>Business and residential address</li>
                        <li>Phone number</li>
                      </ul>
                    </Box>
                  </Box>
                )}
                {selectedIndex === 2 && (
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.black, fontWeight: "bold", my: 2 }}
                    >
                      3. Etsy Store creation
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "justify",
                        mx: 3,
                        color: colors.textPrimary,
                      }}
                    >
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        Etsy is a US-based e-commerce marketplace that mainly
                        supports handmade, crafted, and vintage products for
                        sale. Creating a shop on Etsy is easy, and as far as the
                        seller is from an approved country list. There are
                        restrictions from Etsy that don’t allow sellers from
                        certain countries to sell on Etsy. Although it is free
                        to create an account on Etsy, each listing you create of
                        your product costs $0.20, along with 5% on the final
                        sale price of each listing is claimed by Etsy. Contact
                        us to know more about the complications involved and how
                        to work them out.
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: colors.black, my: 2, fontWeight: "bold" }}
                      >
                        What is Required to create Etsy Account?
                      </Typography>
                      <ul
                        style={{
                          lineHeight: 2,
                          color: colors.textPrimary,
                          fontSize: "14px",
                        }}
                      >
                        <li>
                          ID Document (Passport, National ID, Driver’s license)
                        </li>
                        <li>
                          Business information if not sole proprietor (company
                          name and registration number as per documents)
                        </li>
                        <li>Bank account details</li>
                      </ul>
                    </Box>
                  </Box>
                )}
                {/* {selectedIndex === 3 && (
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.black, fontWeight: "bold", my: 2 }}
                    >
                      4. Daraz ID Creation
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "justify",
                        mx: 3,
                        color: colors.textPrimary,
                      }}
                    >
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        Daraz is an ever-growing e-commerce market operating
                        exclusively in Pakistan. It allows you to create an
                        account as a seller by signing up on its website. Once
                        you have created an account and have updated your
                        product information, you will be able to list the
                        product for sale. There’s no registration or product
                        listing fee for Daraz.
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: colors.black, my: 2, fontWeight: "bold" }}
                      >
                        What is Required to create Daraz Account?
                      </Typography>
                      <ul
                        style={{
                          lineHeight: 2,
                          color: colors.textPrimary,
                          fontSize: "14px",
                        }}
                      >
                        <li>
                          ID Document (Passport, National ID, Driver’s license)
                        </li>
                        <li>
                          Business information (company name and registration
                          number as per documents)
                        </li>
                        <li>Personal details</li>
                        <li>Bank account information</li>
                      </ul>
                    </Box>
                  </Box>
                )} */}
                {selectedIndex === 4 && (
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.black, fontWeight: "bold", my: 2 }}
                    >
                      5. Shopify Store Creation
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "justify",
                        mx: 3,
                        color: colors.textPrimary,
                      }}
                    >
                      <Typography sx={{ my: 2, fontSize: "14px" }}>
                        It is a software service that allows you to create an
                        e-commerce store and sell and ship products to
                        customers. Shopify provides an easy-to-use admin panel
                        to add products, process store data, and manage the
                        orders. Shopify offers 14 days free trial, and then lets
                        to choose a pricing plan to get started with your
                        business professionally. It’s a time-consuming task to
                        optimize your Shopify store according to your business
                        needs, like organizing product listings, grouping
                        products, adding tax information, and shipping details
                        for each of them. When registering and setting up a
                        Shopify store, there are various points to consider. You
                        need to have a clear idea of what business you will do
                        and how to proceed with that.
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: colors.black, my: 2, fontWeight: "bold" }}
                      >
                        What is Required to create Shopify Store?
                      </Typography>
                      <ul
                        style={{
                          lineHeight: 2,
                          color: colors.textPrimary,
                          fontSize: "14px",
                        }}
                      >
                        {shopify.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Layout>
  );
}

export default EcommerceStoreCreation;
