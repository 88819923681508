import React from 'react';
import { ArrowForward, DoubleArrowOutlined } from '@mui/icons-material';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';

//*Import Components
import Layout from 'app/layout/Layout';
import colors from 'app/style/colors';
import Images from 'assets/Images';
import { Link } from 'react-router-dom';

function LLCService() {

  const courseIncludes = [
    'Develop your own FBA wholesale business',
    'How to provide VA services for FBA wholesale',
    'What benefits to selling and how to earn freelancing income from FBA WHOLESALE?',
    'How to prepare FBA wholesale proposals attendees skill analysis',
    'Training feedback',
    'Post-training Q&A to help with practical issues',
  ];

  return (
    <Layout>
      {/* ========== Service Banner ========== */}
      <Box sx={{
        marginTop: 1.2, borderRadius: '14px',
        background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${Images.trainingCourse})`,
        height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
      }
      }>
        <Typography variant="h2" sx={{
          color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
        }}>
          LLC Service
        </Typography>
        <Divider
          sx={{
            bgcolor: colors.primary,
            width: '140px',
            padding: '1px',
            margin: 'auto',
            fontSize: '28px',
            mb: 1
          }}
        />

        <Divider
          sx={{
            bgcolor: colors.primary,
            width: '100px',
            padding: '1px',
            margin: 'auto',
            fontSize: '28px'
          }}
        />
      </Box>
      <Container>
        <Grid sx={{ mt: 6 }}>
          <Box>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              Program Overview
            </Typography>
            <Typography variant="h6">
              You will have practical exposure to understand the end-to-end supply chain of
              amazon FBA wholesale. The aims to give the basic concepts, terminologies, business
              requirements, and training guidelines of all upcoming modules to gauge the attendee's
              performance, provide guidelines for developing own business and start earning by offering
              amazon FBA wholesale services. You will get everything in this course.
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h4"
              sx={{ fontWeight: 'bold', color: colors.black, mb: 2 }}
            >
              Learning Objective:
            </Typography>
            <Typography variant="h6">
              This program will build your expertise in developing/running your own FBA
              wholesale business and providing world-class VA services to international
              clients while mastering your wholesale skills.
            </Typography>
            <Typography variant="h6" sx={{ textAlign: 'left', my: 2, color: colors.textPrimary }}>
              {courseIncludes.map((item, index) => (
                <li key={index} style={{ listStyleType: "none", display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                  <DoubleArrowOutlined style={{ color: colors.primary, paddingRight: '12px' }} />
                  {item}
                </li>
              ))}
            </Typography>
          </Box>
        </Grid>
      </Container>
      {/* ========== Service Banner ========== */}
      <Box sx={{
        marginTop: 10,
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.lowerTrainingCourse})`,
        height: 220, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
      }
      }>

        <Grid sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{
            color: colors.white, marginTop: '15px', fontWeight: 'bold'
          }}>
            Should You Form a Limited Liability Company (LLC)?
          </Typography>
          <Typography variant="h4" sx={{
            color: colors.primary,
          }}>
            So what are you waiting for..?
          </Typography>
          <Button variant="contained"
            component={Link} to='/start-business'
            endIcon={<ArrowForward />}
            sx={{ mt: 2 }}
          >Form Your LLC Now</Button>
        </Grid>

      </Box>
    </Layout>
  )
}

export default LLCService

