import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles'

// *Imported Component
import colors from 'app/style/colors';




function LinearProgressWithLabel(props) {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mx: 3.5 }} >
      <Box sx={{ width: '100%', mr: 1 }} >
        <LinearProgress variant="determinate"  {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }} >
        <Typography variant="h6" fontWeight={"bold"} color={colors.black}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({ value }) {

  return (
    <Box sx={{ width: '100%', height: "130px" }}  >
      <LinearProgressWithLabel sx={{ height: "25px", borderRadius: "12px", bgcolor: "#b6ede0", ".MuiLinearProgress-bar": { bgcolor: colors.GreenyBlue } }} value={value} />
    </Box>
  );
}