import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Done, GppGood, HowToRegTwoTone, Person } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// *Imported Component
import colors from "app/style/colors";
import WhyUseUsAsAgent from "../WhyUseUsAsAgent/WhyUseUsAsAgent";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { Error } from "../UI/Error";
// import AgentInformation from "app/components/AgentInformation/AgentInformation";

const useStyles = makeStyles({
  textFieldSpace: {
    ".css-1bfz5r2": {
      px: 0,
      py: 4,
    },
  },
});

function AgentRegister() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: AgentRegister.js ~ line 40 ~ AgentRegister ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Agent Information Component Constant
  const classes = useStyles();

  //* Auto Complete State Select For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  const [selectedRadioIndividualValue, setSelectedRadioIndividualValue] =
    useState("individual");

  const handleIndividualChange = (event) => {
    setSelectedRadioIndividualValue(event.target.value);
  };

  const handleIndividualChangeBox = (value) => {
    setSelectedRadioIndividualValue(value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // Checkbox Name Details Data
  const [checkBox, setCheckBox] = useState(false);

  const handleCheckBox = () => {
    if (!checkBox) {
      setValue("agentF_Name", companyDetails?.userF_Name);
      setValue("agentL_Name", companyDetails?.userL_Name);
    } else {
      setValue("agentF_Name", "");
      setValue("agentL_Name", "");
    }
    setCheckBox(!checkBox);
  };

  //Agent Register Constant

  const [selectedRadioValue, setSelectedRadioValue] = useState("incfileAgent");

  // Agent Register Check Box Data For Order Summary Section
  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    const indexExpeditedValue = newDetailsData.findIndex(
      (item) => item[0] === "Registered Agent (1st Year)"
    );
    if (indexExpeditedValue === -1) {
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["Registered Agent (1st Year)", 0]],
      };
      dispatch(processContactDetails(obj));
    } else {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "Registered Agent (1st Year)"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: duplicate,
      };
      dispatch(processContactDetails(obj));
    }
  };

  const handleChangeBox = (value) => {
    if (value != selectedRadioValue) {
      setSelectedRadioValue(value);
      const newDetailsData = companyDetails.details;
      const duplicate = [...newDetailsData];
      const indexExpeditedValue = duplicate.findIndex(
        (item) => item[0] === "Registered Agent (1st Year)"
      );
      if (indexExpeditedValue === -1) {
        let obj = {
          ...companyDetails,
          details: [...duplicate, ["Registered Agent (1st Year)", 0]],
        };
        dispatch(processContactDetails(obj));
      } else {
        const indexExpeditedValue = companyDetails.details.findIndex(
          (item) => item[0] === "Registered Agent (1st Year)"
        );
        const data = duplicate.splice(indexExpeditedValue, 1);
        let obj = {
          ...companyDetails,
          details: duplicate,
        };
        dispatch(processContactDetails(obj));
      }
    }
  };

  // Store Data and merge with previous data

  const [agentData, setAgent] = useState("");
  console.log(
    "🚀 ~ file: AgentRegister.js ~ line 98 ~ AgentRegister ~ agentData",
    agentData
  );

  const agentInfo = async (data) => {
    console.log(
      "🚀 ~ file: AgentRegister.js ~ line 44 ~ agentInfo ~ data",
      data
    );
    // setLoading(true)
    try {
      setAgent("");
      let obj = {};
      console.log("selectedRadioValue", selectedRadioValue);
      if (selectedRadioValue === "incfileAgent") {
        console.log("incfileAgent");
        obj = {
          ...companyDetails,
          registeredAgentFree: true,
        };
      } else {
        console.log("selfAgent");
        if (selectedRadioIndividualValue === "individual") {
          console.log("individual");
          obj = {
            ...companyDetails,
            registeredAgentFree: false,
            agentF_Name: data.agentF_Name,
            agentL_Name: data.agentL_Name,
            agentStreetAddress: data.agentStreetAddress,
            agentAddress: data.agentAddress,
            agentCity: data.agentCity,
            agentState: data.agentState,
            agentZipCode: data.agentZipCode,
          };
        } else {
          console.log("Company");
          obj = {
            ...companyDetails,
            agentCompanyName: data.agentCompanyName,
            agentStreetAddress: data.agentStreetAddress,
            agentAddress: data.agentAddress,
            agentCity: data.agentCity,
            agentState: data.agentState,
            agentZipCode: data.agentZipCode,
          };
        }
      }
      setAgent(obj);
      console.log(obj);
      dispatch(processContactDetails(obj));
      navigate("/process-EIN-number");
    } catch (error) {
      console.log(
        "🚀 ~ file: AgentRegister.js ~ line 150 ~ agentInfo ~ error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(agentInfo)}>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <HowToRegTwoTone
            sx={{ color: colors.BrightCerulean, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Registered Agent Information
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: colors.secondary, my: 1 }}
          >
            Alaska requires an LLC to appoint a Registered Agent:
          </Typography>
          <Box sx={{ display: "flex", my: 1 }}>
            <Done sx={{ color: colors.primary, mr: 2 }} />
            <Typography variant="body2" sx={{ color: colors.secondary }}>
              Only Incfile offers{" "}
              <span style={{ fontWeight: "bold" }}>
                1 full year of Registered Agent service FREE
              </span>{" "}
              with every new business formation order - a $119.00 value!
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: colors.secondary, my: 1 }}
          >
            Typical documents received by your Registered Agent can include:
          </Typography>
          <Box sx={{ display: "flex", my: 1 }}>
            <Done sx={{ color: colors.primary, mr: 2 }} />
            <Typography variant="body2" sx={{ color: colors.secondary }}>
              Service of Process, i.e. notification of a pending lawsuit or
              court order
            </Typography>
          </Box>
          <Box sx={{ display: "flex", my: 1 }}>
            <Done sx={{ color: colors.primary, mr: 2 }} />
            <Typography variant="body2" sx={{ color: colors.secondary }}>
              State correspondence, i.e. annual reports or statements
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 3,
              borderRadius: "8px",
              p: 2,
              mx: "2",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "280px",
              cursor: "pointer",
              height: "100px",
            }}
            onClick={() => handleChangeBox("incfileAgent")}
          >
            <Radio
              checked={selectedRadioValue === "incfileAgent"}
              // onChange={handleChange}
              value="incfileAgent"
              name="radio-buttons"
            />
            <Box sx={{ mx: 1 }}>
              <Typography variant="body1" sx={{ color: colors.black }}>
                Assign Incfile as my Registered Agent{" "}
                <span style={{ fontWeight: "bold" }}>FREE</span> For 1 year.
              </Typography>
            </Box>
            <GppGood sx={{ color: colors.TealishGreen, fontSize: "46px" }} />
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 3,
              borderRadius: "8px",
              p: 2,
              mx: "2",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "280px",
              cursor: "pointer",
              height: "100px",
            }}
            onClick={() => handleChangeBox("selfAgent")}
          >
            <Radio
              checked={selectedRadioValue === "selfAgent"}
              // onChange={handleChange}
              value="selfAgent"
              name="radio-buttons"
            />
            <Box sx={{ mx: 1 }}>
              <Typography variant="body1" sx={{ color: colors.black }}>
                I would like to act as my own registered agent.
              </Typography>
            </Box>
          </Box>
        </Box>
        {
          selectedRadioValue === "incfileAgent" ? (
            <WhyUseUsAsAgent />
          ) : (
            // <AgentInformation />
            // Agent Information Component Section
            <Box
              sx={{
                boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
                p: 4,
                my: 4,
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", mb: 4, color: colors.black }}
              >
                Agent Information
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: colors.secondary, mb: 2 }}
              >
                You may serve as your own Registered Agent as long as you
                possess a physical street address{" "}
                <span style={{ fontWeight: "bold" }}>(PO Box not allowed)</span>{" "}
                in the state of formation.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 3,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.lightGray,
                    boxShadow: 2,
                    borderRadius: "8px",
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleIndividualChangeBox("individual")}
                >
                  <Radio
                    checked={selectedRadioIndividualValue === "individual"}
                    onChange={handleIndividualChange}
                    value="individual"
                    name="radio-buttons"
                    // inputProps={{ 'aria-label': 'A' }}
                  />
                  <Box sx={{ mx: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ color: colors.black, fontWeight: "bold" }}
                    >
                      Individual
                    </Typography>
                    <Typography
                      sx={{ color: colors.textSecondary, fontSize: "12px" }}
                    >
                      Select if Member is a person.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: colors.lightGray,
                    boxShadow: 2,
                    borderRadius: "8px",
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleIndividualChangeBox("company")}
                >
                  <Radio
                    checked={selectedRadioIndividualValue === "company"}
                    onChange={handleIndividualChange}
                    value="company"
                    name="radio-buttons"
                    // inputProps={{ 'aria-label': 'A' }}
                  />
                  <Box sx={{ mx: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ color: colors.black, fontWeight: "bold" }}
                    >
                      Company
                    </Typography>
                    <Typography
                      sx={{ color: colors.textSecondary, fontSize: "12px" }}
                    >
                      Select if Member is a company.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: 4 }}>
                {selectedRadioIndividualValue === "individual" && (
                  <FormGroup sx={{ my: 3 }}>
                    <FormControlLabel
                      control={<Checkbox onChange={() => handleCheckBox()} />}
                      label={
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: colors.secondary,
                          }}
                        >
                          <Person sx={{ mr: 1 }} />
                          {companyDetails.userF_Name}{" "}
                          {companyDetails.userL_Name}
                        </span>
                      }
                    />
                  </FormGroup>
                )}
                <Box sx={{ my: 4 }}>
                  <Grid
                    container
                    className={classes.textFieldSpace}
                    sx={{ justifyContent: "space-between" }}
                    spacing={3}
                  >
                    {selectedRadioIndividualValue === "individual" ? (
                      <Fragment>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ my: 1 }}
                          >
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="First Name"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={errors?.agentF_Name?.message && true}
                              {...register("agentF_Name", {
                                required: "Please enter your first name.",
                              })}
                            />
                            {errors?.agentF_Name?.message && (
                              <Error message={errors?.agentF_Name?.message} />
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ my: 1 }}
                          >
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Last Name"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={errors?.agentL_Name?.message && true}
                              {...register("agentL_Name", {
                                required: "Please enter your last name.",
                              })}
                            />
                            {errors?.agentL_Name?.message && (
                              <Error message={errors?.agentL_Name?.message} />
                            )}
                          </FormControl>
                        </Grid>
                      </Fragment>
                    ) : (
                      <Grid item xs={12} md={6} sx={{ mr: 4 }}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ my: 1 }}
                        >
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Company Name"
                            variant="outlined"
                            error={errors?.agentCompanyName?.message && true}
                            {...register("agentCompanyName", {
                              required: "Please enter your company name.",
                            })}
                          />
                          {errors?.agentCompanyName?.message && (
                            <Error
                              message={errors?.agentCompanyName?.message}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Street Address"
                          variant="outlined"
                          error={errors?.agentStreetAddress?.message && true}
                          {...register("agentStreetAddress", {
                            required: "Please enter your street address.",
                          })}
                        />
                        {errors?.agentStreetAddress?.message && (
                          <Error
                            message={errors?.agentStreetAddress?.message}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Address (Cont)"
                          variant="outlined"
                          error={errors?.agentAddress?.message && true}
                          {...register("agentAddress", {
                            required: "Please enter your address.",
                          })}
                        />
                        {errors?.agentAddress?.message && (
                          <Error message={errors?.agentAddress?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          error={errors?.agentCity?.message && true}
                          {...register("agentCity", {
                            required: "Please enter your city.",
                          })}
                        />
                        {errors?.agentCity?.message && (
                          <Error message={errors?.agentCity?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} md={6} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ my: 1 }}
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={mailStateSelect}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                error={errors?.agentState?.message && true}
                                {...register("agentState", {
                                  required: "Please enter state.",
                                })}
                              />
                            )}
                          />
                          {errors?.agentState?.message && (
                            <Error message={errors?.agentState?.message} />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ my: 1 }}
                        >
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Zip Code"
                            type="number"
                            variant="outlined"
                            error={errors?.agentZipCode?.message && true}
                            {...register("agentZipCode", {
                              required: "Please enter your zip code.",
                            })}
                          />
                          {errors?.agentZipCode?.message && (
                            <Error message={errors?.agentZipCode?.message} />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )
          // Agent Information Component Section End
        }
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          my: 4,
        }}
      >
        <Button
          component={Link}
          to="/process-members"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          // onClick={handleSubmit}
          // component={Link}
          // to="/process-EIN-number"
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default AgentRegister;
