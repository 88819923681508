import { Box, Button, Divider, Radio, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// * Imported Component
import colors from "app/style/colors";
import { Done, FollowTheSignsTwoTone, Lightbulb } from "@mui/icons-material";
import { useForm } from "react-hook-form";

function TaxProcess() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ProcessPostage.js ~ line 16 ~ ProcessPostage ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedRadioValue, setSelectedRadioValue] = useState("NoBusinessTax");

  // Agent Register Check Box Data For Order Summary Section
  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    const indexExpeditedValue = newDetailsData.findIndex(
      (item) => item[0] === "Tax Consultation"
    );
    if (indexExpeditedValue === -1) {
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["Tax Consultation", "Free"]],
      };
      dispatch(processContactDetails(obj));
    } else {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "Tax Consultation"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: duplicate,
      };
      dispatch(processContactDetails(obj));
    }
  };

  const handleChangeBox = (value) => {
    if (value != selectedRadioValue) {
      setSelectedRadioValue(value);
      const newDetailsData = companyDetails.details;
      const duplicate = [...newDetailsData];
      const indexExpeditedValue = duplicate.findIndex(
        (item) => item[0] === "Tax Consultation"
      );
      if (indexExpeditedValue === -1) {
        let obj = {
          ...companyDetails,
          details: [...duplicate, ["Tax Consultation", "Free"]],
        };
        dispatch(processContactDetails(obj));
      } else {
        const indexExpeditedValue = companyDetails.details.findIndex(
          (item) => item[0] === "Tax Consultation"
        );
        const data = duplicate.splice(indexExpeditedValue, 1);
        let obj = {
          ...companyDetails,
          details: duplicate,
        };
        dispatch(processContactDetails(obj));
      }
    }
  };

  // *Getting Package Name from Local Storage
  let packageName = localStorage.getItem("package");

  // Store Data and merge with previous data

  const { handleSubmit } = useForm();

  const [taxProcess, setTaxProcess] = useState("");
  console.log(
    "🚀 ~ file: TaxProcess.js ~ line 46 ~ TaxProcess ~ taxProcess",
    taxProcess
  );

  const taxProcessInfo = async (data) => {
    console.log(
      "🚀 ~ file: TaxProcess.js ~ line 50 ~ taxProcessInfo ~ data",
      data
    );

    // setLoading(true)
    try {
      setTaxProcess("");
      let obj = {};
      console.log("selectedRadioValue", selectedRadioValue);
      if (selectedRadioValue === "YesBusinessTax") {
        console.log("YesBusinessTax");
        obj = {
          ...companyDetails,
          processTaxInvite: selectedRadioValue,
        };
      } else {
        console.log("NoBusinessTax");
        obj = {
          ...companyDetails,
          processTaxInvite: selectedRadioValue,
        };
      }
      setTaxProcess(obj);
      console.log(obj);
      dispatch(processContactDetails(obj));
      navigate("/process-license");
    } catch (error) {
      console.log(
        "🚀 ~ file: TaxProcess.js ~ line 76 ~ taxProcessInfo ~ error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(taxProcessInfo)}>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FollowTheSignsTwoTone
            sx={{ color: colors.GreenyBlue, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Tax Strategy / Free Consultation
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary }}>
          This consultation can help you identify important tax deductions and
          provide insight into the IRS tax classification of your new business.
          The offer is completely free of charge and does not obligate you in
          any way.
        </Typography>
        <Box sx={{ mt: 4, mb: 3 }}>
          <Typography sx={{ fontWeight: "bold", my: 2 }}>
            What you'll learn:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Done sx={{ color: colors.primary, mr: 1 }} />
                <Typography>How your LLC is taxed</Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Done sx={{ color: colors.primary, mr: 1 }} />
                <Typography>
                  How to choose the proper IRS tax election
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Done sx={{ color: colors.primary, mr: 1 }} />
                <Typography>Commonly missed tax deductions</Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Done sx={{ color: colors.primary, mr: 1 }} />
                <Typography>Business bookkeeping requirements</Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Done sx={{ color: colors.primary, mr: 1 }} />
                <Typography>
                  How to reduce the chance of an IRS audit
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 1 }}>
                <Done sx={{ color: colors.primary, mr: 1 }} />
                <Typography>How to reduce self employment taxes</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 3,
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "280px",
              height: "80px",
            }}
            onClick={() => handleChangeBox("NoBusinessTax")}
          >
            <Radio
              checked={selectedRadioValue === "NoBusinessTax"}
              // onChange={handleChange}
              value="NoBusinessTax"
              name="radio-buttons"
            />
            <Typography variant="body1" sx={{ color: colors.black, mx: 2 }}>
              I'm not interested at this time.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "280px",
              height: "80px",
            }}
            onClick={() => handleChangeBox("YesBusinessTax")}
          >
            <Radio
              checked={selectedRadioValue === "YesBusinessTax"}
              // onChange={handleChange}
              value="YesBusinessTax"
              name="radio-buttons"
            />
            <Typography variant="body1" sx={{ color: colors.black, mx: 2 }}>
              Yes, I would like to receive the Business Tax Consultation
              <span style={{ fontWeight: "bold" }}>(FREE).</span>
            </Typography>
          </Box>
        </Box>
        {selectedRadioValue === "NoBusinessTax" && selectedRadioValue !== "" ? (
          <Box
            sx={{
              backgroundColor: colors.portlandOrange,
              borderRadius: "8px",
              p: 3,
              display: "flex",
              alignItems: "center",
              my: 4,
            }}
          >
            <Lightbulb sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
            <Box>
              <Typography sx={{ color: colors.black, fontWeight: "bold" }}>
                Is a Tax Strategy / Free Consultation something I should
                consider?
              </Typography>
              <Typography sx={{ color: colors.textPrimary, fontSize: "16px" }}>
                Incorporating a new business can burden owners with complicated
                tax filing. As a client you are entitled to receive a free no
                obligation consultation with a certified tax professional who
                can answer questions regarding the tax obligations of your
                company.
              </Typography>
            </Box>
          </Box>
        ) : null}
        {selectedRadioValue === "YesBusinessTax" &&
        selectedRadioValue !== "" ? (
          <Box
            sx={{
              backgroundColor: colors.portlandOrange,
              borderRadius: "8px",
              p: 3,
              display: "flex",
              alignItems: "center",
              my: 4,
            }}
          >
            <Lightbulb sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
            <Box>
              <Typography sx={{ color: colors.black, fontWeight: "bold" }}>
                How soon can I schedule my Tax Consultation?
              </Typography>
              <Typography sx={{ color: colors.textPrimary, fontSize: "16px" }}>
                You will be prompted to schedule your appointment after
                completing your order.
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to={
            packageName === "platinum"
              ? "/process-businessBanking"
              : packageName === "gold"
              ? "/process-businessBanking"
              : "/process-postage"
          }
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          // component={Link}
          // to="/process-license"
          variant="contained"
          //onClick={handleSubmit}
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default TaxProcess;
