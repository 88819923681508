import React, { Fragment } from "react";
import {
  CheckCircleOutline,
  CurrencyExchange,
  Done,
  LockReset,
} from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";

// *Redux Components
import { useSelector } from "react-redux";

// *Import Component
import colors from "app/style/colors";
import PriceProgressSumary from "../PriceProgressSumary/PriceProgressSumary";

// const silverPackageDetails = [["Electronic Delivery", true]];
// console.log(
//   "🚀 ~ file: OrderSummary.js ~ line 17 ~ silverPackageDetails",
//   silverPackageDetails
// );
// const goldPackageDetails = [["Electronic Delivery", true]];

// const platinumPackageDetails = [["Electronic Delivery", true]];

function OrderSummary({ value }) {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ContactDetails.js ~ line 31 ~ ContactDetails ~ companyData",
    companyDetails
  );

  const packagePrice = JSON.parse(localStorage.getItem("packagePriceDetails"));
  const packageName = localStorage.getItem("package");
  const serviceName = localStorage.getItem("ServiceName");
  return (
    <Box
      sx={{
        borderRadius: "12px",
        boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
        py: 5,
        px: 3,
        my: 4,
        color: colors.black,
        cursor: "pointer",
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
        Order Summary
      </Typography>
      <Typography
        variant="h3"
        sx={{
          my: 3,
          textAlign: "center",
          color: colors.black,
          fontWeight: "bold",
        }}
      >
        ${packagePrice[0]}
      </Typography>
      <PriceProgressSumary value={value} />
      <Box sx={{ mx: 3 }}>
        <Divider />
        <Box sx={{ py: 2, color: colors.textPrimary }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography>Entity Type</Typography>
            <Typography>{serviceName}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography>
              {packageName.charAt(0).toUpperCase() + packageName.slice(1)}{" "}
              Package
            </Typography>
            <Typography>${packagePrice[1]}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography>State Filing Fee</Typography>
            <Typography>${packagePrice[2]}</Typography>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography>Banking Resolution</Typography>
            <Done />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography>Operation Agreement</Typography>
            <Done />
          </Box> */}
          {/* {packageName === "silver" ? (
            <Fragment>
              {silverPackageDetails.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                >
                  <Typography>{item[0]}</Typography>
                  {item[1] === true ? <Done /> : item[1]}
                </Box>
              ))}
            </Fragment>
          ) : packageName === "gold" ? (
            <Fragment>
              {goldPackageDetails.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                >
                  <Typography>{item}</Typography>
                  <Done />
                </Box>
              ))}
            </Fragment>
          ) : packageName === "platinum" ? (
            <Fragment>
              {platinumPackageDetails.map((item, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 1,
                  }}
                >
                  <Typography>{item}</Typography>
                  <Done />
                </Box>
              ))}
            </Fragment>
          ) : null} */}
          {companyDetails.details?.map((item, index) => (
            <Fragment>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  my: 1,
                }}
              >
                <Typography>{item[0]}</Typography>
                {item[1] === true ? (
                  <Done />
                ) : typeof item[1] === "string" ? (
                  item[1]
                ) : (
                  `$${item[1]}`
                )}
              </Box>
            </Fragment>
          ))}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography>Electronic Delivery</Typography>
            <Done />
          </Box> */}
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 4,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Charge Total</Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            ${packagePrice[0]}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ cursor: "pointer", my: 4 }}>
          <Box sx={{ display: "flex", my: 2 }}>
            <CheckCircleOutline
              sx={{ mr: 2, fontSize: "40px", color: colors.BrightCerulean }}
            />
            <Box>
              <Typography sx={{ fontWeight: "bold", color: colors.black }}>
                Start your business with confidence
              </Typography>
              <Typography
                sx={{ color: colors.textSecondary, fontSize: "14px" }}
              >
                Trusted by over 500,000 business owners since 2004.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", my: 2 }}>
            <CurrencyExchange
              sx={{ mr: 2, fontSize: "36px", color: colors.WarmPurple }}
            />
            <Box>
              <Typography sx={{ fontWeight: "bold", color: colors.black }}>
                Tax savings benefit
              </Typography>
              <Typography
                sx={{ color: colors.textSecondary, fontSize: "14px" }}
              >
                This is a fully deductible business expense.
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", my: 2 }}>
            <LockReset
              sx={{ mr: 2, fontSize: "42px", color: colors.LapisBlue }}
            />
            <Box>
              <Typography sx={{ fontWeight: "bold", color: colors.black }}>
                Safe & Secure
              </Typography>
              <Typography
                sx={{ color: colors.textSecondary, fontSize: "14px" }}
              >
                Your information and data is safe and secure. Our servers are
                located in secure data centers and our website uses SSL modern
                encryption for all sensitive data. Our servers are also backed
                up hourly ensuring your data is never lost.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OrderSummary;
