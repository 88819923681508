import React, { useState } from 'react';
import { AddToPhotos, ArrowForward } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

//*Import Components
import Layout from 'app/layout/Layout';
import colors from 'app/style/colors';
import Images from 'assets/Images';
import { Link } from 'react-router-dom';

function USFormation() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (

    <Layout>
      <Container>
        {/* ========== Header ========== */}
        <Grid container sx={{ bgcolor: colors.lightGray, justifyContent: 'space-between' }}>
          <Grid item xs={12} md={6}
          >
            <Typography variant="h4" sx={{
              color: colors.black, textAlign: 'center', mt: 10, mx: 10,
              fontWeight: 'bold',
            }}>
              Company Registration in US
            </Typography>
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '140px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px',
                mb: 1
              }}
            />

            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '100px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px'
              }}
            />
            <Typography sx={{ my: 3, ml: 3 }}>If you are thinking of expanding your business to some more prominent marketplaces or an eCommerce platform, a company in the US is the best way to start with. Whether you are a non-resident or a US citizen living outside of the country, you can form a company in the US. </Typography>
            <Typography sx={{ ml: 3 }}>Before filing a company, one should know which company structure to go forward with. One can go with multiple entity types, but a limited liability company (LLC) is the best business structure for forming a business in the USA.</Typography>
            <Button
              component={Link} to='/start-business'
              variant="contained"
              endIcon={<ArrowForward />}
              sx={{ my: 3, borderRadius: 8, mx: 18, py: 2, fontWeight: 'bold', fontSize: '14px' }}
            >
              Form Your US Company
            </Button>


          </Grid>
          <Grid item xs={12} md={5} sx={{
            borderRadius: '14px', mr: 3,
            background: `url(${Images.companyFormationBanner})`,
            position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
          }
          } />

        </Grid>

        <Grid sx={{ my: 7 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1, color: colors.black }}>There are mainly five types of company structure you can choose from, including: </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '200px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
          <Box>
            <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
              <Grid item xs={12} md={3.5}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1, height: '300px'
                }}>
                <Box >
                  <Box sx={{ textAlign: 'justify', mt: 3 }}>
                    <List component="nav" >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 0)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Limited Liability Company or LLC" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 1)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="C Corporation" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 2}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 2)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="S Corporation" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 3}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 3)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Non-profit" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 4}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 4)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Sole Proprietorship" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1
                }}
              >
                {
                  selectedIndex === 0 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Limited liability company or LLC </Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary }}>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>A Limited liability company is a legal business structure under state regulation. The owners of LLC are called members, and there is no limit to how many members can be added as an LLC owner. Moreover, the majority of the states in the US allow unrestricted ownership, so members may include individuals, foreign bodies, other LLCs, and corporations. </Typography>
                      <Typography sx={{ mb: 2, fontSize: "14px" }}> An LLC is a preferred type of entity due to its more straightforward setup and management with a simple business structure compared to other conventional corporation types. </Typography>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, mb: 2, fontSize: "14px" }}
                        >This eml:1,ntity framework is suitable for most small and start-up businesses due to its pass-through taxation element, similar to a sole proprietorship and liability protection of a corporation. </Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, mb: 2, fontSize: "14px" }}
                        >The policies, process, and cost of registering LLCs vary state-wise, so one should have these things in mind before the process is started. </Typography>
                      </Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>LLC comes with liability protection:</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>Limited liability protection means if your business assets are under some legal liability, debt, or claims, your personal asset will stay intact and protected. As only LLC possessions are used to pay any business debts, the owners only lose the money invested in an LLC.</Typography>
                      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: colors.black }}>What to keep in mind about LLC Taxes?</Typography>
                      <Typography sx={{ mb: 2, fontSize: "14px" }}>Taxation in an LLC is very similar to sole-proprietorship LLC itself does not pay any federal income tax on business; instead, it is passed through to the owner’s personal tax returns. After that, it is taxed by IRS as a personal income. There are various taxes an LLC is liable to pay, which are usually abated as business expenditures and do not add up in the owner’s tax returns. </Typography>
                      <Typography variant="h6" sx={{ mb: 2, fontSize: "14px", fontWeight: 'bold', color: colors.black }}>Type of taxes include:</Typography>
                      <ul style={{ fontSize: "14px", marginBottom: "30px" }}>
                        <li>Property tax on a property under company ownership</li>
                        <li>Sales Tax on items acquired by/for the company </li>
                        <li>Payroll Taxes on company employees’ salaries</li>
                        <li>Self-employment tax paid by the company owners</li>
                      </ul>
                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 1 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >C Corporation</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, mb: 2, fontSize: "14px" }}
                        >It is a conventional corporation under IRS rules and acts as a separate legal entity to protect the owner from company debt as well as personal liability.</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, mb: 2, fontSize: "14px" }}
                        >Although it’s the oldest and successful business entity type , it involves more formalities and is expensive to set up.</Typography>
                      </Box>
                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 2 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >S Corporation</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Box sx={{ display: 'flex', my: 2 }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >The IRS offers a special tax status in S corporation with some tax benefits. Therefore, S corporation holds the corporation's stature founded to eliminate the need to pay double taxation as in conventional corporations. </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', my: 2 }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >Most of the new, especially smaller corporations and family businesses, opt for S corporations so they can add profit or loss to shareholders’ personal tax returns to avoid double taxation.</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', my: 2 }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >It involves more legal complications and paperwork than LLC, but it is easier to run an S Corp than a C corporation.</Typography>
                      </Box>
                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 3 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Non-profit </Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >These are corporations regulated under state law for specific purposes like charity, education, research, etc.</Typography>
                      </Box>

                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 4 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Sole Proprietorship </Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Box sx={{ display: 'flex', my: 2 }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >This is an unincorporated business structure owned by a single person with full authority over the business and referred to as a sole proprietor.It is not formed as a separate entity, and the business owner is personally liable for the company, including its debt. </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', my: 2 }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >The tax returns on all the income and expenses are paid under personal income tax.</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', my: 2 }}>
                        <ArrowForward sx={{ color: colors.primary }} />
                        <Typography
                          sx={{ ml: 1, fontSize: "14px" }}
                        >Setting up such an entity is more accessible, but there’s no recognition at the state level.</Typography>
                      </Box>
                    </Box>
                  </Box>
                }

              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid sx={{ bgcolor: colors.lightGray, p: 2 }}>
          <Typography variant="h5" color={colors.black}
            sx={{ my: 2, fontWeight: 'bold' }}> How to get started with an LLC formation in the US?</Typography>
          <ol style={{ lineHeight: 2.5, fontSize: '18px', color: colors.textPrimary }}>
            <li>Decide a State you want your company to register in</li>
            <li>Choose a name for your company and check its availability in your chosen state</li>
            <li>Appoint a registered agent </li>
            <li>File for the certificate of formation/Article of organization</li>
            <li>Create an operating agreement to make your company more legitimate </li>
            <li>Get employee Identification Number (EIN)</li>
          </ol>
        </Grid>
      </Container >
    </Layout >
  )
}

export default USFormation