// *Import Components
import MyProfile from "app/views/MyProfile/MyProfile";
import MyInvoice from "app/views/MyInvoice/MyInvoice";
import MyOrders from "app/views/MyOrders/MyOrders";
import MyRequirements from "app/views/MyRequirements/MyRequirements";
import MyAccount from "app/views/MyAccount/MyAccount";
import MyAddress from "app/views/MyAddress/MyAddress";

const PrivateRoutes = [

  {
    path: "/myprofile",
    component: <MyProfile />,
  },
  {
    path: "/myorder",
    component: <MyOrders />,
  },
  {
    path: "/myrequirements/:id",
    component: <MyRequirements />,
  },
  {
    path: "/myinvoice",
    component: <MyInvoice />,
  },
  {
    path: "/myaccount",
    component: <MyAccount />,
  },
  {
    path: "/myaddress",
    component: <MyAddress />,
  },
]

export default PrivateRoutes