import axios from 'axios';

export const server = 'https://masher.mangotech-apps.com/api';

const instance = axios.create({
  baseURL: server,
});

instance.interceptors.request.use((request) => {

  const token = localStorage.getItem('jwt')

  request.headers = {
    'Authorization': `Bearer ${token}`
  }

  return request
});

export default instance;