import { Card, CardContent, Container, Grid, Typography } from '@mui/material'
import colors from 'app/style/colors'
import React from 'react'

function Qualities() {
  return (
    <Container>
      <Grid item md={12} container spacing={2} >
        <Grid item md={3} xs={12} sm={6} sx={{ my: 4 }}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 3,
              height: '120px',
              border: '1px solid rgba(145, 158, 171, 0.24)',
              bgcolor: 'transparent !important',
              transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: colors.white,
                boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                'h6': {
                  color: colors.primary,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }
              }
            }}
          >
            <CardContent>
              <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 2, fontWeight: 700, fontFamily: 'Barlow' }} >
                Fast and accurate information Processing and filing
              </Typography>

            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sm={6} sx={{ my: 4 }}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 3,
              height: '120px',
              border: '1px solid rgba(145, 158, 171, 0.24)',
              bgcolor: 'transparent !important',
              transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: colors.white,
                boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                'h6': {
                  color: colors.primary,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }
              }
            }}
          >
            <CardContent>
              <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 3, fontWeight: 700, fontFamily: 'Barlow' }} >
                Compliance as committed
              </Typography>

            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sm={6} sx={{ my: 4 }}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 3,
              height: '120px',
              border: '1px solid rgba(145, 158, 171, 0.24)',
              bgcolor: 'transparent !important',
              transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: colors.white,
                boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                'h6': {
                  color: colors.primary,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }
              }
            }}
          >
            <CardContent>
              <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 3, fontWeight: 700, fontFamily: 'Barlow' }} >
                Competitive service charges
              </Typography>

            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} xs={12} sm={6} sx={{ my: 4 }}>
          <Card
            elevation={0}
            sx={{
              borderRadius: 3,
              textALign: 'center',
              height: '120px',
              border: '1px solid rgba(145, 158, 171, 0.24)',
              bgcolor: 'transparent !important',
              transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: colors.white,
                boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                'h6': {
                  color: colors.primary,
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                }
              }
            }}
          >
            <CardContent>
              <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, my: 0.7, fontWeight: 700, fontFamily: 'Barlow' }} >
                Perfect packages to meet all your e-Commerce business needs
              </Typography>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Qualities