import React from 'react'
import { Container, Divider, Typography, Box, Grid, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
//* Import Component
import Layout from 'app/layout/Layout'
import Images from 'assets/Images'
import colors from 'app/style/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const stateFiling = [
  {
    name: "Alabama",
    price: "$ 237",
    duration: "3 Weeks State filing time",
  },
  {
    name: "Alaska",
    price: "$250",
    duration: "",
  },
  {
    name: "Arizona",
    price: "$85",
    duration: "",
  },
  {
    name: "Arkansas",
    price: "$50",
    duration: "",
  },
  {
    name: "California",
    price: "$75",
    duration: "",
  },
  {
    name: "Colorado",
    price: "$50",
    duration: "",
  },
  {
    name: "Connecticut",
    price: "$120",
    duration: "",
  },
  {
    name: "Delaware",
    price: "$110",
    duration: "",
  },
  {
    name: "Florida",
    price: "$125",
    duration: "",
  },
  {
    name: "Georgia",
    price: "$100",
    duration: "",
  },
  {
    name: "Hawaii",
    price: "$51",
    duration: "",
  },
  {
    name: "Idaho",
    price: "$100",
    duration: "",
  },
  {
    name: "Illinois",
    price: "$154",
    duration: "",
  },
  {
    name: "Indiana",
    price: "$98",
    duration: "",
  },
  {
    name: "Iowa",
    price: "$50",
    duration: "",
  },
  {
    name: "Kansas",
    price: "$160",
    duration: "",
  },
  {
    name: "Kentucky",
    price: "$40",
    duration: "",
  },
  {
    name: "Louisiana",
    price: "$105",
    duration: "",
  },
  {
    name: "Maine",
    price: "$178",
    duration: "",
  },
  {
    name: "Maryland",
    price: "$197",
    duration: "",
  },
  {
    name: "Massachusetts",
    price: "$520",
    duration: "",
  },
  {
    name: "Michigan",
    price: "$50",
    duration: "",
  },
  {
    name: "Minnesota",
    price: "$155",
    duration: "",
  },
  {
    name: "Mississippi",
    price: "$53",
    duration: "",
  },
  {
    name: "Missouri",
    price: "$52",
    duration: "",
  },
  {
    name: "Montana",
    price: "$70",
    duration: "",
  },
  {
    name: "Nebraska",
    price: "$109",
    duration: "",
  },
  {
    name: "Nevada",
    price: "$425",
    duration: "",
  },
  {
    name: "New Hampshire",
    price: "$102",
    duration: "",
  },
  {
    name: "New Jersey",
    price: "$130",
    duration: "",
  },
  {
    name: "New Mexico",
    price: "$50",
    duration: "",
  },
  {
    name: "New York",
    price: "$205",
    duration: "",
  },
  {
    name: "North Carolina",
    price: "$127",
    duration: "",
  },
  {
    name: "North Dakota",
    price: "$135",
    duration: "",
  },
  {
    name: "Ohio",
    price: "$99",
    duration: "",
  },
  {
    name: "Oklahoma",
    price: "$104",
    duration: "",
  },
  {
    name: "Oregon",
    price: "$100",
    duration: "",
  },
  {
    name: "Pennsylvania",
    price: "$131",
    duration: "",
  },
  {
    name: "Rhode Island",
    price: "$156",
    duration: "",
  },
  {
    name: "South Carolina",
    price: "$150",
    duration: "",
  },
  {
    name: "South Dakota",
    price: "$150",
    duration: "",
  },
  {
    name: "Tennessee",
    price: "$308",
    duration: "",
  },
  {
    name: "Texas",
    price: "$300",
    duration: "",
  },
  {
    name: "Utah",
    price: "$76",
    duration: "",
  },
  {
    name: "Vermont",
    price: "$125",
    duration: "",
  },
  {
    name: "Virginia",
    price: "$100",
    duration: "",
  },
  {
    name: "Washington DC",
    price: "$200",
    duration: "",
  },
  {
    name: "West Virginia",
    price: "$125",
    duration: "",
  },
  {
    name: "Wisconsin",
    price: "$130",
    duration: "",
  },
  {
    name: "Wyoming",
    price: "$102",
    duration: "",
  },
]

function StateFiling() {
  return (
    <Layout>
      <Container>
        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.stateFiling})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'
        }
        }>

          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            State Filing
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '140px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '100px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>
        <Grid sx={{ my: 5, cursor: 'pointer' }}>
          <TableContainer component={Paper} sx={{ height: 700 }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 20 }}>State</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontWeight: 'bold', fontSize: 20 }}>Fee</StyledTableCell>
                  <StyledTableCell align="right" sx={{ fontWeight: 'bold', fontSize: 20 }}>Processing Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stateFiling.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.price}</StyledTableCell>
                    <StyledTableCell align="right">{row.duration}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </Layout>
  )
}

export default StateFiling