import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, TextField } from '@mui/material'
//* Import Component
import colors from 'app/style/colors'
import { Loader } from 'assets/Images'
import SideNav from 'app/components/SideNav/SideNav';
import Layout from 'app/layout/Layout';
import useAuth from 'app/hooks/useAuth';
import ProfileDetail from 'app/components/ProfileDetail/ProfileDetail';
import { Service } from 'app/config/service';

function MyAddress() {

  const { user } = useAuth();
  const userId = user.split("_")[0]

  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(true);

  //* Get Order Details API
  const getUserInfo = async () => {
    try {
      const { Data } = await Service.getUserInfo(userId)
      console.log('file: MyAddress.js => line 24 => getUserInfo => Data', Data);
      setUserData(Data)
      setLoader(false)

    } catch (error) {
      console.log('file: MyAddress.js => line 29 => getUserInfo => error', error);
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [])


  return (
    <Layout>
      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
        <Grid item xs={12} md={2}
          sx={{
            bgcolor: colors.white, textAlign: "center", boxShadow: 3,
            borderRadius: '12px', mx: 4, height: '180px'
          }}
        >

          <SideNav indexValue={0} />

        </Grid>
        <Grid item xs={12} md={8}>
          <ProfileDetail id={userId} pageName={"Personal Details"} />
          <Grid sx={{
            borderRadius: '10px', boxShadow: 3, padding: 1,

          }}>
            {loader ? (
              <Box sx={{ textAlign: 'center' }}>
                <Loader />
              </Box>) : (<Box
                sx={{ alignItems: 'center', my: 3 }}>
                <Box component={"form"} >
                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      id="outlined-textarea"
                      defaultValue={userData?.Address}
                      multiline
                      variant="outlined"
                      label="Business/Ressidential Address"
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>
                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="City"
                      defaultValue={userData?.City}
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>
                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="State"
                      defaultValue={userData?.State}
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>
                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Country"
                      defaultValue={userData?.Country}
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>

                </Box>
              </Box>)}

          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}


export default MyAddress

