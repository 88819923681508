import React from 'react'
import { Grid } from '@mui/material'
import Layout from 'app/layout/Layout'

//*Imported Component
import OrderSummary from 'app/components/OrderSummary/OrderSummary'
import MemberInfo from 'app/components/MemberInfo/MemberInfo'

function ProcessMember() {

  return (
    <Layout>
      <Grid container spacing={3} sx={{ justifyContent: "space-evenly", p: 3 }}>
        <Grid item xs={12} md={7}>
          <MemberInfo />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <OrderSummary value={55} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default ProcessMember