import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { Assignment, Person, Receipt } from '@mui/icons-material';
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
//* Import Component
import colors from 'app/style/colors';

function SideNav({ indexValue }) {
  const [selectedIndex, setSelectedIndex] = useState(indexValue);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (
    <Fragment>
      <Box sx={{ textAlign: 'justify' }}>
        <List component="nav" >
          <ListItemButton
            component={Link} to='/myprofile'
            selected={selectedIndex === 0}
            sx={{
              '&.Mui-selected': {
                bgcolor: '#344768',
                color: colors.white,
                '.MuiSvgIcon-root': {
                  color: colors.white,
                },
              },

              '&:hover': {

                '&.Mui-selected': {
                  color: '#344768',
                  bgcolor: colors.lightGray,
                },
                '.MuiSvgIcon-root': {
                  color: '#344768',
                },
              }
            }}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <Person sx={{ color: '#344768' }} />
            </ListItemIcon>
            <ListItemText primary="My Profile" sx={{ ml: -1.5 }} />
          </ListItemButton>
          <ListItemButton
            component={Link} to='/myorder'
            selected={selectedIndex === 1}
            sx={{
              '&.Mui-selected': {
                bgcolor: '#344768',
                color: colors.white,
                '.MuiSvgIcon-root': {
                  color: colors.white,
                },
              },

              '&:hover': {

                '&.Mui-selected': {
                  color: '#344768',
                  bgcolor: colors.lightGray,
                },
                '.MuiSvgIcon-root': {
                  color: '#344768',
                },
              }
            }}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <Assignment sx={{ color: '#344768' }} />
            </ListItemIcon>
            <ListItemText primary="My Orders" sx={{ ml: -1.5 }} />
          </ListItemButton>
          <ListItemButton
            component={Link} to='/myinvoice'
            selected={selectedIndex === 3}
            sx={{
              '&.Mui-selected': {
                bgcolor: '#344768',
                color: colors.white,
                '.MuiSvgIcon-root': {
                  color: colors.white,
                },
              },

              '&:hover': {

                '&.Mui-selected': {
                  color: '#344768',
                  bgcolor: colors.lightGray,
                },
                '.MuiSvgIcon-root': {
                  color: '#344768',
                },
              }
            }}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon>
              <Receipt sx={{ color: '#344768' }} />
            </ListItemIcon>
            <ListItemText primary="Invoice" sx={{ ml: -1.5 }} />
          </ListItemButton>

        </List>
      </Box>
    </Fragment>
  )
}

export default SideNav