import {
  Lightbulb,
  AddLocationAltTwoTone,
  Person,
  LocationOn,
  FactCheckTwoTone,
  AssignmentTurnedInTwoTone,
  Done,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// * Imported Components
import colors from "app/style/colors";
import { useTheme } from "@emotion/react";
import { useForm } from "react-hook-form";
import { Error } from "../UI/Error";

function TaxIdentificationInfo() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: AgentRegister.js ~ line 40 ~ AgentRegister ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  //* Auto Complete State Select For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  // Security Number
  const [selectedSecurityValue, setSelectedSecurityValue] = useState("no");
  const [selectedEINValue, setSelectedEINValue] = useState("SSN");

  const handleSecurityChange = (event) => {
    setSelectedSecurityValue(event.target.value);
    setOpenForeignModal(false);
  };
  const handleEINChange = (event) => {
    setSelectedEINValue(event.target.value);
    setOpenITINModal(false);
  };

  // *Const for Modal of Foreign Individual
  const [openForeignModal, setOpenForeignModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleForeignModalOpen = () => {
    setOpenForeignModal(true);
  };

  const handleForeignModalClose = () => {
    setOpenForeignModal(false);
  };

  // *Const for Modal of ITIN/SSN
  const [openITINModal, setOpenITINModal] = useState(false);

  const handleITINModalOpen = () => {
    setOpenITINModal(true);
  };

  const handleITINModalClose = () => {
    setOpenITINModal(false);
  };

  // Store Data and merge with previous data

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  // Checkbox Name Details Data
  const [checkBox, setCheckBox] = useState(false);

  const handleCheckBox = () => {
    if (!checkBox) {
      setValue("einF_name", companyDetails?.userF_Name);
      setValue("einL_name", companyDetails?.userL_Name);
    } else {
      setValue("einF_name", "");
      setValue("einL_name", "");
    }
    setCheckBox(!checkBox);
  };

  // Checkbox Address Details Data
  const [checkAddressBox, setCheckAddressBox] = useState(false);

  const handleCheckAddressBox = () => {
    if (!checkAddressBox) {
      setValue("einStreetAddress", companyDetails?.streetAddress);
      setValue("einCity", companyDetails?.city);
      setValue("einZipCode", companyDetails?.zipCode);
    } else {
      setValue("einStreetAddress", "");
      setValue("einCity", "");
      setValue("einZipCode", "");
    }
    setCheckAddressBox(!checkAddressBox);
  };

  const [agentData, setAgent] = useState("");

  const einInfo = async (data) => {
    // setLoading(true)
    try {
      setAgent("");
      let obj = {};
      console.log("selectedRadioValue", selectedSecurityValue);
      if (selectedSecurityValue === "no") {
        console.log("no");
        if (selectedEINValue === "ITIN") {
          obj = {
            ...companyDetails,
            einSecurityNumber: false,
            einF_name: data.einF_name,
            einL_name: data.einL_name,
            einITIN: true,
            einSSN: false,
            einNoEINNumber: data.einNoEINNumber,
            einStreetAddress: data.einStreetAddress,
            einCity: data.einCity,
            einState: data.einState,
            einZipCode: data.einZipCode,
          };
        } else {
          obj = {
            ...companyDetails,
            einSecurityNumber: false,
            einF_name: data.einF_name,
            einL_name: data.einL_name,
            einITIN: false,
            einSSN: true,
            einNoEINNumber: data.einNoEINNumber,
            einStreetAddress: data.einStreetAddress,
            einCity: data.einCity,
            einState: data.einState,
            einZipCode: data.einZipCode,
          };
        }
      } else {
        console.log("yes");
        obj = {
          ...companyDetails,
          einSecurityNumber: true,
          einF_name: data.einF_name,
          einL_name: data.einL_name,
          einStreetAddress: data.einStreetAddress,
          einCity: data.einCity,
          einState: data.einState,
          einZipCode: data.einZipCode,
        };
      }

      setAgent(obj);
      console.log(obj);
      dispatch(processContactDetails(obj));
      navigate("/process-businessBanking");
    } catch (error) {
      console.log(
        "🚀 ~ file: TaxIdentificationInfo.js ~ line 177 ~ einInfo ~ error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(einInfo)}>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          py: 3,
          px: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FactCheckTwoTone
            sx={{ color: colors.LavenderMist, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            EIN / Tax Identification Number Information
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary }}>
          An Employer Identification Number (EIN) is a nine-digit number that is
          assigned by the IRS and used to identify taxpayers. We will apply and
          obtain your EIN from the IRS electronically. This option is the
          fastest way to obtain the EIN.
        </Typography>
        <Typography sx={{ color: colors.secondary, my: 2 }}>
          I am a foreign individual and do not have a social security number
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <Radio
              checked={selectedSecurityValue === "yes"}
              //onChange={handleSecurityChange}
              onClick={handleForeignModalOpen}
              value="yes"
              name="radio-buttons"
            />
            <Typography variant="body1" sx={{ color: colors.secondary }}>
              Yes
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              checked={selectedSecurityValue === "no"}
              onChange={handleSecurityChange}
              value="no"
              name="radio-buttons"
            />
            <Typography variant="body1" sx={{ color: colors.secondary }}>
              No
            </Typography>
          </Box>
        </Box>
        {/* Modal for Foreign Individual */}
        <Dialog
          fullScreen={fullScreen}
          open={openForeignModal}
          // onClose={handleForeignModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <AssignmentTurnedInTwoTone
              sx={{ color: colors.GreenyBlue, fontSize: "50px", mr: 2 }}
            />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Confirm
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Please review and agree to the statements listed below in order
                for us prepare the SS4 application for a non-US citizen.
              </Typography>
              <ul
                style={{
                  listStyle: "none",
                  my: 2,
                  fontSize: "18px",
                  marginLeft: "-40px",
                }}
              >
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "6px",
                  }}
                >
                  <Done sx={{ color: colors.GreenyBlue, mr: 2 }} />I am not a
                  citizen of the United States.
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "6px",
                  }}
                >
                  <Done sx={{ color: colors.GreenyBlue, mr: 2 }} />I have never
                  been issued a Social Security Number.
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "6px",
                  }}
                >
                  <Done sx={{ color: colors.GreenyBlue, mr: 2 }} />A prepared
                  SS4 Application will be provided and will require my digital
                  signature prior to being delivered to the IRS.
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "6px",
                  }}
                >
                  <Done sx={{ color: colors.GreenyBlue, mr: 2 }} />
                  Approximate filing time for a non-US citizen SS4 Application
                  is 3 months or more.
                </li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
            <Button
              variant="outlined"
              autoFocus
              onClick={handleForeignModalClose}
              sx={{ px: 4, mx: 3 }}
            >
              Disagree
            </Button>
            <Button
              variant="contained"
              value="yes"
              onClick={(event) => handleSecurityChange(event)}
              autoFocus
              sx={{ px: 4, mx: 3 }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Box>
          <FormGroup sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox onChange={() => handleCheckBox()} />}
              label={
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: colors.secondary,
                  }}
                >
                  <Person sx={{ mr: 1 }} />
                  {companyDetails.userF_Name} {companyDetails.userL_Name}
                </span>
              }
            />
          </FormGroup>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.einF_name?.message && true}
                  {...register("einF_name", {
                    required: "Please enter your first name.",
                  })}
                />
                {errors?.einF_name?.message && (
                  <Error message={errors?.einF_name?.message} />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.einL_name?.message && true}
                  {...register("einL_name", {
                    required: "Please enter your last name.",
                  })}
                />
                {errors?.einL_name?.message && (
                  <Error message={errors?.einL_name?.message} />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {selectedSecurityValue === "no" && (
          <Box sx={{ my: 4 }}>
            <Typography sx={{ color: colors.secondary, my: 2 }}>
              Identification number by which I will obtain the EIN *
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center", mr: 3 }}>
                <Radio
                  checked={selectedEINValue === "ITIN"}
                  //onChange={handleEINChange}
                  onClick={handleITINModalOpen}
                  value="ITIN"
                  name="radio-buttons"
                />
                <Typography variant="body1" sx={{ color: colors.secondary }}>
                  ITIN
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mr: 3 }}>
                <Radio
                  checked={selectedEINValue === "SSN"}
                  onChange={handleEINChange}
                  value="SSN"
                  name="radio-buttons"
                />
                <Typography variant="body1" sx={{ color: colors.secondary }}>
                  SSN
                </Typography>
              </Box>
              <Box>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="EIN Number"
                    variant="outlined"
                    error={errors?.einNoEINNumber?.message && true}
                    {...register("einNoEINNumber", {
                      required: "Please enter EIN Number.",
                    })}
                  />
                  {errors?.einNoEINNumber?.message && (
                    <Error message={errors?.einNoEINNumber?.message} />
                  )}
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
        {/* Modal for ITIN */}
        <Dialog
          fullScreen={fullScreen}
          open={openITINModal}
          // onClose={handleForeignModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <AssignmentTurnedInTwoTone
              sx={{ color: colors.GreenyBlue, fontSize: "50px", mr: 2 }}
            />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Confirm
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                The obtainment for an EIN using an ITIN number cannot be
                procured through the IRS automated system and will take up to 12
                weeks to obtain.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
            <Button
              variant="outlined"
              autoFocus
              onClick={handleITINModalClose}
              sx={{ px: 4, mx: 3 }}
            >
              Disagree
            </Button>
            <Button
              variant="contained"
              value="ITIN"
              onClick={(event) => handleEINChange(event)}
              autoFocus
              sx={{ px: 4, mx: 3 }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            backgroundColor: colors.portlandOrange,
            borderRadius: "8px",
            p: 3,
            display: "flex",
            alignItems: "center",
            my: 4,
          }}
        >
          <Lightbulb sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
          <Box>
            <Typography sx={{ color: colors.textPrimary, fontSize: "16px" }}>
              To ensure that your order is not delayed please ensure that the
              requested information is provided accurately. The name provided
              should be spelled exactly as it is listed on your social security
              card.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          py: 3,
          px: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AddLocationAltTwoTone
            sx={{ color: colors.FrenchRose, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Physical Street Address
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary }}>
          The IRS requires a physical address in order to issue an Employer
          Identification Number (EIN / Tax ID Number) to your company.
          <span style={{ fontWeight: "bold" }}>
            {" "}
            Please note the IRS will not allow for the use of a PO Box; however,
            this address will not be public under any circumstance.
          </span>
        </Typography>
        <FormControlLabel
          sx={{ my: 3 }}
          control={<Checkbox onChange={() => handleCheckAddressBox()} />}
          label={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: colors.secondary,
              }}
            >
              <LocationOn sx={{ mr: 1 }} />
              {companyDetails.streetAddress},{companyDetails.city},{" "}
              {companyDetails.state}
              {companyDetails.zipCode}
            </span>
          }
        />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={12}>
            <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Street Address"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.einStreetAddress?.message && true}
                {...register("einStreetAddress", {
                  required: "Please enter your street address.",
                })}
              />
              {errors?.einStreetAddress?.message && (
                <Error message={errors?.einStreetAddress?.message} />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="City"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.einCity?.message && true}
                {...register("einCity", {
                  required: "Please enter your city.",
                })}
              />
              {errors?.einCity?.message && (
                <Error message={errors?.einCity?.message} />
              )}
            </FormControl>
          </Grid>
          <Grid item container xs={12} md={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={mailStateSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      error={errors?.einState?.message && true}
                      {...register("einState", {
                        required: "Please enter state.",
                      })}
                    />
                  )}
                />
                {errors?.einState?.message && (
                  <Error message={errors?.einState?.message} />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Zip Code"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.einZipCode?.message && true}
                  {...register("einZipCode", {
                    required: "Please enter your zip code.",
                  })}
                />
                {errors?.einZipCode?.message && (
                  <Error message={errors?.einZipCode?.message} />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to="/process-agent"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          // component={Link}
          // to="/process-businessBanking"
          variant="contained"
          //onClick={handleSubmit}
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default TaxIdentificationInfo;
