import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, TextField } from '@mui/material';

//*Import Components
import colors from 'app/style/colors'
import { Loader } from 'assets/Images'
import SideNav from 'app/components/SideNav/SideNav';
import Layout from 'app/layout/Layout';
import useAuth from 'app/hooks/useAuth';
import ProfileDetail from 'app/components/ProfileDetail/ProfileDetail';
import { Service } from 'app/config/service';

function MyAccount() {
  const { user, logout } = useAuth();
  const userId = user.split("_")[0]

  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(true);

  //* Get Order Details API
  const getUserInfo = async () => {
    try {
      const { Data } = await Service.getUserInfo(userId)
      setUserData(Data)
      setLoader(false)

    } catch (error) {
      console.log('file: MyAccount.js => line 27 => getUserInfo => error', error);
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [])


  // *Password Section
  const [values, setValues] = useState({
    password: 'something',
    showPassword: false,
  });

  return (
    <Layout>
      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
        <Grid item xs={12} md={2}
          sx={{
            bgcolor: colors.white, textAlign: "center", boxShadow: 3,
            borderRadius: '12px', mx: 4, height: '180px'
          }}>
          <SideNav indexValue={0} />
        </Grid>
        <Grid item xs={12} md={8}>

          <ProfileDetail id={userId} pageName={"Personal Details"} />
          <Grid sx={{
            borderRadius: '10px', boxShadow: 3, padding: 1,

          }}>
            {loader ? (
              <Box sx={{ textAlign: 'center' }}>
                <Loader />
              </Box>) : (<Box
                sx={{ alignItems: 'center', my: 3 }}>
                <Box component={"form"} >
                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Full Name"
                      defaultValue={userData.FirstName}
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Email"
                      type="email"
                      defaultValue={userData?.Email}
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>

                  <FormControl variant="standard" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Phone"
                      defaultValue={userData?.Phone}
                      sx={{ width: '350px', mx: 2 }}
                    />
                  </FormControl>

                </Box>
              </Box>)}


          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}


export default MyAccount


