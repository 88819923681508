//* Import From MUI
import React from 'react';
import { Box, Typography, Grid, Container, Button, CardContent, Card } from '@mui/material';

//* Import Component
import colors from 'app/style/colors';
import { Link } from 'react-router-dom';

function BusinessGrowth() {
  return (
    <Box sx={{ py: 10, bgcolor: '#F9FAFB' }}>
      <Container>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item md={6}>
            <Typography variant="h3"
              sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow', color: colors.black }} >
              Get Recognized through our Special Business Growth services</Typography>
            <Button
              component={Link} to="/marketing"
              variant="contained"
              sx={{
                py: 1.5,
                px: 2,
                mt: 5,
                borderRadius: 2,
              }}
            >
              Explore More
            </Button>
          </Grid>
          <Grid item md={6} container spacing={2} >
            <Grid item md={6} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 100,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer', textAlign: "center",
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.FrenchRose,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, mt: "21px", fontWeight: 700, fontFamily: 'Barlow' }} >
                    Product Photography
                  </Typography>

                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 100,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer', textAlign: "center",
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.FrenchRose,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, mt: "21px", fontWeight: 700, fontFamily: 'Barlow' }} >
                    Product Branding
                  </Typography>

                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 100,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer', textAlign: "center",
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.FrenchRose,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, mt: "10px", fontWeight: 700, fontFamily: 'Barlow' }} >
                    Website Design & Development
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 100,
                  border: '1px solid rgba(145, 158, 171, 0.24)',
                  bgcolor: 'transparent !important',
                  transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  cursor: 'pointer', textAlign: "center",
                  '&:hover': {
                    bgcolor: colors.white,
                    boxShadow: '-24px 24px 72px -8px rgb(145 158 171 / 24%)',
                    transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    'h6': {
                      color: colors.FrenchRose,
                      transition: 'all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                  }
                }}
              >
                <CardContent>
                  <Typography component="h6" sx={{ fontSize: 18, color: colors.textSecondary, mt: "21px", fontWeight: 700, fontFamily: 'Barlow' }} >
                    Marketing
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}


export default BusinessGrowth