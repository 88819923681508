import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, TextField, Typography, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material'
import { Link, useParams } from 'react-router-dom';
import colors from 'app/style/colors'
import { makeStyles, styled } from '@mui/styles';
import { AddOutlined, ClearOutlined, Download } from '@mui/icons-material';
import Swal from 'sweetalert2';
import FileSaver from 'file-saver';
import { IMaskInput } from 'react-imask'

//* Import Components
import SideNav from 'app/components/SideNav/SideNav';
import Layout from 'app/layout/Layout';
import useAuth from 'app/hooks/useAuth';
import { Service } from 'app/config/service';
import { cnicRegex, getComma } from 'app/utils';
import ProfileDetail from 'app/components/ProfileDetail/ProfileDetail';


const useStyles = makeStyles({
  input: {
    "&:invalid": {
      border: "red solid 2px"
    }

  }
});


const Input = styled('input')({
  display: 'none',
});


function MyRequirements() {

  const classes = useStyles();

  const { id } = useParams()
  const { user, logout } = useAuth();
  const userId = user.split("_")[0]

  const [loader, setLoader] = useState(true);

  // *CNIC Text Feild
  const [cnic, setCnic] = useState("");
  console.log('file: MyRequirements.js => line 47 => MyRequirements => cnic', cnic)

  //* For Downloading
  const [downloadFile, setDownloadFile] = useState([]);

  //* for creating input fields
  const [preRequisiteDetails, setPreRequisiteDetails] = useState([]);
  console.log('file: MyRequirements.js => line 36 => MyRequirements => preRequisiteDetails', preRequisiteDetails)

  //* for all input field
  const [files, setFiles] = useState([]);
  console.log('file: MyRequirements.js => line 39 => MyRequirements => files', files)
  const [preRequisiteSubmitDetails, setPreRequisiteSubmitDetails] = useState([]);

  //* for radio field
  const [preRequisiteRadioDetails, setPreRequisiteRadioDetails] = useState("");

  function selectOption(event, fileId) {


    if (files.length === 0) {
      setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: event.target.value, FilePath: null }]);
      setPreRequisiteRadioDetails(event.target.value)
    }
    else {
      files?.map((item, index) => {
        if (item?.PreRequisitesID === +fileId) {
          const idIndex = files.findIndex(object => object?.PreRequisitesID === +fileId);
          files.splice(idIndex, 1)
          setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: event.target.value, FilePath: null }]);
          setPreRequisiteRadioDetails(event.target.value)
        }
        else {
          setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: event.target.value, FilePath: null }]);
          setPreRequisiteRadioDetails(event.target.value)
        }
      })
    }
  }

  const onFileUpload = async (event) => {
    console.log('file: MyRequirements.js => line 84 => onFileUpload => event', event)
    console.log('file: MyRequirements.js => line asdgasbjd => event.target.value', event.target.value)
    // console.log('file: MyRequirements.js => line 85 => onFileUpload => inputRegex', inputRegex)
    event.preventDefault();
    let fileId = event.target.id;
    if (event.target.type === "text" && event.length === 15) {
      files?.map((item, index) => {
        if (item?.PreRequisitesID === +fileId) {
          const idIndex = files.findIndex(object => object?.PreRequisitesID === +fileId);
          const arr = [...files]
          arr.splice(idIndex, 1)
          setFiles([...arr, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: cnic, FilePath: "" }]);
        }
        else {
          setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: cnic, FilePath: "" }]);
        }
      })
      if (files.length === 0) {
        setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: cnic, FilePath: "" }]);
      }
    }
    else {
      let file_reader = new FileReader();
      let file = event.target.files[0];
      const sortData = [...downloadFile, { file, fileId: Number(fileId) }]
      setDownloadFile(sortData)
      let formData = new FormData();
      formData.append('Url', file);
      const idIndex = preRequisiteSubmitDetails.findIndex(object => object?.PreRequisitesID === +fileId);
      var removeData = [...preRequisiteSubmitDetails]
      if (idIndex > -1) removeData.splice(idIndex, 1)
      const { ResponseCode, Status, Data } = await Service.getPreRequisiteUrl(formData)
      if (ResponseCode === 201 && Status === true) {
        file_reader.onload = () => {
          files?.map((item, index) => {
            if (item?.PreRequisitesID === +fileId) {
              const idIndex = files.findIndex(object => object?.PreRequisitesID === +fileId);
              files.splice(idIndex, 1)
              setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: file.name, FilePath: Data }]);
            }
            else {
              setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: file.name, FilePath: Data }]);
            }
          })
          if (files.length === 0) {

            setFiles([...files, { ServiceOrderID: +id, PreRequisitesID: +fileId, Name: file.name, FilePath: Data }]);
          }

        };
      }
      file_reader.readAsDataURL(file);
    }
  }

  // *Sweeet Alert Try
  const successAlert = (Message) => {

    Swal.fire({
      title: 'Thank You',
      html: `${Message}`,
      icon: 'success',
      confirmButtonColor: colors.primary,
    })
  }
  const errorAlert = (Message) => {

    Swal.fire({
      title: 'oops',
      html: `${Message}`,
      icon: 'error',
      confirmButtonColor: colors.primary,
    })
  }

  // handle submit button for form
  async function handleSubmit(e) {
    e.preventDefault();
    let newArray = [...files]
    preRequisiteDetails.forEach(element => {
      const index = newArray.findIndex(e => e.PreRequisitesID === element.preReqId)
      if (index === -1) {
        let obj = {
          FilePath: null,
          PreReqType: element.type,
          Name: null,
          PreRequisitesID: element.preReqId,
          ServiceOrderID: id,
        }
        console.log('file: MyRequirements.js => line 158 => handleSubmit => obj', obj)
        newArray.push(obj)
      }
    });
    console.log('newArray', newArray);
    // var submitPreReqData = files
    // console.log('file: MyRequirements.js => line 162 => handleSubmit => submitPreReqData', submitPreReqData)
    //console.log(JSON.stringify(files));
    const { ResponseCode, Status, Message } = await Service.getServiceOrderPreRequisite(newArray)
    if (ResponseCode === 201 && Status === true) {
      successAlert(Message)
    }
    else if (Status === true && ResponseCode === 500) {
      errorAlert(Message)
    }
  }

  // handle Update button for form
  async function handleUpdate(e) {
    e.preventDefault();
    // const updatedData = [...files]
    // updatedData.map((item) => {
    //   delete item.PreReqType
    // })
    let newArray = [...files]
    preRequisiteDetails.forEach(element => {
      const index = newArray.findIndex(e => e.PreRequisitesID === element.preReqId)
      console.log('file: MyRequirements.js => line 149 => handleSubmit => index', index)
      if (index === -1) {
        let obj = {
          FilePath: null,
          PreReqType: element.type,
          Name: null,
          PreRequisitesID: element.preReqId,
          ServiceOrderID: id,
        }
        newArray.push(obj)
      }
    });
    const { ResponseCode, Status, Message } = await Service.updatePreRequisitesByOrderID(id, newArray)
    if (ResponseCode === 201 && Status === true) {
      successAlert(Message)
    }
    else if (Status === true && ResponseCode === 500) {
      errorAlert(Message)
    }
  }




  //*On Delete File From Api Data
  function deleteFile(objectId) {
    const updatedData = [...files]
    const idIndex = updatedData.findIndex(object => object?.PreRequisitesID === +objectId);
    var removeData = [...updatedData]
    if (idIndex !== -1) {
      // removeData.splice(idIndex, 1)
      removeData[idIndex].Name = null
    }
    setFiles(removeData)
    const updatedDownload = [...downloadFile]
    const fileIndex = updatedDownload.findIndex(object => object?.fileId === +objectId);
    if (fileIndex !== -1) {
      updatedDownload.splice(fileIndex, 1)
    }
    setDownloadFile(updatedDownload)

  }

  //* On File Download 
  const saveFile = (fileUrl, fileName) => {
    FileSaver.saveAs(fileUrl, fileName)
  }


  //* Get Pre-Requisite Details API
  const getPreRequisite = async () => {
    try {
      const { Data } = await Service.getPreRequisite(id)
      setPreRequisiteDetails(Data)
      setLoader(false)
    } catch (error) {
      console.log('file: MyRequirements.js => line 263 => getPreRequisite => error', error)
    }
  }

  //* Get Pre-Requisite Submit Details API
  const getPreRequisiteDetails = async () => {
    try {
      const { Data } = await Service.getPreRequisiteDetails(id)
      setPreRequisiteSubmitDetails(Data)
      setFiles(Data)
      let responseDataDownload = Data.filter((item) => item.PreReqType !== "Radio" || item.PreReqType !== "Text")
      let arr = []
      responseDataDownload = responseDataDownload.filter((item) => item.Name !== "")
      responseDataDownload.map((files) => {
        const obj = {
          filePath: files.FilePath,
          fileId: files.PreRequisitesID,
          fileName: files.Name
        }
        arr.push(obj)

      })
      const sortData = [...downloadFile, ...arr]
      setDownloadFile(sortData)
      Data.map((item) => {
        if (item.PreReqType === "Radio") {
          return setPreRequisiteRadioDetails(item.Name)
        }
      })

      setLoader(false)
    } catch (error) {
      console.log('file: MyRequirements.js => line 295 => getPreRequisiteDetails => error', error)
    }
  }


  useEffect(() => {
    getPreRequisite()
    getPreRequisiteDetails()

  }, [])


  return (
    <Layout>
      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
        <Grid item xs={12} md={2}
          sx={{
            bgcolor: colors.white, textAlign: "center", boxShadow: 3,
            borderRadius: '12px', mx: 4, height: '180px'
          }}
        >
          <SideNav indexValue={2} />
        </Grid>
        <Grid item xs={12} md={8}>
          <ProfileDetail id={userId} pageName={"Requirement Details"} />
          <Grid sx={{ borderRadius: '12px', boxShadow: 3, padding: 2 }}>
            <Grid container>
              {preRequisiteDetails.map((item, index) => (
                <Grid item xs={12} md={12}
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center', padding: 2 }}
                >
                  <Grid xs={5} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={item.icon} width="50px" alt="Icon" />
                    <Box sx={{ m: 2, width: "250px" }}>
                      <Typography variant='body1'
                        sx={{ fontWeight: 'bold' }}
                      > {item.name}:</Typography>
                      {item.urlType !== null &&
                        <Typography variant='body2'><strong>Type:</strong>{item.urlType}</Typography>
                      }
                    </Box>
                  </Grid>

                  {item.urlType !== null ? (
                    <Grid xs={7} md={7} sx={{ display: "flex", alignItems: 'center' }}>

                      {files.map((fileData, index) => {
                        if (fileData?.PreRequisitesID === +item.preReqId && fileData?.Name !== null) {
                          return (
                            <Fragment>
                              <Typography key={index}
                                sx={{ mx: 2 }}
                              >{fileData.Name}</Typography>
                              <ClearOutlined sx={{ color: 'red', ml: 1, mr: 1, cursor: 'pointer' }}
                                onClick={() => { deleteFile(fileData?.PreRequisitesID) }}
                              />
                              {downloadFile.map((fileItem, index) => {
                                // console.log('file: MyRequirements.js => line 364 => {downloadFile.map => downloadFile', downloadFile)
                                if (fileItem?.fileId === +fileData?.PreRequisitesID) {
                                  return (
                                    <Download key={index} sx={{ color: 'green', ml: 1, mr: 1, cursor: 'pointer' }}
                                      onClick={() => { saveFile(fileItem.file ? fileItem.file : fileItem.filePath, fileItem.file?.name ? fileItem.file?.name : fileItem?.fileName) }}
                                    />
                                  )
                                }

                              })}

                            </Fragment>
                          )
                        }
                        if (fileData?.PreRequisitesID === +item.preReqId && fileData?.Name === null) {
                          return (
                            <label style={{
                              overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`,
                              height: "30px", width: '30px', display: "flex",
                              alignItems: "center", justifyContent: "center",
                            }} htmlFor={item.preReqId}>
                              <Input

                                onChange={onFileUpload}
                                accept={`.${getComma(item.urlType)}`}
                                id={item.preReqId}
                                type="file" />
                              <IconButton color="primary" aria-label="upload picture" component="span"
                                sx={{
                                  '&:hover': {
                                    bgcolor: '#caddfc',
                                  }
                                }}>

                                <AddOutlined
                                  sx={{ color: colors.Rhino, cursor: "pointer", fontSize: 30 }}
                                />
                              </IconButton>
                            </label>
                          )
                        }

                      })}
                      {files.length === 0 &&
                        <label style={{
                          overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`,
                          height: "30px", width: '30px', display: "flex",
                          alignItems: "center", justifyContent: "center",
                        }} htmlFor={item.preReqId}>
                          <Input

                            onChange={onFileUpload}
                            accept={`.${getComma(item.urlType)}`}
                            id={item.preReqId}
                            type="file" />
                          <IconButton color="primary" aria-label="upload picture" component="span"
                            sx={{
                              '&:hover': {
                                bgcolor: '#caddfc',
                              }
                            }}>

                            <AddOutlined
                              sx={{ color: colors.Rhino, cursor: "pointer", fontSize: 30 }}
                            />
                          </IconButton>
                        </label>
                      }

                      {/* <label style={{
                        overflow: "hidden", cursor: "pointer", border: `1px solid #E7E7E7`,
                        height: "30px", width: '30px', display: "flex",
                        alignItems: "center", justifyContent: "center",
                      }} htmlFor={item.preReqId}>
                        <Input

                          onChange={onFileUpload}
                          accept={`.${getComma(item.urlType)}`}
                          id={item.preReqId}
                          type="file" />
                        <IconButton color="primary" aria-label="upload picture" component="span"
                          sx={{
                            '&:hover': {
                              bgcolor: '#caddfc',
                            }
                          }}>

                          <AddOutlined
                            sx={{ color: colors.Rhino, cursor: "pointer", fontSize: 30 }}
                          />
                        </IconButton>
                      </label> */}
                    </Grid>
                  ) :
                    (item.type.toLowerCase() !== "text" ? (
                      <Grid xs={7} md={7} sx={{ display: "flex", alignItems: 'center' }}>
                        <FormControl>
                          <RadioGroup
                            row
                            name="radio-buttons-group"
                            value={preRequisiteRadioDetails || ""}
                            onChange={(e) => selectOption(e, item.preReqId)}
                          >
                            {item.answer?.split(",").map((field, index) => (
                              <FormControlLabel
                                key={field + index}
                                value={field}
                                control={<Radio
                                  sx={{
                                    color: `${colors.Rhino} !important`,
                                    '&:hover': {
                                      bgcolor: '#caddfc',
                                    },
                                  }} />}
                                label={field}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    ) : (
                      files.length > 0 ? (
                        files.map((textItem, i) => (
                          <Fragment>
                            {textItem.PreReqType === 'Text' ? (
                              // <TextField variant="standard"
                              //   key={i}
                              //   onChange={onFileUpload}
                              //   //onKeyDown={(e) => { console.log("ye Cnic Regex Hai", cnicRegex.match(e.target.value)) }}
                              //   inputProps={{ className: classes.input, pattern: cnicRegex, maxLength: 15 }}
                              //   type={item?.type.toLowerCase()}
                              //   size='small'
                              //   id={item.preReqId}
                              //   defaultValue={textItem.PreRequisitesID === item.preReqId ? textItem.Name : ""}
                              //   sx={{
                              //     '.MuiInputBase-colorPrimary:after': {
                              //       borderBottom: `2px solid ${colors.Rhino}`,
                              //     }
                              //   }} />
                              <IMaskInput
                                mask="00000-0000000-0"
                                // onAccept={(value, mask) => onFileUpload(value)}
                                // value=""
                                value={cnic}
                                onChange={e => setCnic(e.target.value)}
                                id={item.preReqId}
                                sx={{
                                  '.MuiInputBase-colorPrimary:after': {
                                    borderBottom: `2px solid ${colors.Rhino}`,
                                  }
                                }}
                              />
                            ) : (
                              <Fragment>
                                {i === 0 &&
                                  // <TextField variant="standard"
                                  //   key={i}
                                  //   onChange={onFileUpload}
                                  //   inputProps={{ className: classes.input, pattern: cnicRegex, maxLength: 15 }}
                                  //   // onKeyDown={(e) => { console.log("ye Cnic Regex Hai", cnicRegex.match(e.target.value)) }}
                                  //   type={item?.type.toLowerCase()}
                                  //   size='small'
                                  //   id={item.preReqId}
                                  //   sx={{
                                  //     '.MuiInputBase-colorPrimary:after': {
                                  //       borderBottom: `2px solid ${colors.Rhino}`,
                                  //     }
                                  //   }} />
                                  <IMaskInput
                                    mask="00000-0000000-0"
                                    //onAccept={(value, mask) => onFileUpload(value)}
                                    // value=""
                                    //onChange={onFileUpload}
                                    value={cnic}
                                    onChange={e => setCnic(e.target.value)}
                                    id={item.preReqId}
                                    sx={{
                                      '.MuiInputBase-colorPrimary:after': {
                                        borderBottom: `2px solid ${colors.Rhino}`,
                                      }
                                    }}
                                  />
                                }
                              </Fragment>
                            )}
                          </Fragment>
                        ))
                      ) : (
                        // <TextField variant="standard"
                        //   inputProps={{ className: classes.input, pattern: cnicRegex, maxLength: 15 }}
                        //   onChange={onFileUpload}
                        //   type={item?.type.toLowerCase()}
                        //   size='small'
                        //   id={item.preReqId}
                        //   sx={{
                        //     '.MuiInputBase-colorPrimary:after': {
                        //       borderBottom: `2px solid ${colors.Rhino}`,
                        //     }
                        //   }} />
                        <IMaskInput
                          mask="00000-0000000-0"
                          // onAccept={(value, mask) => onFileUpload(value)}
                          // onChange={onFileUpload}
                          // value=""
                          value={cnic}
                          onChange={e => setCnic(e.target.value)}
                          id={item.preReqId}
                          sx={{
                            '.MuiInputBase-colorPrimary:after': {
                              borderBottom: `2px solid ${colors.Rhino}`,
                            }
                          }}
                        />

                      )

                    ))

                  }
                </Grid>
              ))}

            </Grid>
            <Box sx={{ textAlign: 'right', mx: 3 }}>
              <Button
                component={Link} to="/myorder"
                variant="outlined"
                sx={{
                  borderColor: colors.Rhino,
                  color: colors.Rhino,
                  mx: 2,
                  '&:hover': {
                    borderColor: colors.RhinoDark,
                    bgcolor: "#c5dafc",
                  },
                }}
              >
                Close
              </Button>
              {preRequisiteSubmitDetails.length > 0 ? (
                <Button variant="contained"
                  onClick={handleUpdate}
                  sx={{
                    bgcolor: colors.Rhino,
                    '&:hover': {
                      bgcolor: colors.RhinoDark,
                    },
                  }}
                >
                  Update
                </Button>) : (
                <Button
                  //disabled={files[0].Name != "" ? false : true}
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    bgcolor: colors.Rhino,
                    '&:hover': {
                      bgcolor: colors.RhinoDark,
                    },
                  }}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}
export default MyRequirements