import moment from "moment";

// *Email Regex
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// * CNIC Regex
export const cnicRegex = /^[1-7+]{1}[0-9+]{4}-[0-9+]{7}-[0-9]{1}$/


// *Current Year
export const CurrentYear = () => {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <span>
      {year}
    </span>
  )
}

// *Set Date Formate
export const getDate = (date) => {
  let result = moment(date).format('LL');
  return result
}

//* Set dot for formats
export const getComma = (file) => {
  if (file == null) {
    return
  }
  else {
    let formatFile = file.replaceAll(",", ",.");
    return formatFile
  }
}

