import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, Typography, TextField, Dialog, Box, FormControl, DialogContent } from '@mui/material/';
import { LoadingButton } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Swal from 'sweetalert2';
//* Import Component
import Images from 'assets/Images';
import { Error } from 'app/components/UI/Error';
import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import { emailRegex } from 'app/utils';


function Register({ openRegister, handleRegisterDialog, courseId }) {


  //*Registration Dialog Form 

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);

  const registration = async (data) => {
    setLoading(true)
    try {
      let obj = {
        TrainingCourseID: courseId,
        FullName: data.name,
        Address: data.add,
        Email: data.email,
        PhoneNumber: data.phone,
        DOB: data.dob,
        Remarks: data.remark,
      }
      const { Status, message } = await Service.trainingRegistration(obj)
      handleRegisterDialog()
      if (Status === true) {
        // SuccessToaster(message)

        Swal.fire({
          title: message,
          html: "<p style='color: red'>" + ' A Verification link has been sent to your email id' + "</p>" + 'Your Course Registration has been submitted, We will get back to you.',
          icon: 'success',
        })
        setDate(null)
        reset()
      }
    } catch (error) {
      console.log('file: Learning.js => line 14 => Learning => error', error)
    } finally {
      setLoading(false)
    }
  }


  const dateHandle = (newDate) => {
    try {
      setDate(newDate)
      setValue('dob', newDate)
    } catch (error) {
      console.log('file: CreateRfq.js => line 217 => dateHandle => error', error)
    }
  }


  return (
    <Grid>
      <Dialog open={openRegister}
        sx={{ height: '780px' }}
      >
        <DialogContent>
          <Grid container justifyContent="space-around"
          >
            <Grid item sx={{ padding: '4px' }}>
              <Box sx={{ textAlign: 'center' }}>
                <img src={Images.registration} width="50%" alt="" />
                <Typography variant="h5"
                  sx={{ color: colors.primary, textAlign: 'center' }}
                >Registration Form</Typography>
              </Box>
            </Grid>
            <Grid
              sx={{
                borderRadius: '14px', my: 4, float: 'right', mx: 3, bgcolor: 'white'
              }}>
              <Box component={"form"} onSubmit={handleSubmit(registration)}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ my: 1, mx: 1 }}>
                      <TextField
                        variant="outlined"
                        label="Full Name"
                        sx={{ width: '240px' }}
                        error={errors?.name?.message && (true)}
                        {...register("name", {
                          required: 'Please enter your first name.',
                        })}
                      />
                      {errors?.name?.message && (
                        <Error message={errors?.name?.message} />
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <FormControl variant="standard" sx={{ my: 1, mx: 1 }}>
                      <TextField
                        variant="outlined"
                        label="Address"
                        sx={{ width: '240px' }}
                        error={errors?.add?.message && (true)}
                        {...register("add", {
                          required: 'Please enter your address.',
                        })}
                      />
                      {errors?.add?.message && (
                        <Error message={errors?.add?.message} />
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <FormControl variant="standard" sx={{ my: 1, mx: 1 }}>
                      <TextField
                        variant="outlined"
                        label="Email"
                        type="email"
                        sx={{ width: '240px' }}
                        error={errors?.email?.message && (true)}
                        {...register("email", {
                          required: 'Email is required',
                          pattern: {
                            value: emailRegex,
                            message: 'Please enter a valid email address',
                          }
                        })}
                      />
                      {errors?.email?.message && (
                        <Error message={errors?.email?.message} />
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <FormControl variant="standard" sx={{ my: 1, mx: 1 }}>
                      <TextField
                        variant="outlined"
                        label="Phone"
                        sx={{ width: '240px' }}
                        error={errors?.phone?.message && (true)}
                        {...register("phone", {
                          required: 'Please enter phone number.',
                        })}
                      />
                      {errors?.phone?.message && (
                        <Error message={errors?.phone?.message} />
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ my: 1, mx: 1 }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                          variant="outlined"
                          label="DOB"
                          value={date}
                          onChange={dateHandle}
                          renderInput={(params) => <TextField {...params}
                            sx={{ width: '240px', color: 'transparent' }}
                            error={errors?.dob?.message && (true)}
                            {...register("dob", {
                              required: 'Please enter Date of Birth.',
                            })}
                          />}
                        />
                        {errors?.dob?.message && (
                          <Error message={errors?.dob?.message} />
                        )}
                      </LocalizationProvider>

                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ my: 1, mx: 1 }}>
                      <TextField
                        variant="outlined"
                        label="remark"
                        sx={{ width: '240px' }}
                        error={errors?.remark?.message && (true)}
                        {...register("remark", {
                          required: 'Please enter your remark.',
                        })}
                      />
                      {errors?.remark?.message && (
                        <Error message={errors?.remark?.message} />
                      )}
                    </FormControl>
                  </Grid>

                </Grid>
                <Box
                  sx={{
                    display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', flexDirection: 'row',
                    width: '250px', margin: 'auto'
                  }}>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={loading}
                    sx={{
                      borderRadius: "10px",
                      p: 1,
                      pl: 2,
                      pr: 2,
                      my: 1,
                      mr: 2,
                    }}
                  >
                    Get Registered
                  </LoadingButton>
                  <Button onClick={handleRegisterDialog}
                    variant="outlined"
                    sx={{
                      borderRadius: "10px",
                      p: 1,
                      my: 1,
                      mr: 2,
                    }}
                  >Cancel</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>
    </Grid >
  );
}



export default Register