import React, { useEffect, useState } from 'react'
import { ArrowForward, PersonOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Typography } from '@mui/material'
// * Import COmponent
import colors from 'app/style/colors'
import useAuth from 'app/hooks/useAuth'
import { Service } from 'app/config/service'
import { makeStyles } from '@mui/styles'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'


const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain"
  },
  labelStyle: {
    width: "100px",
    height: "100px",
    margin: "5px",
    borderRadius: "10px",
    overflow: "hidden",
    cursor: "pointer",
    // border: `1px solid #E7E7E7`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});


function ProfileDetail({ pageName, id }) {

  const { logout } = useAuth()

  const classes = useStyles()

  const { pathname } = useLocation()

  const [selectedProfileImg, setSelectedProfileImg] = useState();

  const [userData, setUserData] = useState("");
  // *For Cover Image
  const imageChange = (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        console.log("swal")
        Swal.fire({
          // title: 'Are you sure you want to add/update profile picture',
          icon: 'warning',
          html: "<p style='color: gray'>" + ' Are you sure you want to add/update profile picture' + "</p>",
          confirmButtonColor: colors.primary,
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append("Upload", e.target.files[0]);
            const { Status, Data, ResponseCode } = await Service.updateUserProfilePic(id, formData)
            if (Status == true && ResponseCode === 201) {
              var imgUrl = "https://masher.mangotech-apps.com" + Data
              setSelectedProfileImg(imgUrl);
            }
          }

        });

      }
    } catch (error) {
      console.log('file: ProfileDetail.js => line 26 => imageChange => error', error);
    }
  };

  //* Get Order Details API
  const getUserInfo = async () => {
    try {
      const { Data } = await Service.getUserInfo(id)
      setUserData(Data)
      // Data?.ProfilePath.split(".com/")

    } catch (error) {
      console.log('file: MyOrders.js => line 71 => getUserInfo => error', error);
    }
  }

  useEffect(() => {
    getUserInfo()

  }, [])


  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '10px', boxShadow: 4, mb: 2 }}>
      <Box sx={{ display: 'flex', my: 'auto' }}>
        {/* <img src={Images.loginHead} width="14%" alt="" style={{ borderRadius: '14px', marginTop: '9px', marginLeft: '10px' }} /> */}

        <Box sx={{ position: "relative", ml: 2 }}>
          <label className={classes.labelStyle} htmlFor='file-input'
          >
            {selectedProfileImg ? (
              <Avatar sx={{ width: 80, height: 80, bgcolor: "transparent" }}>
                <img
                  src={selectedProfileImg}
                  alt=""
                  width="100px"
                  height="100px"
                  className={classes.image}
                  onClick={imageChange}
                />
              </Avatar>
            ) :
              userData?.ProfilePath?.split(".com")[1] !== "" ? (
                <Avatar sx={{ width: 80, height: 80, bgcolor: "transparent" }}>
                  <img
                    src={userData?.ProfilePath}
                    alt=""
                    width="100%"
                    className={classes.image}
                    onClick={imageChange}
                  />
                </Avatar>

              ) :
                (<Avatar onClick={imageChange} sx={{ width: 60, height: 60, bgcolor: colors.primary }}></Avatar>)
            }
          </label>
          <input
            disabled={pathname === "/myprofile" ? false : true}
            style={{ display: "none" }}
            id="file-input"
            type="file"
            onChange={imageChange}
            accept="image/*"
          />
        </Box>
        <Box sx={{ my: 'auto', mx: 3 }}>
          <Typography variant="h5" sx={{ color: colors.Rhino, fontWeight: 'bold' }}>{userData?.FirstName} {userData?.LastName}</Typography>
          <Typography variant="h6" sx={{ color: colors.Rhino }}>{pageName}</Typography>
        </Box>
      </Box>
      <Box sx={{ float: 'Right', my: 'auto', mx: 4 }}>
        <Button

          variant='contained'
          endIcon={<ArrowForward />}
          onClick={() => logout()}
          sx={{
            bgcolor: colors.Rhino,
            '&:hover': {
              bgcolor: colors.RhinoDark,
            },
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  )
}

export default ProfileDetail