import { Navigate } from "react-router-dom";

// *Import Components
import Home from "app/views/Home/Home";
import { PageNotFound } from "app/components/NotFound/NotFound";
import StartBusiness from "app/views/StartBusiness/StartBusiness";
import ServicePayment from "app/components/ServicePayment/ServicePayment";
import Service from "app/views/Service/Service";
import Learning from "app/views/Learning/Learning";
import Training from "app/views/Courses/Training";
import LLCService from "app/views/AllService/LLCService";
import AboutUs from "app/views/AboutUs/AboutUs";
import ContactUs from "app/views/ContactUs/ContactUs";
import USFormation from "app/views/CompanyFormation/USFormation";
import UKFormation from "app/views/CompanyFormation/UKFormation";
import PakistanFormation from "app/views/CompanyFormation/PakistanFormation";
import FillingTaxReturn from "app/views/FillingTaxReturn/FillingTaxReturn";
import TaxIDRegistrationUS from "app/views/TaxIDRegistration/TaxIDRegistrationUS";
import TaxIDRegistrationUK from "app/views/TaxIDRegistration/TaxIDRegistrationUK";
import EcommerceStoreCreation from "app/views/eCommerceStoreCreation/eCommerceStoreCreation";
import Marketing from "app/views/Marketing/Marketing";
import StateFiling from "app/views/StateFiling/StateFiling";
import VirtualBankAccount from "app/views/VirtualBankAccount/VirtualBankAccount";
import Verified from "app/views/Verified/Verified";
import ContactDetails from "app/components/ContactDetails/ContactDetails";
import ProcessContact from "app/views/ProcessContact/ProcessContact";
import StateExpedite from "app/views/StateExpedite/StateExpedite";
import CompanyInformation from "app/views/CompanyInformation/CompanyInformation";
import ProcessPckgUpgrade from "app/views/ProcessPckgUpgrade/ProcessPckgUpgrade";
import ProcessMember from "app/views/ProcessMember/ProcessMember";
import ProcessAgent from "app/views/ProcessAgent/ProcessAgent";
import ProcessEIN from "app/views/ProcessEIN/ProcessEIN";
import ProcessBusinessBanking from "app/views/ProcessBusinessBanking/ProcessBusinessBanking";
import PostageProcess from "app/views/PostageProcess/PostageProcess";
import ProcessTaxInvite from "app/views/ProcessTaxInvite/ProcessTaxInvite";
import LicenseProcess from "app/views/LicenseProcess/LicenseProcess";
import OrderReviewProcess from "app/views/OrderReviewProcess/OrderReviewProcess";
import PaymentProcess from "app/views/PaymentProcess/PaymentProcess";

const AppRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "*",
    component: <Navigate to="/404" />,
  },
  {
    path: "/404",
    component: <PageNotFound />,
  },
  {
    path: "/start-business",
    component: <StartBusiness />,
  },

  {
    path: "/contact-details",
    component: <ContactDetails />,
  },

  {
    path: "/state-expedite",
    component: <StateExpedite />,
  },
  {
    path: "/process-contact",
    component: <ProcessContact />,
  },
  {
    path: "/company-information",
    component: <CompanyInformation />,
  },
  {
    path: "/process-kit-invite/silver",
    component: <ProcessPckgUpgrade />,
  },
  {
    path: "/process-kit-invite/gold",
    component: <ProcessPckgUpgrade />,
  },
  {
    path: "/process-kit-invite/premium",
    component: <ProcessPckgUpgrade />,
  },
  {
    path: "/process-members",
    component: <ProcessMember />,
  },
  {
    path: "/process-agent",
    component: <ProcessAgent />,
  },
  {
    path: "/process-EIN-number",
    component: <ProcessEIN />,
  },
  {
    path: "/process-businessBanking",
    component: <ProcessBusinessBanking />,
  },
  {
    path: "/process-postage",
    component: <PostageProcess />,
  },
  {
    path: "/process-taxInvite",
    component: <ProcessTaxInvite />,
  },
  {
    path: "/process-license",
    component: <LicenseProcess />,
  },
  {
    path: "/process-order-review",
    component: <OrderReviewProcess />,
  },
  {
    path: "/process-payment",
    component: <PaymentProcess />,
  },
  {
    path: "/service-payment",
    component: <ServicePayment />,
  },
  {
    path: "/service",
    component: <Service />,
  },
  {
    path: "/llc-service",
    component: <LLCService />,
  },
  {
    path: "/learning",
    component: <Learning />,
  },
  {
    path: "/training/:id",
    component: <Training />,
  },
  {
    path: "/about-us",
    component: <AboutUs />,
  },
  {
    path: "/us-formation",
    component: <USFormation />,
  },
  {
    path: "/uk-formation",
    component: <UKFormation />,
  },
  // {
  //   path: "/pakistan-formation",
  //   component: <PakistanFormation />,
  // },
  {
    path: "/filling-tax-return",
    component: <FillingTaxReturn />,
  },
  {
    path: "/tax-id-registration-us",
    component: <TaxIDRegistrationUS />,
  },
  {
    path: "/tax-id-registration-uk",
    component: <TaxIDRegistrationUK />,
  },
  {
    path: "/marketing",
    component: <Marketing />,
  },
  {
    path: "/eCommerce-store-creation",
    component: <EcommerceStoreCreation />,
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
  },
  {
    path: "/state-filing",
    component: <StateFiling />,
  },
  {
    path: "/virtual-bank-account-formation",
    component: <VirtualBankAccount />,
  },
  {
    path: "/verified",
    component: <Verified />,
  },
];

export default AppRoutes;
