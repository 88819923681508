import React from "react";
import { Grid } from "@mui/material";
import Layout from "app/layout/Layout";

//*Imported Component
import ContactDetails from "app/components/ContactDetails/ContactDetails";
import OrderSummary from "app/components/OrderSummary/OrderSummary";

function ProcessContact() {
  return (
    <Layout>
      <Grid container spacing={3} sx={{ justifyContent: "space-evenly", p: 3 }}>
        <Grid item xs={12} md={7}>
          <ContactDetails />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <OrderSummary value={25} />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ProcessContact;
