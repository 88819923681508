import React from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material'

//Import Components
import Layout from 'app/layout/Layout'
import colors from 'app/style/colors'
import Images from 'assets/Images'

function TaxIDRegistrationUS() {
  const EIN = [
    'To open a business bank account',
    'To pay federal taxes',
    'Hire and pay employees for your company',
    'If you own a corporation or partnership business',
    'Apply for permits and business licenses',
    'If you file tax returns for employment or consumption of alcohol, tobacco, and firearms',
    'EIN is needed if your business withholds taxes on income excluding wages paid to a non-resident person.',
  ]

  const ITIN = [
    'If you are a foreign national, ineligible to obtain an SSN but need to file tax returns in the US.',
    'If you are a non-US resident who is ineligible to obtain an SSN but needs to file tax returns in the US.',
    'If you are dependent or spouse of a US citizen.',
    'If someone is a dependent of a non-US visa holder who needs to file tax returns.',
    'If you have come to the US to settle and file tax returns.',
    'If you are doing e-commerce business in the US and thinking to open a business PayPal account.',
  ]
  return (

    <Layout>
      <Container>
        {/* ========== Header ========== */}

        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.learningSectionBanner})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }
        }>
          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            Tax ID Registration US
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '170px',
              padding: '1.5px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '120px',
              padding: '1.3px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>


        <Grid sx={{ my: 7 }}>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>Once you have decided on a business structure (C Corp, S Corp, Sole proprietorship, or LLC) for yourself and get it registered, then you’ll have to see what tax structure and day to day operations come under that layout. The business structure will decide how much tax you’ll pay, what paperwork you’ll have to do, and the status of your personal liability. Knowing tax and legal formalities that you must follow onwards will make your business run smoother without liability and risk. As most small and medium-sized businesses go with LLC formation, the tax registration is done according to the state's ruling in which the LLC is registered. The LLC owners are legally responsible for self-employment tax, personal tax, and corporate tax.</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Federal Tax ID Number/ State Tax ID Number</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>As you start a business, it is required that you pay the taxes both to the federal government and state of business registration. Both tax payments require that you register and get a unique tax ID number for both federal and state tax. It requires Employers Identification Number (EIN) to pay taxes to the federal government and sales tax permit or state tax ID to pay taxes on the state level. </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>EIN</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>Employer Identification Number (EIN) is like a Social Security Number (SSN) for the business you own. EIN or Federal Tax Identification Number is your state tax ID and federal tax ID number. This unique nine-digit EIN allotted by the Internal Revenue Service (IRS) helps identify a business for tax reports. In other words, EIN is just a Taxpayer Identification Number (TIN) that distinguishes your LLC from the IRS. Applying for EIN is important once your LLC is registered.</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>What is EIN needed for?</Typography>
          <ul style={{ lineHeight: 2, color: colors.textPrimary }}>
            {EIN.map((item, index) => (
              <li key={index}>{item}</li>))}
          </ul>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Where and how to apply for EIN?</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>You can apply for EIN or federal tax ID by filling SS-4 form on the IRS website through their online portal. In case you need it to be done by a professional, you have got us to take care of the process.</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Individual Taxpayer Identification Number (ITIN)</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>It can only be obtained by specific individuals who are not US citizens. You can apply for ITIN if you meet the below-mentioned requirements.</Typography>
          <ul style={{ lineHeight: 2, color: colors.textPrimary }}>
            {ITIN.map((item, index) => (
              <li key={index}>{item}</li>))}
          </ul>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>ITIN is a must for most of the people who are starting a business in the United States; even if the owner is not planning to live there, however, he’s eligible to file tax returns with IRS. </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>How to apply for ITIN?</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>You’ll have to apply with IRS to get an ITIN by sending your original passport, W-7 form, and document from the consulate to prove you are not a US resident. </Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>You can apply for ITIN in three ways:</Typography>
          <ol style={{ lineHeight: 2, color: colors.textPrimary }}>
            <li>Via post in an envelop containing the required documents on an address provided on the IRS website.</li>
            <li>Hire an IRS authorized intermediary firm to get the ITIN. Faster processing and doesn’t require you to send your original passport to the US.</li>
            <li>Apply on your own through an appointment with the IRS if you plan to visit the USA. It does not require you to provide documents like foreign status documents or a passport.</li>
          </ol>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>State Tax ID Number</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>Whether you need a state tax ID or not depends on the requirement of the state you have registered your business in. If the state needs you to pay taxes or you want to use a state tax ID number for other purposes like to get protected against identity theft in case you are a sole proprietor. Check the state's website to know what tax procedures are followed in that particular state as the obligations may vary at the state level. You should get yourself equipped with knowledge about states policies on employment taxes and income taxes before applying for your business. Else you can leave it to us as we have it all researched and filed it correctly for so many.</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Reseller Permit (Sales Tax ID)</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>Sales tax is a form of tax imposed on the end purchase of an item or service. It is imposed on all retail, rental of goods, leases, and other taxable services. Sales tax does not apply to products that are purchased for resale or further processing. The resellers are exempted from sales tax as far as they do not use the products they were exempted on. The tax ID number or sales tax exemption certificate can come in different forms and can be named differently like:</Typography>
          <ul style={{ lineHeight: 2, color: colors.textPrimary }}>
            <li>Reseller permit</li>
            <li>Sales Tax Exemption Certificate</li>
            <li>Certificate of authority</li>
            <li>Sales tax registration</li>
            <li>Reseller Certificate</li>
            <li>Reseller Tax ID</li>
          </ul>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>Reseller permits allow the wholesalers and retailers to purchase an item and then resale them without paying the sales tax. It also allows the manufacturers to get the raw material to manufacture new items for selling purposes without paying sales tax. These reseller permits vary from one state to the other. </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Requirements to obtain a Reseller Permit (Sale Tax ID)</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}></Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>States with no sales tax</Typography>
          <ul style={{ lineHeight: 2, color: colors.textPrimary }}>
            <li>Business license</li>
            <li>Other required documents as per the state you have your business registered</li>
          </ul>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>States with no sales tax</Typography>
          <ul style={{ lineHeight: 2, color: colors.textPrimary }}>
            <li>Delaware</li>
            <li>Montana</li>
            <li>Alaska</li>
            <li>Oregon</li>
            <li>New Hampshire</li>
          </ul>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>When do you need a Reseller Permit?</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>If you are thinking of registering your business as a sales tax vendor, you need to get a sales tax permit from the state of business registration. Your business may get penalized if you do not get a sales tax exemption certificate before making a taxable sale. We assist our customers in getting a reseller permit as soon as they require. </Typography>

        </Grid >
      </Container >
    </Layout >
  )
}

export default TaxIDRegistrationUS