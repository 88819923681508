import { Lightbulb, LocationOn, Person } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// *Imported Component
import colors from "app/style/colors";
import { useForm } from "react-hook-form";
import { Error } from "app/components/UI/Error";

function MemberInfo() {
  // For Styling Purpose of Member Component
  // const [isActive, setIsActive] = useState([]);

  // const handleClick = (id) => {
  //   // 👇️ toggle
  //   try {
  //     let newIsActive = [...isActive];
  //     const currentIndex = newIsActive.findIndex((e) => e.id === id);
  //     if (currentIndex !== -1) {
  //       newIsActive.splice(currentIndex, 1);
  //     } else {
  //       const obj = {
  //         id: id,
  //         value: true,
  //       };
  //       newIsActive.push(obj);
  //       setIsActive(newIsActive);
  //       console.log(
  //         "🚀 ~ file: MemberInfo.js ~ line 31 ~ MemberInfo ~ isActive",
  //         isActive
  //       );
  //       console.log(
  //         "🚀 ~ file: MemberInfo.js ~ line 31 ~ MemberInfo ~ isActive.id",
  //         isActive[0].id
  //       );
  //     }
  //     console.log("isActive", isActive);
  //     console.log(
  //       "🚀 ~ file: MemberInfo.js ~ line 31 ~ MemberInfo ~ isActive.id",
  //       isActive[0].id
  //     );
  //   } catch (error) {
  //     console.log("member open close", error);
  //   }
  // };

  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: MemberInfo.js ~ line 17 ~ MemberInfo ~ companyData",
    companyDetails
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // *Getting Package Name from Local Storage
  let packageName = localStorage.getItem("package");

  //* Auto Complete State Select For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  //* Auto Complete Owner Percentage Select
  const ownerPercentageSelect = [
    { label: "1%" },
    { label: "2%" },
    { label: "3%" },
    { label: "4%" },
    { label: "5%" },
    { label: "6%" },
    { label: "7%" },
    { label: "8%" },
    { label: "9%" },
    { label: "10%" },
    { label: "11%" },
    { label: "12%" },
    { label: "13%" },
    { label: "14%" },
    { label: "15%" },
    { label: "16%" },
    { label: "17%" },
    { label: "18%" },
    { label: "19%" },
    { label: "20%" },
    { label: "21%" },
    { label: "22%" },
    { label: "23%" },
    { label: "24%" },
    { label: "25%" },
    { label: "26%" },
    { label: "27%" },
    { label: "28%" },
    { label: "29%" },
    { label: "30%" },
    { label: "31%" },
    { label: "32%" },
    { label: "33%" },
    { label: "34%" },
    { label: "35%" },
    { label: "36%" },
    { label: "37%" },
    { label: "38%" },
    { label: "39%" },
    { label: "40%" },
    { label: "41%" },
    { label: "42%" },
    { label: "43%" },
    { label: "44%" },
    { label: "45%" },
    { label: "46%" },
    { label: "47%" },
    { label: "48%" },
    { label: "49%" },
    { label: "50%" },
    { label: "51%" },
    { label: "52%" },
    { label: "53%" },
    { label: "54%" },
    { label: "55%" },
    { label: "56%" },
    { label: "57%" },
    { label: "58%" },
    { label: "59%" },
    { label: "60%" },
    { label: "61%" },
    { label: "62%" },
    { label: "63%" },
    { label: "64%" },
    { label: "65%" },
    { label: "66%" },
    { label: "67%" },
    { label: "68%" },
    { label: "69%" },
    { label: "70%" },
    { label: "71%" },
    { label: "72%" },
    { label: "73%" },
    { label: "74%" },
    { label: "75%" },
    { label: "76%" },
    { label: "77%" },
    { label: "78%" },
    { label: "79%" },
    { label: "80%" },
    { label: "81%" },
    { label: "82%" },
    { label: "83%" },
    { label: "84%" },
    { label: "85%" },
    { label: "86%" },
    { label: "87%" },
    { label: "88%" },
    { label: "89%" },
    { label: "90%" },
    { label: "91%" },
    { label: "92%" },
    { label: "93%" },
    { label: "94%" },
    { label: "95%" },
    { label: "96%" },
    { label: "97%" },
    { label: "98%" },
    { label: "99%" },
    { label: "100%" },
  ];

  const [selectedRadioValue, setSelectedRadioValue] = useState([]);

  const handleChangeBox = (id, value) => {
    try {
      let newSelectedRadioValue = [...selectedRadioValue];
      const currentIndex = selectedRadioValue.findIndex((e) => e.id === id);
      if (currentIndex !== -1) {
        newSelectedRadioValue.splice(currentIndex, 1);
        const obj = {
          id: id,
          member: value,
        };
        newSelectedRadioValue.push(obj);
      }
      setSelectedRadioValue(newSelectedRadioValue);
    } catch (error) {
      console.log(
        "🚀 ~ file: MemberInfo.js ~ line 190 ~ handleChangeBox ~ error",
        error
      );
    }
  };

  // *For Check Member Radio
  const checkMember = (id) => {
    try {
      const currentIndex = selectedRadioValue.findIndex((e) => e.id === id);
      if (currentIndex !== -1) {
        const obj = selectedRadioValue[currentIndex];
        return obj.member;
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: MemberInfo.js ~ line 268 ~ checkMember ~ error",
        error
      );
    }
  };

  const [formData, setFormData] = useState([]);
  // *For Get Form Data
  const getFormData = (id, key, value) => {
    try {
      const newFormData = [...formData];
      const radioValueIndex = selectedRadioValue.findIndex((e) => e.id === id);
      const test = selectedRadioValue[radioValueIndex];
      const index = newFormData.findIndex((e) => e.id === id);
      if (index !== -1) {
        const obj = newFormData[index];
        const updateObj = { ...obj, [key]: value };
        newFormData.splice(index, 1);
        newFormData.push(updateObj);
      } else {
        if (test.member === "individual") {
          const obj = {
            id: id,
            memberType: "individual",
            ind_NameCheckbox: true,
            ind_FirstName: "",
            ind_LastName: "",
            memberStreetAddress: "",
            memberAddress: "",
            memberCity: "",
            memberState: "",
            memberZipCode: "",
            memberOwnership: "",
            [key]: value,
          };
          newFormData.push(obj);
        } else {
          const obj = {
            id: id,
            memberType: "company",
            com_AddressCheckbox: true,
            com_CompanyName: "",
            memberStreetAddress: "",
            memberAddress: "",
            memberCity: "",
            memberState: "",
            memberZipCode: "",
            memberOwnership: "",
            [key]: value,
          };
          newFormData.push(obj);
        }
      }
      setFormData(newFormData);
    } catch (error) {
      console.log(
        "🚀 ~ file: MemberInfo.js ~ line 298 ~ getFormData ~ error",
        error
      );
    }
  };

  useEffect(() => {
    let array = [];
    for (let index = 0; index < Number(companyDetails.no_of_members); index++) {
      const obj = {
        id: index + 1,
        member: "individual",
      };
      array.push(obj);
    }
    setSelectedRadioValue(array);
  }, []);

  //* Extract Data of Clicked Save Button
  const [extractMemberData, setExtractMemberData] = useState([]);
  const handleDataSave = (id) => {
    try {
      const saveData = [...extractMemberData];
      const index = formData.findIndex((e) => e.id === id);
      if (index !== -1) {
        let obj = formData[index];
        const currentIndex = saveData.findIndex((e) => e.id === obj.id);
        if (currentIndex !== -1) {
          saveData.splice(currentIndex, 1);
          saveData.push(obj);
        } else {
          saveData.push(obj);
        }
      }
      console.log(saveData);
      setExtractMemberData(saveData);
      // dispatch(processContactDetails(saveData));
      // navigate("/process-agent");
    } catch (error) {
      console.log(
        "🚀 ~ file: MemberInfo.js ~ line 375 ~ handleDataSave ~ error",
        error
      );
    }
  };

  const handleDataDispatch = () => {
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    let obj = {
      ...companyDetails,
      extractMemberData,
      details: [...duplicate, ["Registered Agent (1st Year)", 0]],
    };
    // const dataMemberDispatch = {...companyDetails, extractMemberData};
    dispatch(processContactDetails(obj));
    navigate("/process-agent");
  };

  // Checkbox Address Data
  const [checkBox, setCheckBox] = useState([]);

  const [checkBoxName, setCheckBoxName] = useState(false);

  const handleCheckBoxValue = (index) => {
    if (!checkBox[index]) {
      setValue(`memberStreetAddress${index}`, companyDetails?.streetAddress);
      setValue(`memberAddress${index}`, companyDetails?.address);
      setValue(`memberCity${index}`, companyDetails?.city);
      setValue(`memberZipCode${index}`, companyDetails?.zipCode);
    } else {
      setValue(`memberStreetAddress${index}`, "");
      setValue(`memberAddress${index}`, "");
      setValue(`memberCity${index}`, "");
      setValue(`memberZipCode${index}`, "");
    }
    const changeValue = !checkBox[index];
    let newChecked = [...checkBox];
    newChecked[index] = changeValue;
    setCheckBox(newChecked);
  };

  const handleCheckBoxNameValue = (index) => {
    if (!checkBoxName) {
      setValue(`memberF_Name${index}`, companyDetails?.userF_Name);
      setValue(`memberL_Name${index}`, companyDetails?.userL_Name);
    } else {
      setValue(`memberF_Name${index}`, "");
      setValue(`memberL_Name${index}`, "");
    }
    setCheckBoxName(!checkBoxName);
  };

  useEffect(() => {
    let newChecked = [...checkBox];
    for (let index = 0; index < Number(companyDetails.no_of_members); index++) {
      newChecked.push(false);
    }
    setCheckBox(newChecked);
  }, []);

  // Number(companyDetails.no_of_members)
  return (
    <Box component={"form"} onSubmit={handleSubmit(handleDataSave)}>
      <Box>
        {[...Array(Number(companyDetails.no_of_members))].map((item, index) => (
          <Fragment key={index}>
            <Box
              key={index}
              component={"form"}
              onSubmit={handleSubmit(handleDataSave)}
              sx={{
                borderRadius: "12px",
                boxShadow: 3,
                py: 4,
                px: 5,
                my: 4,
                // height: `${isActive[index].id === index + 1}` ? "25px" : "auto",
                overflow: "hidden",
              }}
            >
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
                //onClick={() => handleClick(index + 1)}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: colors.black }}
                >
                  Member {index + 1}
                </Typography>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  my: 3,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: colors.lightGray,
                    boxShadow: 3,
                    borderRadius: "8px",
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleChangeBox(index + 1, "individual")}
                >
                  <Radio
                    checked={checkMember(index + 1) === "individual"}
                    value="individual"
                    name="radio-buttons"
                  />
                  <Box sx={{ mx: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ color: colors.black, fontWeight: "bold" }}
                    >
                      Individual
                    </Typography>
                    <Typography
                      sx={{ color: colors.textSecondary, fontSize: "12px" }}
                    >
                      Select if Member is a person.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: colors.lightGray,
                    boxShadow: 3,
                    borderRadius: "8px",
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleChangeBox(index + 1, "company")}
                >
                  <Radio
                    checked={checkMember(index + 1) === "company"}
                    value="company"
                    name="radio-buttons"
                  />
                  <Box sx={{ mx: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ color: colors.black, fontWeight: "bold" }}
                    >
                      Company
                    </Typography>
                    <Typography
                      sx={{ color: colors.textSecondary, fontSize: "12px" }}
                    >
                      Select if Member is a company.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ my: 3, boxShadow: 3, p: 4, boxSizing: "border-box" }}>
                <FormGroup sx={{ my: 3 }}>
                  {checkMember(index + 1) === "individual" ? (
                    <Fragment>
                      {index === 0 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => handleCheckBoxNameValue(index)}
                            />
                          }
                          label={
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: colors.secondary,
                              }}
                            >
                              <Person sx={{ mr: 1 }} />
                              {companyDetails.userF_Name}{" "}
                              {companyDetails.userL_Name}
                            </span>
                          }
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => handleCheckBoxValue(index)}
                          />
                        }
                        label={
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: colors.secondary,
                            }}
                          >
                            <LocationOn sx={{ mr: 1 }} />
                            {companyDetails.streetAddress},{" "}
                            {companyDetails.address},{companyDetails.city},{" "}
                            {companyDetails.state}
                            {companyDetails.zipCode}
                          </span>
                        }
                      />
                    </Fragment>
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox onChange={() => handleCheckBoxValue(index)} />
                      }
                      label={
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: colors.secondary,
                          }}
                        >
                          <LocationOn sx={{ mr: 1 }} />
                          {companyDetails.streetAddress},{" "}
                          {companyDetails.address},{companyDetails.city},{" "}
                          {companyDetails.state}
                          {companyDetails.zipCode}
                        </span>
                      }
                    />
                  )}
                </FormGroup>

                {/* ======= Text Field Section ====== */}

                <Box key={index}>
                  <Grid
                    key={index}
                    container
                    sx={{ justifyContent: "space-between" }}
                    spacing={2}
                  >
                    {checkMember(index + 1) === "individual" ? (
                      <Fragment>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ my: 1 }}
                          >
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="First Name"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              // value={inputFields.firstName}
                              // onChange={(event) =>
                              //   handleChangeInput(index, event)
                              // }
                              onChange={(e) =>
                                getFormData(
                                  index + 1,
                                  "ind_FirstName",
                                  e.target.value
                                )
                              }
                              // error={errors?.memberF_Name?.message && true}
                              {...register(`memberF_Name${index}`)}
                            />
                            {errors?.memberF_Name?.message && (
                              <Error message={errors?.memberF_Name?.message} />
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ my: 1 }}
                          >
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label="Last Name"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={(e) =>
                                getFormData(
                                  index + 1,
                                  "ind_LastName",
                                  e.target.value
                                )
                              }
                              error={errors?.memberL_Name?.message && true}
                              {...register(`memberL_Name${index}`)}
                            />
                            {errors?.memberL_Name?.message && (
                              <Error message={errors?.memberL_Name?.message} />
                            )}
                          </FormControl>
                        </Grid>
                      </Fragment>
                    ) : (
                      <Grid item xs={12} md={6} sx={{ mr: 4 }}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ my: 1 }}
                        >
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Company Name"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) =>
                              getFormData(
                                index + 1,
                                "com_CompanyName",
                                e.target.value
                              )
                            }
                            error={errors?.memberCompanyName?.message && true}
                            {...register("memberCompanyName")}
                          />
                          {errors?.memberCompanyName?.message && (
                            <Error
                              message={errors?.memberCompanyName?.message}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Street Address"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            getFormData(
                              index + 1,
                              "memberStreetAddress",
                              e.target.value
                            )
                          }
                          error={errors?.memberStreetAddress?.message && true}
                          {...register(`memberStreetAddress${index}`)}
                        />
                        {errors?.memberStreetAddress?.message && (
                          <Error
                            message={errors?.memberStreetAddress?.message}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Address (Cont)"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            getFormData(
                              index + 1,
                              "memberAddress",
                              e.target.value
                            )
                          }
                          error={errors?.memberAddress?.message && true}
                          {...register(`memberAddress${index}`)}
                        />
                        {errors?.memberAddress?.message && (
                          <Error message={errors?.memberAddress?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            getFormData(index + 1, "memberCity", e.target.value)
                          }
                          error={errors?.memberCity?.message && true}
                          {...register(`memberCity${index}`)}
                        />
                        {errors?.memberCity?.message && (
                          <Error message={errors?.memberCity?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item container xs={12} md={6} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ my: 1 }}
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={mailStateSelect}
                            onChange={(e, value) =>
                              getFormData(index + 1, "memberState", value.label)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                error={errors?.memberState?.message && true}
                                // {...register(`memberState${index}`)}
                              />
                            )}
                          />
                          {errors?.memberState?.message && (
                            <Error message={errors?.memberState?.message} />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          variant="standard"
                          fullWidth
                          sx={{ my: 1 }}
                        >
                          <TextField
                            fullWidth
                            id="outlined-basic"
                            label="Zip Code"
                            type="number"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) =>
                              getFormData(
                                index + 1,
                                "memberZipCode",
                                e.target.value
                              )
                            }
                            error={errors?.memberZipCode?.message && true}
                            {...register(`memberZipCode${index}`)}
                          />
                          {errors?.memberZipCode?.message && (
                            <Error message={errors?.memberZipCode?.message} />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={ownerPercentageSelect}
                          onChange={(e, value) =>
                            getFormData(
                              index + 1,
                              "memberOwnership",
                              value.label
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="% of Ownership"
                              error={errors?.memberOwnership?.message && true}
                              // {...register(`memberOwnership${index}`)}
                            />
                          )}
                        />
                        {errors?.memberOwnership?.message && (
                          <Error message={errors?.memberOwnership?.message} />
                        )}
                      </FormControl>
                    </Grid>
                    <Box
                      sx={{
                        backgroundColor: colors.portlandOrange,
                        borderRadius: "8px",
                        p: 3,
                        display: "flex",
                        alignItems: "center",
                        my: 4,
                      }}
                    >
                      <Lightbulb
                        sx={{ mr: 1, fontSize: "42px", color: colors.black }}
                      />
                      <Box>
                        <Typography sx={{ color: colors.textPrimary }}>
                          The articles of organization will include the names
                          and or addresses of the initial members of the LLC.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Box>
              <Button
                sx={{ borderRadius: "20px", px: 4, py: 1 }}
                // component={Link}
                // to="/company-information"
                variant="contained"
                onClick={() => handleDataSave(index + 1)}
              >
                Save
              </Button>
            </Box>
          </Fragment>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to={
            packageName === "silver"
              ? "/process-kit-invite/silver"
              : packageName === "gold"
              ? "/process-kit-invite/gold"
              : "/company-information"
          }
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          // component={Link}
          // to="/process-agent"
          variant="contained"
          onClick={handleDataDispatch}
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default MemberInfo;
