import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Typography, Divider, Dialog, DialogTitle, DialogContent } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import Images from 'assets/Images';
import colors from 'app/style/colors';
import { Service } from 'app/config/service';
import { getDate } from 'app/utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function Invoice({ handleClose, open, id }) {

  const [invoiceDetails, setInvoiceData] = useState([]);
  // const [newSplitDate, setNewDate] = useState("");

  //* Get Invoice API
  const getInvoiceDetails = async () => {
    try {
      const { Data } = await Service.getInvoiceDetails(id)
      setInvoiceData(Data)
      // var newDate = Data?.date.split('T')[0]
      // setNewDate(newDate)
    } catch (error) {
      console.log('file: Invoice.js => line 31 => getInvoiceDetails => error', error)
    }
  }


  useEffect(() => {
    getInvoiceDetails()
  }, [id])

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ height: "650px" }}
      >
        <DialogTitle sx={{
          bgcolor: colors.RhinoLight, color: 'white', display: 'flex', justifyContent: 'center'
        }}>
          <img
            src={Images.logo}
            alt="Mashr"
            style={{
              width: '13%',
              mr: 2
            }} />

          <Typography variant='h4' sx={{ my: 'auto', fontWeight: 'bold' }} >
            Invoice Details</Typography>
          {handleClose &&
            <Close
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 12,
                top: 12,
                color: colors.white,
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            />
          }
        </DialogTitle>
        <DialogContent dividers>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} md={3}>
              <Typography sx={{ fontWeight: 'bold' }}>Billed to:</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{`${invoiceDetails.firstName}  ${invoiceDetails.lastName}`}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{invoiceDetails.address}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{invoiceDetails.city}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{invoiceDetails.state}</Typography>
              <Typography sx={{ color: colors.textPrimary }}>{invoiceDetails.country}</Typography>
            </Grid>
            <Grid item xs={12} md={3} >
              <Typography sx={{ fontWeight: 'bold' }}>Invoice Number: </Typography>
              <Typography sx={{ color: colors.textPrimary }}>{invoiceDetails.invoiceId}</Typography>
              <Typography sx={{ mt: 3, fontWeight: 'bold' }}>Date of Issue: </Typography>
              <Typography sx={{ color: colors.textPrimary }}>{getDate(invoiceDetails.date)}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Invoice Total: </Typography>
              <Typography variant='h4' color={colors.RhinoLight}
                sx={{ fontWeight: 'bold' }}
              >${invoiceDetails.serviceCharges}</Typography>
            </Grid>
          </Grid>
          <Divider
            sx={{
              bgcolor: colors.RhinoLight,
              width: '90%',
              padding: '1px',
              margin: 'auto',
              my: 3,
            }}
          />
          <Grid gutterbottom container sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} md={8}>
              <Typography sx={{ fontWeight: 'bold' }}>Service Name:</Typography>
              <Typography sx={{ mx: 2, color: colors.textPrimary }}>{invoiceDetails.serviceName}</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Amount: </Typography>
              <Typography variant='body1' color={colors.RhinoLight}
                sx={{ fontWeight: 'bold' }}
              >${invoiceDetails.serviceCharges}</Typography>
            </Grid>
          </Grid>
          <Grid gutterbottom container sx={{ justifyContent: 'space-between', mt: 8 }}>
            <Grid item xs={12} md={8}>
              <Typography variant="body1"
                sx={{ fontWeight: 'bold' }}
              >Order Status:</Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Typography variant='body1'
                sx={{ fontWeight: 'bold', color: invoiceDetails.status === "Verified" ? "green" : "red" }}
              >{invoiceDetails.status}</Typography>
            </Grid>
          </Grid>
        </DialogContent>

      </BootstrapDialog>
    </Fragment>
  );
}

export default Invoice
