import { Box, Button, Divider, Radio, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// * Imported Component
import colors from "app/style/colors";
import { useForm } from "react-hook-form";

function ProcessPostage() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ProcessPostage.js ~ line 16 ~ ProcessPostage ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedRadioValue, setSelectedRadioValue] =
    useState("electronicDelivery");

  // Getting Package Total Price
  const packagePrice = JSON.parse(localStorage.getItem("packagePriceDetails"));

  // Process Postage Check Box Data For Order Summary Section
  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    const indexExpeditedValue = newDetailsData.findIndex(
      (item) => item[0] === "Express Shipping"
    );
    if (indexExpeditedValue === -1) {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "Electronic Delivery"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["Express Shipping", 25]],
      };
      dispatch(processContactDetails(obj));
      const newValue = packagePrice[0] + 25;
      packagePrice[0] = newValue;
      localStorage.setItem("packagePriceDetails", JSON.stringify(packagePrice));
    } else {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "Express Shipping"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["Electronic Delivery", true]],
      };
      dispatch(processContactDetails(obj));
      const newValue = packagePrice[0] - 25;
      packagePrice[0] = newValue;
      localStorage.setItem("packagePriceDetails", JSON.stringify(packagePrice));
    }
  };

  const handleChangeBox = (value) => {
    if (value != selectedRadioValue) {
      setSelectedRadioValue(value);
      const newDetailsData = companyDetails.details;
      const duplicate = [...newDetailsData];
      const indexExpeditedValue = newDetailsData.findIndex(
        (item) => item[0] === "Express Shipping"
      );
      if (indexExpeditedValue === -1) {
        const indexExpeditedValue = companyDetails.details.findIndex(
          (item) => item[0] === "Electronic Delivery"
        );
        const data = duplicate.splice(indexExpeditedValue, 1);
        let obj = {
          ...companyDetails,
          details: [...duplicate, ["Express Shipping", 25]],
        };
        dispatch(processContactDetails(obj));
        const newValue = packagePrice[0] + 25;
        packagePrice[0] = newValue;
        localStorage.setItem(
          "packagePriceDetails",
          JSON.stringify(packagePrice)
        );
      } else {
        const indexExpeditedValue = companyDetails.details.findIndex(
          (item) => item[0] === "Express Shipping"
        );
        const data = duplicate.splice(indexExpeditedValue, 1);
        let obj = {
          ...companyDetails,
          details: [...duplicate, ["Electronic Delivery", true]],
        };
        dispatch(processContactDetails(obj));
        const newValue = packagePrice[0] - 25;
        packagePrice[0] = newValue;
        localStorage.setItem(
          "packagePriceDetails",
          JSON.stringify(packagePrice)
        );
      }
    }
  };

  // Store Data and merge with previous data

  const { handleSubmit } = useForm();

  const [processPostage, setProcessPostage] = useState("");
  console.log(
    "🚀 ~ file: ProcessPostage.js ~ line 33 ~ ProcessPostage ~ processPostage",
    processPostage
  );

  const processPostageInfo = async (data) => {
    console.log(
      "🚀 ~ file: ProcessPostage.js ~ line 46 ~ processPostageInfo ~ data",
      data
    );

    // setLoading(true)
    try {
      setProcessPostage("");
      let obj = {};
      console.log("selectedRadioValue", selectedRadioValue);
      if (selectedRadioValue === "electronicDelivery") {
        console.log("electronicDelivery");
        obj = {
          ...companyDetails,
          processPostage: selectedRadioValue,
        };
      } else {
        console.log("FedExDelivery");
        obj = {
          ...companyDetails,
          processPostage: selectedRadioValue,
        };
      }
      setProcessPostage(obj);
      console.log(obj);
      dispatch(processContactDetails(obj));
      navigate("/process-taxInvite");
    } catch (error) {
      console.log(
        "🚀 ~ file: AgentRegister.js ~ line 150 ~ agentInfo ~ error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(processPostageInfo)}>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          p: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Select your preferred method of delivery
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Typography sx={{ color: colors.secondary }}>
          By default, your business formation documents will be uploaded to your
          client dashboard for instant access upon successful completion of your
          state filing.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 3,
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "280px",
            }}
            onClick={() => handleChangeBox("electronicDelivery")}
          >
            <Radio
              checked={selectedRadioValue === "electronicDelivery"}
              // onChange={handleChange}
              value="electronicDelivery"
              name="radio-buttons"
            />
            <Box sx={{ mx: 2 }}>
              <Typography
                variant="body1"
                sx={{ color: colors.black, fontWeight: "bold" }}
              >
                Electronic Delivery
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textPrimary }}>
                Instant access once formed
              </Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{ color: colors.primary, mx: 2, fontWeight: "bold" }}
            >
              $0
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "280px",
            }}
            onClick={() => handleChangeBox("FedExDelivery")}
          >
            <Radio
              checked={selectedRadioValue === "FedExDelivery"}
              // onChange={handleChange}
              value="FedExDelivery"
              name="radio-buttons"
            />
            <Box sx={{ mx: 2 }}>
              <Typography
                variant="body1"
                sx={{ color: colors.black, fontWeight: "bold" }}
              >
                FedEx Express
              </Typography>
              <Typography variant="body2" sx={{ color: colors.textPrimary }}>
                Physically mail my documents
              </Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{ color: colors.primary, mx: 2, fontWeight: "bold" }}
            >
              $25
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to="/process-businessBanking"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          // component={Link}
          // to="/process-taxInvite"
          variant="contained"
          //onClick={handleSubmit}
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default ProcessPostage;
