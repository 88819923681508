import Axios from "../../axios";
import { errorHandler } from "./errorHandler";

export const Apis = {
  //* Post API
  login: "API/LoginCustomer",
  getTraining: "API/GetTraining",
  getTrainingDetail: "API/GetTrainingDetailsById",
  trainingRegistration: "API/SaveTrainingRegistration",
  getCategory: "API/GetCourseCategory",
  getTrainingsById: "API/GetTrainingByCourseCatId",
  getPreRequisiteUrl: "API/PreReqOrderImageDocUrl",
  getServiceOrderPreRequisite: "API/SaveOrderPreRequisites",
  postServiceOrderV2: "API/SaveServiceOrderVersion2",
  updatePreRequisitesByOrderID: "API/UpdatePreRequisitesByOrderID",
  //* Get API
  getStates: "API/GetStates",
  getServices: "API/GetServices",
  getUserInfo: "/API/GetUserInfo",
  getServiceDetails: "API/GetServiceDetails",
  getInvoiceDetails: "API/GetinvoiceDetail",
  getOrderDetails: "API/GetOrderList",
  postServiceOrder: "API/SaveServiceOrder",
  getPreRequisite: "API/GetPreRequisiteFormat",
  getPreRequisiteDetails: "API/GetPreRequisitesByOrderID",
  updateUserProfilePic: "API/UserProfilePic",
  checkEmail: "API/CheckEmail",
};

export const headers = {
  "content-type": "application/json",
};

export const get = async (endPoint, token) => {
  try {
    const result = await Axios.get(endPoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result;
  } catch (e) {
    throw errorHandler(e);
  }
};

export const post = async (endPoint, data, token) => {
  try {
    const result = await Axios.post(endPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result;
  } catch (e) {
    throw errorHandler(e);
  }
};

export const put = async (endPoint, data, token) => {
  try {
    const result = await Axios.put(endPoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result;
  } catch (e) {
    throw errorHandler(e);
  }
};
