import React, { Fragment } from 'react'

// *Import Components
import Header from 'app/components/Header/Header'
import Footer from 'app/components/Footer/Footer'
import { Divider } from '@mui/material'

function Layout({ children }) {
  return (
    <Fragment>

      {/* ========== Header ========== */}
      < Header />

      {children}

      {/* ========== Footer ========== */}

      <Divider sx={{ mt: 12 }} />
      < Footer />

    </Fragment>
  )
}

export default Layout