import React from 'react';
import { Box, Container, Divider, Grid, Typography } from '@mui/material'

//Import Components
import Layout from 'app/layout/Layout'
import colors from 'app/style/colors'
import Images from 'assets/Images'

function TaxIDRegistrationUK() {

  return (

    <Layout>
      <Container>
        {/* ========== Header ========== */}

        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.learningSectionBanner})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }
        }>
          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            VAT Registration UK
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '170px',
              padding: '1.5px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '120px',
              padding: '1.3px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>

        <Grid sx={{ my: 7 }}>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>Value Added Tax (VAT) is applied on a purchase price of products, services, or other taxable goods acquired and sold in the UK. The VAT rates applied in the UK can be categorized into three depending on what services or products are involved. </Typography>
          <li style={{ listStyle: 'none', color: colors.textPrimary, margin: 3 }}><span style={{ fontWeight: 'bold', color: colors.black }}>1. Standard: </span>Most goods and services are charged with a 20% standard VAT rate.</li>
          <li style={{ listStyle: 'none', color: colors.textPrimary, margin: 3 }}><span style={{ fontWeight: 'bold', color: colors.black }}>2. Reduced: </span>Certain services and goods such as children's car seats and home energy are charged with 5% VAT rates.</li>
          <li style={{ listStyle: 'none', color: colors.textPrimary, margin: 3 }}><span style={{ fontWeight: 'bold', color: colors.black }}>3. Zero: </span>There is no VAT on most food items, books, newspapers, magazines, and children’s clothing.</li>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black, my: 2 }}>Who should register for VAT?</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>A business with a taxable turnover crossing £85,000 should register for VAT. Companies with a threshold of less than 85K do not require to charge VAT on goods or services they sell and do not need to register for VAT with HM Revenue and Customs (HMRC). One should only register for VAT with HMRC if the turnover exceeds the threshold.</Typography>
          <Typography sx={{ mb: 2, color: colors.textPrimary }}>VAT is applied in the same way to all business structures, including sole proprietors, partnerships, and companies. Once a business gets registered for VAT with HMRC, a registration certificate is issued with a VAT number and a date to file the first VAT return. </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>What is the VAT Number?</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>It is a unique code that HMRC assigns to a company when registered for VAT. When submitting VAT returns to HMRC, do confirm your VAT number to avoid mistakes and delays in the process that can make HMRC reject your claim of input tax.</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Output VAT</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>When a business is VAT registered, it must calculate and collect a tax on the sale of goods and services. A business normally charges VAT from the customers and then reports and pays it to the HMRC. </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Input VAT</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>When a business reclaims VAT paid on business purchases, it is called input tax.</Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Claiming VAT back</Typography>
          <Typography sx={{ my: 2, color: colors.textPrimary }}>The VAT you pay on goods and services like transportation, computers, office furniture that you buy specifically for your business is reclaimable. However, you cannot reclaim VAT on products and services that you get for personal use. It’s a complicated process to reclaim VAT, so it’s better to consult with an expert before taking any step.</Typography>

        </Grid >
      </Container >
    </Layout >
  )
}


export default TaxIDRegistrationUK