import React from 'react'
import { Grid } from '@mui/material'
import Layout from 'app/layout/Layout'

//*Imported Component
import OrderSummary from 'app/components/OrderSummary/OrderSummary'
import CompanyInfo from 'app/components/CompanyInfo/CompanyInfo'


function CompanyInformation() {

  return (
    <Layout>
      <Grid container spacing={3} sx={{ justifyContent: "space-evenly", p: 3 }}>
        <Grid item xs={12} md={7}>
          <CompanyInfo />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <OrderSummary value={45} />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default CompanyInformation