import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import {
  ContactSupport,
  MarkAsUnreadTwoTone,
  PermContactCalendarTwoTone,
} from "@mui/icons-material";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// *Import Component
import colors from "app/style/colors";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Error } from "app/components/UI/Error";

function ContactDetails() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ContactDetails.js ~ line 31 ~ ContactDetails ~ companyData",
    companyDetails
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // *Getting Package name from Local Storage
  let packageName = localStorage.getItem("package");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // *Subsection 1
  // const [customerData, setData] = useState("");
  // console.log(
  //   "file: ContactDetails.js => line 14 => Customer Data => customerData",
  //   customerData
  // );
  const customerInfo = async (data) => {
    // setLoading(true)
    try {
      //setData("");
      let obj = {
        ...companyDetails,
        packageName: packageName,
        userF_Name: data.f_name,
        userL_Name: data.l_name,
        userEmail: data.email,
        phone: data.phone,
        streetAddress: data.streetAddress,
        address: data.address,
        city: data.city,
        state: selectState,
        zipCode: data.zipCode,
      };
      // setData(obj);
      // localStorage.setItem("contactDetail", JSON.stringify(obj));
      dispatch(processContactDetails(obj));
      navigate(
        `${
          packageName === "platinum"
            ? "/company-information"
            : "/state-expedite"
        }`,
        { state: obj }
      );
      // const { Data } = await Service.checkEmail(data.email)
      // if (Data === "Email Already Exist") {
      //   Swal.fire({
      //     icon: 'error',
      //     html: "<p style='color: red'>" + `${Data}` + "</p>",
      //     confirmButtonColor: colors.primary,
      //   })
      // }
      // else {
      //   handleNext()
      //   setData(obj)
      // }
    } catch (error) {
      console.log(
        "file: ContactDetails.js => line 50 => Contact Details => error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };
  // * To handle Email hint
  const [emailHintBox, setEmailHintBox] = useState(false);

  const handleEmailHint = () => {
    setEmailHintBox(true);
  };

  //* Auto Complete State Select For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  //* State for Basic Select
  const [selectState, setSelectState] = useState("");

  const handleBasicSelect = (event) => {
    setSelectState(event.target.value);
  };

  return (
    <Fragment>
      <Box component={"form"} onSubmit={handleSubmit(customerInfo)}>
        {/* Personal Address */}
        <Box sx={{ borderRadius: "12px", boxShadow: 3, py: 4, px: 5, my: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PermContactCalendarTwoTone
              sx={{ color: colors.BrightCerulean, fontSize: "40px", mr: 2 }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: colors.black }}
            >
              Contact Person
            </Typography>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Typography sx={{ color: colors.textSecondary, mb: 2 }}>
            Please provide the name of the person responsible for this order
            whom we may contact if additional information is needed.
          </Typography>
          <Box>
            <Grid
              container
              sx={{ justifyContent: "space-between" }}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    error={errors?.f_name?.message && true}
                    {...register("f_name", {
                      required: "Please enter your first name.",
                    })}
                  />
                  {errors?.f_name?.message && (
                    <Error message={errors?.f_name?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    error={errors?.l_name?.message && true}
                    {...register("l_name", {
                      required: "Please enter your last name.",
                    })}
                  />
                  {errors?.l_name?.message && (
                    <Error message={errors?.l_name?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onChange={handleEmailHint}
                    onBlur={() => setEmailHintBox(false)}
                    error={errors?.email?.message && true}
                    {...register("email", {
                      required: "Please enter your valid email.",
                    })}
                  />
                  {errors?.email?.message && (
                    <Error message={errors?.email?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    error={errors?.phone?.message && true}
                    {...register("phone", {
                      required: "Please enter phone number.",
                    })}
                  />
                  {errors?.phone?.message && (
                    <Error message={errors?.phone?.message} />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          {emailHintBox === true && (
            <Box
              sx={{
                backgroundColor: colors.portlandOrange,
                borderRadius: "12px",
                p: 3,
                display: "flex",
                alignItems: "center",
                my: 4,
              }}
            >
              <ContactSupport sx={{ mr: 1, fontSize: "66px" }} />
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: colors.black,
                    fontSize: "16px",
                  }}
                >
                  What will my email be used for?
                </Typography>
                <Typography
                  sx={{ color: colors.textPrimary, fontSize: "14px" }}
                >
                  Email is the primary method of contact, which will be used to
                  send your order confirmation, provide order status updates,
                  and possibly to request any additional information required to
                  complete your order.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {/* Mailing Address */}
        <Box sx={{ borderRadius: "12px", boxShadow: 3, py: 4, px: 5, my: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MarkAsUnreadTwoTone
              sx={{ color: colors.GreenyBlue, fontSize: "40px", mr: 2 }}
            />
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: colors.black }}
            >
              Mailing Address
            </Typography>
          </Box>
          <Divider sx={{ my: 3 }} />
          {/* <Typography sx={{ color: colors.textSecondary, mb: 2 }}>Please provide the name of the person responsible for this order whom we may contact if additional information is needed.</Typography> */}
          <Box>
            <Grid
              container
              sx={{ justifyContent: "space-between" }}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Street Address"
                    variant="outlined"
                    error={errors?.streetAddress?.message && true}
                    {...register("streetAddress", {
                      required: "Please enter street address.",
                    })}
                  />
                  {errors?.streetAddress?.message && (
                    <Error message={errors?.streetAddress?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Address (Cont)"
                    variant="outlined"
                    error={errors?.address?.message && true}
                    {...register("address", {
                      required: "Please enter your address.",
                    })}
                  />
                  {errors?.address?.message && (
                    <Error message={errors?.address?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    error={errors?.city?.message && true}
                    {...register("city", {
                      required: "Please enter your city.",
                    })}
                  />
                  {errors?.city?.message && (
                    <Error message={errors?.city?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    {/* <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={mailStateSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={errors?.state?.message && true}
                          {...register("state", {
                            required: "Please enter your state.",
                          })}
                        />
                      )}
                    />
                    {errors?.state?.message && (
                      <Error message={errors?.state?.message} />
                    )} */}
                    {/* Select State */}
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectState}
                      label="State"
                      error={errors?.state?.message && true}
                      {...register("state", {
                        required: "Please enter your state.",
                      })}
                      onChange={handleBasicSelect}
                    >
                      {mailStateSelect.map((item, index) => (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    {errors?.state?.message && (
                      <Error message={errors?.state?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Zip Code"
                      type="number"
                      variant="outlined"
                      error={errors?.zipCode?.message && true}
                      {...register("zipCode", {
                        required: "Please enter your zip code.",
                      })}
                    />
                    {errors?.zipCode?.message && (
                      <Error message={errors?.zipCode?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.portlandOrange,
              borderRadius: "12px",
              p: 3,
              display: "flex",
              alignItems: "center",
              my: 4,
            }}
          >
            <ContactSupport sx={{ mr: 1, fontSize: "66px" }} />
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: colors.black,
                  fontSize: "16px",
                }}
              >
                How will this address be used?
              </Typography>
              <Typography sx={{ color: colors.textPrimary, fontSize: "14px" }}>
                The contact address is the primary address used for the delivery
                of any documents or products related to your order. This address
                is used for internal purposes only and will not be shared with
                any third parties or other outside agencies unless provided in
                any subsequent pages of the order process which require the
                intake of an address.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
          }}
        >
          <Button
            component={Link}
            to="/start-business"
            variant="outlined"
            sx={{ px: 4 }}
          >
            Back
          </Button>
          <Button
            type="submit"
            //component={Link} to={packageName === "platinum" ? "/company-information" : "/state-expedite"}
            variant="contained"
            sx={{ px: 4 }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}

export default ContactDetails;
