import {
  AccountBoxTwoTone,
  ApartmentTwoTone,
  BadgeTwoTone,
  Campaign,
  CheckCircle,
  ContactMailTwoTone,
  EmailOutlined,
  FoundationTwoTone,
  LocationOnOutlined,
  PermContactCalendarTwoTone,
  PersonOutline,
  PhoneOutlined,
} from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// *Imported Components
import colors from "app/style/colors";

function ProcessOrderReview() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ProcessOrderReview.js ~ line 27 ~ ProcessOrderReview ~ companyDetails",
    companyDetails
  );

  // package name
  const packName = localStorage.getItem("package");

  return (
    <Box>
      <Box>
        {/* Formation Info */}
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FoundationTwoTone
                sx={{ color: colors.primary, fontSize: "40px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                Formation Info
              </Typography>
            </Box>
            <CheckCircle sx={{ color: colors.GreenyBlue, fontSize: "30px" }} />
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              State of Formation
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              Alaska
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              Entity Type
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              LLC
            </Typography>
          </Box>
        </Box>
        {/* Contact Info */}
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PermContactCalendarTwoTone
                sx={{ color: colors.BrightCerulean, fontSize: "40px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                Contact Info
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                component={Link}
                to="/process-contact"
                sx={{
                  mr: 2,
                  color: colors.primary,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Edit
              </Typography>
              <CheckCircle
                sx={{ color: colors.GreenyBlue, fontSize: "30px" }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <PersonOutline
                sx={{ mr: 1, fontSize: "32px", color: colors.textPrimary }}
              />
              <Typography sx={{ fontWeight: "bold", color: colors.black }}>
                {companyDetails.userF_Name} {companyDetails.userL_Name}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <LocationOnOutlined
                sx={{ mr: 1, fontSize: "32px", color: colors.textPrimary }}
              />
              <Typography sx={{ color: colors.black }}>
                {companyDetails.streetAddress}, {companyDetails.address},
                {companyDetails.city}, {companyDetails.state}
                {companyDetails.zipCode}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <PhoneOutlined
                sx={{ mr: 1, fontSize: "32px", color: colors.textPrimary }}
              />
              <Typography sx={{ color: colors.black }}>
                {companyDetails.phone}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <EmailOutlined
                sx={{ mr: 1, fontSize: "32px", color: colors.textPrimary }}
              />
              <Typography sx={{ color: colors.black }}>
                {companyDetails.userEmail}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Agent Info */}
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BadgeTwoTone
                sx={{ color: colors.WarmPurple, fontSize: "40px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                Agent Info
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                component={Link}
                to="/process-agent"
                sx={{
                  mr: 2,
                  color: colors.primary,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Edit
              </Typography>
              <CheckCircle
                sx={{ color: colors.GreenyBlue, fontSize: "30px" }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          {companyDetails.registeredAgentFree ? (
            <Box
              sx={{
                backgroundColor: colors.JaggedIce,
                borderRadius: "8px",
                p: 3,
                display: "flex",
                alignItems: "center",
                my: 4,
              }}
            >
              <Campaign sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
              <Box>
                <Typography
                  sx={{ color: colors.textPrimary, fontSize: "18px" }}
                >
                  You have selected Incfile to provide you with a Registered
                  Agent
                  <span style={{ fontWeight: "bold" }}>First Year Free</span>
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{ fontWeight: "bold", color: colors.black, my: 2 }}
              >
                Agent Company
              </Typography>
              <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
                TEST
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", color: colors.black, my: 2 }}
              >
                Agent Address
              </Typography>
              <Typography
                sx={{
                  color: colors.textPrimary,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlined sx={{ color: colors.textPrimary, mr: 1 }} />{" "}
                Aslknaksln, Lashlkan, Karacgu, AK 99501
              </Typography>
            </Box>
          )}
        </Box>

        {/* Company Info */}
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ApartmentTwoTone
                sx={{ color: colors.FrenchRose, fontSize: "40px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                Company Info
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                component={Link}
                to="/company-information"
                sx={{
                  mr: 2,
                  color: colors.primary,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Edit
              </Typography>
              <CheckCircle
                sx={{ color: colors.GreenyBlue, fontSize: "30px" }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              Company Name
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              {companyDetails.llc_name} {companyDetails.designator}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              Business Purpose
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              {companyDetails.business_purpose}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              Address
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              {companyDetails.companyStreetAddress},{" "}
              {companyDetails.companyAddress},{companyDetails.companyCity},{" "}
              {companyDetails.companyState}
              {companyDetails.companyZipCode}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              NAICS CODE
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              Agriculture, Forestry, Fishing and Hunting (11)
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              NAICS SUBCODE
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              Other Vegetable (except Potato) and Melon Farming (111219)
            </Typography>
          </Box>
        </Box>
        {/* Member Info */}
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ContactMailTwoTone
                sx={{ color: colors.WaterBlue, fontSize: "40px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                Member Info
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                component={Link}
                to={
                  packName === "silver"
                    ? "/process-kit-invite/silver"
                    : packName === "gold"
                    ? "/process-kit-invite/gold"
                    : "/process-kit-invite/premium"
                }
                sx={{
                  mr: 2,
                  color: colors.primary,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Edit
              </Typography>
              <CheckCircle
                sx={{ color: colors.GreenyBlue, fontSize: "30px" }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            {/* Member Number 1 */}
            {companyDetails.extractMemberData.map((item, index) => {
              if (item.memberType === "individual") {
                return (
                  <Fragment>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          backgroundColor: colors.JaggedIce,
                          px: 1.5,
                          mr: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography>{index + 1}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: colors.black,
                            my: 1,
                          }}
                        >
                          Name
                        </Typography>
                        <Typography sx={{ color: colors.textPrimary, mb: 1 }}>
                          {item.ind_FirstName} {item.ind_LastName}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: colors.black,
                            my: 1,
                          }}
                        >
                          Address
                        </Typography>
                        <Typography sx={{ color: colors.textPrimary, mb: 1 }}>
                          {item.memberStreetAddress}, {item.memberAddress},
                          {item.memberCity}, {item.memberState}
                          {item.memberZipCode}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: colors.black,
                            my: 1,
                          }}
                        >
                          Ownership
                        </Typography>
                        <Typography sx={{ color: colors.textPrimary, mb: 1 }}>
                          {item.memberOwnership}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                  </Fragment>
                );
              } else {
                return (
                  <Fragment>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          backgroundColor: colors.JaggedIce,
                          px: 1.5,
                          mr: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography>{index + 1}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: colors.black,
                            my: 1,
                          }}
                        >
                          Company Name
                        </Typography>
                        <Typography sx={{ color: colors.textPrimary, mb: 1 }}>
                          {item.com_CompanyName}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: colors.black,
                            my: 1,
                          }}
                        >
                          Address
                        </Typography>
                        <Typography sx={{ color: colors.textPrimary, mb: 1 }}>
                          {item.memberStreetAddress}, {item.memberAddress},
                          {item.memberCity}, {item.memberState}
                          {item.memberZipCode}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: colors.black,
                            my: 1,
                          }}
                        >
                          Ownership
                        </Typography>
                        <Typography sx={{ color: colors.textPrimary, mb: 1 }}>
                          {item.memberOwnership}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                  </Fragment>
                );
              }
            })}
          </Box>
        </Box>
        {/* EIN / Tax Info */}
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AccountBoxTwoTone
                sx={{ color: colors.LapisBlue, fontSize: "40px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                EIN / Tax Info
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                component={Link}
                to="/process-EIN-number"
                sx={{
                  mr: 2,
                  color: colors.primary,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Edit
              </Typography>
              <CheckCircle
                sx={{ color: colors.GreenyBlue, fontSize: "30px" }}
              />
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              Name
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              {companyDetails.einF_name} {companyDetails.einL_name}
            </Typography>
            <Typography sx={{ fontWeight: "bold", color: colors.black, my: 2 }}>
              Address
            </Typography>
            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              {companyDetails.einStreetAddress},{companyDetails.einCity},{" "}
              {companyDetails.einState}
              {companyDetails.einZipCode}
            </Typography>

            {companyDetails.einITIN ? (
              <Typography
                sx={{ fontWeight: "bold", color: colors.black, my: 2 }}
              >
                ITIN
              </Typography>
            ) : (
              <Typography
                sx={{ fontWeight: "bold", color: colors.black, my: 2 }}
              >
                SSN
              </Typography>
            )}

            <Typography sx={{ color: colors.textPrimary, mb: 2 }}>
              {companyDetails.einNoEINNumber}
            </Typography>
            <Typography sx={{ color: colors.textPrimary, my: 2 }}>
              This is a multi member LLC (Two or more owners)
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to="/process-license"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          component={Link}
          to="/process-payment"
          variant="contained"
          //onClick={handleSubmit}
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default ProcessOrderReview;
