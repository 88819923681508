import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// * Imported Component
import colors from "app/style/colors";
import { Campaign, Lightbulb, VerifiedUserTwoTone } from "@mui/icons-material";
import Images from "assets/Images";
import { useForm } from "react-hook-form";
import { Error } from "../UI/Error";

function ProcessLicense() {
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ProcessLicense.js ~ line 22 ~ ProcessLicense ~ companyDetails",
    companyDetails
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedRadioValue, setSelectedRadioValue] = useState("NoIdentify");

  // Getting Package Total Price
  const packagePrice = JSON.parse(localStorage.getItem("packagePriceDetails"));

  // Business License Check Box Data For Order Summary Section
  const handleChange = (event) => {
    setSelectedRadioValue(event.target.value);
    const newDetailsData = companyDetails.details;
    const duplicate = [...newDetailsData];
    const indexExpeditedValue = newDetailsData.findIndex(
      (item) => item[0] === "License Package"
    );
    if (indexExpeditedValue === -1) {
      let obj = {
        ...companyDetails,
        details: [...duplicate, ["License Package", 99]],
      };
      dispatch(processContactDetails(obj));
      const newValue = packagePrice[0] + 99;
      packagePrice[0] = newValue;
      localStorage.setItem("packagePriceDetails", JSON.stringify(packagePrice));
    } else {
      const indexExpeditedValue = companyDetails.details.findIndex(
        (item) => item[0] === "License Package"
      );
      const data = duplicate.splice(indexExpeditedValue, 1);
      let obj = {
        ...companyDetails,
        details: duplicate,
      };
      dispatch(processContactDetails(obj));
      const newValue = packagePrice[0] - 99;
      packagePrice[0] = newValue;
      localStorage.setItem("packagePriceDetails", JSON.stringify(packagePrice));
    }
  };

  const handleChangeBox = (value) => {
    if (value != selectedRadioValue) {
      setSelectedRadioValue(value);
      const newDetailsData = companyDetails.details;
      const duplicate = [...newDetailsData];
      const indexExpeditedValue = duplicate.findIndex(
        (item) => item[0] === "License Package"
      );
      if (indexExpeditedValue === -1) {
        let obj = {
          ...companyDetails,
          details: [...duplicate, ["License Package", 99]],
        };
        dispatch(processContactDetails(obj));
        const newValue = packagePrice[0] + 99;
        packagePrice[0] = newValue;
        localStorage.setItem(
          "packagePriceDetails",
          JSON.stringify(packagePrice)
        );
      } else {
        const indexExpeditedValue = companyDetails.details.findIndex(
          (item) => item[0] === "License Package"
        );
        const data = duplicate.splice(indexExpeditedValue, 1);
        let obj = {
          ...companyDetails,
          details: duplicate,
        };
        dispatch(processContactDetails(obj));
        const newValue = packagePrice[0] - 99;
        packagePrice[0] = newValue;
        localStorage.setItem(
          "packagePriceDetails",
          JSON.stringify(packagePrice)
        );
      }
    }
  };

  //* Auto Complete State Select For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  // Store Data and merge with previous data

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [processTax, setProcessTax] = useState("NoIdentify");
  console.log(
    "🚀 ~ file: ProcessLicense.js ~ line 61 ~ ProcessLicense ~ processTax",
    processTax
  );

  const processTaxInfo = async (data) => {
    console.log(
      "🚀 ~ file: ProcessPostage.js ~ line 46 ~ processPostageInfo ~ data",
      data
    );

    // setLoading(true)
    try {
      setProcessTax("");
      let obj = {};
      console.log("selectedRadioValue", selectedRadioValue);
      if (selectedRadioValue === "NoIdentify") {
        console.log("NoIdentify");
        obj = {
          ...companyDetails,
          processLicense: selectedRadioValue,
        };
      } else {
        console.log("YesIdentify");

        obj = {
          ...companyDetails,
          processLicense: selectedRadioValue,
          processLicenseStreetAddress: data.processLicenseStreetAddress,
          processLicenseAddress: data.processLicenseAddress,
          processLicenseCity: data.processLicenseCity,
          processLicenseState: data.processLicenseState,
          processLicenseZipCode: data.processLicenseZipCode,
        };
      }
      setProcessTax(obj);
      console.log(obj);
      dispatch(processContactDetails(obj));
      navigate("/process-order-review");
    } catch (error) {
      console.log(
        "🚀 ~ file: AgentRegister.js ~ line 150 ~ agentInfo ~ error",
        error
      );
    } finally {
      // setLoading(false)
    }
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(processTaxInfo)}>
      <Box
        sx={{
          borderRadius: "12px",
          boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
          my: 4,
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <VerifiedUserTwoTone
            sx={{ color: colors.WarmPurple, fontSize: "40px", mr: 2 }}
          />
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.black }}
          >
            Business Licenses and Permits
          </Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ textAlign: "center" }}>
          <img src={Images.licenseResearch} alt="License Research" />
          <Typography
            variant="h5"
            sx={{ color: colors.black, fontWeight: "bold", my: 2 }}
          >
            We’ve identified
            <span style={{ color: colors.primary }}> 4 licenses </span>for your
            Agriculture business in Alaska...
          </Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>
            As a business owner, you are responsible for making sure your
            business has the proper federal, state and local licenses and
            permits to operate legally.
          </Typography>
          <Typography sx={{ color: colors.textPrimary, my: 2 }}>
            To make this easier, we’ve assembled a Licensing Specialist team to
            conduct the research to determine the licenses, permits and tax
            registration applications required for your business on a federal,
            state, county, and municipal level.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 3,
            mb: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "280px",
              height: "80px",
            }}
            onClick={() => handleChangeBox("NoIdentify")}
          >
            <Radio
              checked={selectedRadioValue === "NoIdentify"}
              // onChange={handleChange}
              value="NoIdentify"
              name="radio-buttons"
            />
            <Typography variant="body1" sx={{ color: colors.black, mx: 2 }}>
              No thanks, I'll do the work myself.
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: colors.lightGray,
              boxShadow: 2,
              borderRadius: "8px",
              p: 2,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "280px",
              height: "80px",
            }}
            onClick={() => handleChangeBox("YesIdentify")}
          >
            <Radio
              checked={selectedRadioValue === "YesIdentify"}
              // onChange={handleChange}
              value="YesIdentify"
              name="radio-buttons"
            />
            <Typography variant="body1" sx={{ color: colors.black, mx: 2 }}>
              <span style={{ fontWeight: "bold" }}>Yes</span>, identify all
              licenses I need and send me the necessary applications.
              <span style={{ fontWeight: "bold" }}> ($99).</span>
            </Typography>
          </Box>
        </Box>
        {selectedRadioValue === "YesIdentify" && selectedRadioValue !== "" ? (
          <Box sx={{ boxShadow: 2, p: 4, borderRadius: "6px", my: 3 }}>
            <Typography sx={{ fontWeight: "bold", color: colors.black }}>
              Please provide the address below where you would like us to
              research required licenses and permits:
            </Typography>
            <Grid
              container
              sx={{ justifyContent: "space-between", my: 3 }}
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Street Address"
                    variant="outlined"
                    error={errors?.processLicenseStreetAddress?.message && true}
                    {...register("processLicenseStreetAddress", {
                      required: "Please enter your street address.",
                    })}
                  />
                  {errors?.processLicenseStreetAddress?.message && (
                    <Error
                      message={errors?.processLicenseStreetAddress?.message}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Address (Cont)"
                    variant="outlined"
                    error={errors?.processLicenseAddress?.message && true}
                    {...register("processLicenseAddress", {
                      required: "Please enter your address.",
                    })}
                  />
                  {errors?.processLicenseAddress?.message && (
                    <Error message={errors?.processLicenseAddress?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    error={errors?.processLicenseCity?.message && true}
                    {...register("processLicenseCity", {
                      required: "Please enter your city.",
                    })}
                  />
                  {errors?.processLicenseCity?.message && (
                    <Error message={errors?.processLicenseCity?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={mailStateSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          error={errors?.processLicenseState?.message && true}
                          {...register("processLicenseState", {
                            required: "Please enter your state.",
                          })}
                        />
                      )}
                    />
                    {errors?.processLicenseState?.message && (
                      <Error message={errors?.processLicenseState?.message} />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="standard" fullWidth sx={{ my: 1 }}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Zip Code"
                      type="number"
                      variant="outlined"
                      error={errors?.processLicenseZipCode?.message && true}
                      {...register("processLicenseZipCode", {
                        required: "Please enter your zip code.",
                      })}
                    />
                    {errors?.processLicenseZipCode?.message && (
                      <Error message={errors?.processLicenseZipCode?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : null}
        <Box
          sx={{
            backgroundColor: colors.JaggedIce,
            borderRadius: "8px",
            p: 3,
            display: "flex",
            alignItems: "center",
            my: 4,
          }}
        >
          <Campaign sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
          <Box>
            <Typography sx={{ color: colors.textPrimary, fontSize: "18px" }}>
              Join the <span style={{ fontWeight: "bold" }}>18,000+</span>{" "}
              business owners who have gained peace of mind with this package.
            </Typography>
          </Box>
        </Box>
        {selectedRadioValue === "NoIdentify" && selectedRadioValue !== "" ? (
          <Box
            sx={{
              backgroundColor: colors.portlandOrange,
              borderRadius: "8px",
              p: 3,
              display: "flex",
              alignItems: "center",
              my: 4,
            }}
          >
            <Lightbulb sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
            <Box>
              <Typography
                sx={{
                  color: colors.black,
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Are Business Licenses and Permits something I should consider?
              </Typography>
              <Typography sx={{ color: colors.textPrimary, fontSize: "14px" }}>
                Remember that operating your business without the required
                licenses can expose you to risks and fines from state and local
                governments. Don't let your new business stumble without the
                proper licenses.
              </Typography>
            </Box>
          </Box>
        ) : null}
        {selectedRadioValue === "YesIdentify" && selectedRadioValue !== "" ? (
          <Box
            sx={{
              backgroundColor: colors.portlandOrange,
              borderRadius: "8px",
              p: 3,
              display: "flex",
              alignItems: "center",
              my: 4,
            }}
          >
            <Lightbulb sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
            <Box>
              <Typography sx={{ color: colors.black, fontWeight: "bold" }}>
                Are Business Licenses and Permits something I should consider?
              </Typography>
              <Typography sx={{ color: colors.textPrimary, fontSize: "16px" }}>
                Remember that operating your business without the required
                licenses can expose you to risks and fines from state and local
                governments. Don't let your new business stumble without the
                proper licenses.
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button
          component={Link}
          to="/process-taxInvite"
          variant="outlined"
          sx={{ px: 4 }}
        >
          Back
        </Button>
        <Button
          type="submit"
          // component={Link}
          // to="/process-order-review"
          variant="contained"
          //onClick={handleSubmit}
          sx={{ px: 4 }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default ProcessLicense;
