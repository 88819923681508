const colors = {
  // primary: 'rgb(250, 84, 28)',
  // lightPrimary: 'rgba(250, 84, 28,0.5)',
  primary: "#10aada",
  lightPrimary: "#7ab3c4",
  secondary: "#707070",
  secondaryLight: "#C2C2C2",
  textPrimary: "#637281",
  textSecondary: "#212836",
  lightWhite: "rgba(255, 255, 255, 0.8)",
  white: "#fff",
  black: "#262626",
  lightGray: "#f5f5f5",
  antiqueWhite: "#FFE8E1",
  portlandOrange: "#ff572217",
  Rhino: "#344768",
  RhinoDark: "#2B374C",
  RhinoLight: "#8194B3",
  TealishGreen: "#97e0c7",
  LavenderMist: "#d497f0",
  JaggedIce: "#bce8f1",
  CandleLight: "#f8d70d",
  GreenyBlue: "#36ba9b",
  BrightCerulean: "#10aada",
  WaterBlue: "#1d84c6",
  PinkishOrange: "#f37854",
  FrenchRose: "#f04989",
  WarmPurple: "#852e91",
  LapisBlue: "#2d3185",
};

export default colors;
