import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
//* Import Component
import { Assets } from 'assets/Images';
import colors from 'app/style/colors';

function WhyChooseUs() {
  return (
    <Box sx={{ py: 5, textAlign: 'center', bgcolor: '#F9FAFB' }}>
      <Container>
        <Typography variant="h3" sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow' }} >Why Choose us?</Typography>
        {/* <Typography variant="body1" sx={{ color: colors.textPrimary }} >Nunc nonummy metus. Donec elit libero, sodales nec, volutpat a, suscipit non, turpis.</Typography> */}
        <Box sx={{ py: 10 }}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item md={3.5}>
              <Grid container spacing={0} justifyContent="space-between" sx={{ mb: 6 }}>
                <Grid item md={10.5} sx={{ textAlign: 'right' }}>
                  <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >Have been dealing for years</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >Years of experience in dealing with clients all around the world.</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >We understand the time restrictions and work accordingly.</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >We have the strategies ready for all your business needs.</Typography>
                </Grid>
                <Grid item md={1.5}>
                  <Box
                    sx={{
                      argin: 8,
                      width: 16,
                      height: 16,
                      mx: 'auto',
                      my: 1,
                      flexShrink: 0,
                      borderRadius: '50%',
                      background: 'linear-gradient(rgb(253, 171, 118), rgb(250, 84, 28))'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item md={10.5} sx={{ textAlign: 'right' }}>
                  <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >Growth with financial Safety</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >We offer you growth with safety so that you are fearless in moving ahead in your business and turning your investments into profits.</Typography>
                </Grid>
                <Grid item md={1.5}>
                  <Box
                    sx={{
                      argin: 8,
                      width: 16,
                      height: 16,
                      mx: 'auto',
                      my: 1,
                      flexShrink: 0,
                      borderRadius: '50%',
                      background: 'linear-gradient(rgb(119, 240, 237), rgb(34, 184, 207))'
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Assets />
            </Grid>
            <Grid item md={3.5}>
              <Grid container spacing={0} justifyContent="space-between" sx={{ mb: 6 }}>
                <Grid item md={1.5}>
                  <Box
                    sx={{
                      argin: 8,
                      width: 16,
                      height: 16,
                      mx: 'auto',
                      my: 1,
                      flexShrink: 0,
                      borderRadius: '50%',
                      background: 'linear-gradient(rgb(119, 240, 237), rgb(34, 184, 207))'
                    }}
                  />
                </Grid>
                <Grid item md={10.5} sx={{ textAlign: 'left' }}>
                  <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >Perfection & Accuracy</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >We know how important it is for your business to have your official work done accurately so that there are no issues later.</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >We take every client.</Typography>

                </Grid>
              </Grid>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item md={1.5}>
                  <Box
                    sx={{
                      argin: 8,
                      width: 16,
                      height: 16,
                      mx: 'auto',
                      my: 1,
                      flexShrink: 0,
                      borderRadius: '50%',
                      background: 'linear-gradient(rgb(253, 171, 118), rgb(250, 84, 28))'
                    }}
                  />
                </Grid>

                <Grid item md={10.5} sx={{ textAlign: 'left' }}>
                  <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 700, fontFamily: 'Barlow' }} >Reasonable Service charges with Day to Day discounts</Typography>
                  <Typography variant="body2" sx={{ color: colors.textPrimary }} >Our service packages are unbeatable in the market with no compromise on the quality and time </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default WhyChooseUs;