import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
//* Import Component
import SideNav from 'app/components/SideNav/SideNav';
import colors from 'app/style/colors'
import { Loader } from 'assets/Images';
import Layout from 'app/layout/Layout';
import Invoice from 'app/components/Dialog/Invoice';
import useAuth from 'app/hooks/useAuth';
import { Service } from 'app/config/service';
import { getDate } from 'app/utils';
import ProfileDetail from 'app/components/ProfileDetail/ProfileDetail';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#344768',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function MyInvoice() {
  const { user, logout } = useAuth();

  const userId = user.split("_")[0]

  const [openInvoice, setOpenInvoice] = useState(false);
  const [userDetails, setuserDetail] = useState("");
  const [loader, setLoader] = useState(true);

  const handleInvoiceDialog = () => {
    setOpenInvoice(!openInvoice);
  };

  const [orderDetails, setorderDetails] = useState([]);


  //* Get Order Details API
  const getUserInfo = async (id) => {
    try {
      const { Data } = await Service.getUserInfo(id)
      return Data

    } catch (error) {
      console.log('file: MyOrders.js => line 71 => getUserInfo => error', error);
    }
  }

  //* Get Order Details API
  const getOrderDetails = async () => {
    try {
      const { Data } = await Service.getOrderDetails()
      const userDetail = await getUserInfo(Data[0]?.UserID)
      setorderDetails(Data)
      setuserDetail(userDetail)
      setLoader(false)
    } catch (error) {
      console.log('file: MyInvoice.js => line 90 => getOrderDetails => error', error);
    }
  }

  useEffect(() => {
    getOrderDetails()
  }, [])



  return (
    <Layout>

      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
        <Grid item xs={12} md={2}
          sx={{
            bgcolor: colors.white, textAlign: "center", boxShadow: 3,
            borderRadius: '12px', mx: 4, height: '180px'
          }}
        >

          <SideNav indexValue={3} />

        </Grid>
        <Grid item xs={12} md={8}>
          <ProfileDetail id={userId} data={userDetails} pageName={"Invoice Details"} />
          {loader ? (
            <Box sx={{ textAlign: 'center' }}>
              <Loader />
            </Box>) : (
            <Grid sx={{ borderRadius: '12px', boxShadow: 3, padding: 2 }}>
              <TableContainer component={Paper} sx={{ cursor: 'pointer' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell><Typography sx={{ fontWeight: 'bold' }}>Invoice Number(#)</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold' }}>Invoice Date&nbsp;</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold' }}>Status&nbsp;(+)</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold' }}>Total&nbsp;($)</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold' }}>Action&nbsp;</Typography></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails.map((item, index) => (
                      <Fragment>
                        {/* Dialog */}
                        <Invoice id={item.Id} open={openInvoice} handleClose={handleInvoiceDialog} />
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            <Typography>{item.Id}</Typography>
                          </StyledTableCell>
                          <StyledTableCell ><Typography>{getDate(item.AddOn)}</Typography></StyledTableCell>
                          <StyledTableCell ><Typography sx={{ color: item.PaymentStatus === "UnPaid" ? "red" : "green" }}>{item.PaymentStatus}</Typography></StyledTableCell>
                          <StyledTableCell ><Typography>${item.ServiceCharge}</Typography></StyledTableCell>
                          <StyledTableCell >
                            <Tooltip title="View Invoice Detail" >
                              <IconButton onClick={() => setOpenInvoice(!openInvoice)}>
                                <RemoveRedEye color="primary" sx={{ fontSize: "22px" }} />
                              </IconButton>
                            </Tooltip>
                            {/* <Button variant='outlined' startIcon={<Receipt />}
                              sx={{ textTransform: 'capitalize' }}
                              onClick={() => {
                                setOpenInvoice(!openInvoice);
                              }}
                            >View Invoice Detail
                            </Button> */}
                          </StyledTableCell>
                        </StyledTableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

        </Grid>
      </Grid>
    </Layout>
  )
}

export default MyInvoice

