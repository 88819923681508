import React, { useState } from 'react';
import { AddToPhotos, ArrowForward, } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Link } from 'react-router-dom';

//*Import Components
import Layout from 'app/layout/Layout';
import colors from 'app/style/colors';
import Images from 'assets/Images';

function UKFormation() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  return (

    <Layout>
      <Container>
        {/* ========== Header ========== */}
        <Grid container sx={{ bgcolor: colors.lightGray, justifyContent: 'space-between' }}>
          <Grid item xs={12} md={6}
          >
            <Typography variant="h4" sx={{
              color: colors.black, textAlign: 'center', mt: 10, mx: 10,
              fontWeight: 'bold',
            }}>
              Company Registration in UK
            </Typography>
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '140px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px',
                mb: 1
              }}
            />

            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '100px',
                padding: '1px',
                margin: 'auto',
                fontSize: '28px'
              }}
            />

            <Typography sx={{ my: 3, ml: 3 }}>The process involves legal registration of a limited liability company (liability is limited by shares or guarantees) with companies house in the UK.Registering for a limited company keeps the business and personal finances of the owner separate once incorporated.</Typography>
            <Typography sx={{ ml: 3 }}>Moreover, limited companies provide financial protection to the owner, allow raising external finance, and are tax-efficient, unlike other business models.</Typography>
            <Button
              component={Link} to='/start-business'
              variant="contained"
              endIcon={<ArrowForward />}
              sx={{ my: 3, borderRadius: 8, mx: 16, py: 2, fontWeight: 'bold', fontSize: '14px' }}
            >
              Form Your UK Company
            </Button>

          </Grid>
          <Grid item xs={12} md={5} sx={{
            borderRadius: '14px',
            background: `url(${Images.companyFormationBanner})`, mr: 3,
            position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
          }
          } />

        </Grid>
        <Grid sx={{ my: 7 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', color: colors.black }}>About Companies House</Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '140px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '100px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
          <Typography sx={{ color: colors.textPrimary, float: 'left', mx: 2, my: 3 }}>Companies House is the company registrar in the UK operating in England and Wales, Scotland, and North Ireland. It is a government-operated agency of the Department for Business, Innovation, and Skills (BIS). All the work related to the incorporation or dissolution of a company is conducted by companies house. It also keeps a record of corporate information and makes it open and transparently available to the public on the official record of the UK companies.</Typography>
        </Grid>
        <Grid sx={{ my: 7 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1, color: colors.black }}>These are the common types of companies one can open in the UK: </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '200px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
          <Box>
            <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
              <Grid item xs={12} md={3.5}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1, height: '185px'
                }}>
                <Box >
                  <Box sx={{ textAlign: 'justify', mt: 3 }}>


                    <List component="nav" >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 0)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Private Company Limited by Shares or Guarantee" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === 1}
                        sx={{
                          '&.Mui-selected': {
                            bgcolor: colors.primary,
                            color: colors.white,
                            '.MuiSvgIcon-root': {
                              color: colors.white,
                            },
                          },

                          '&:hover': {

                            '&.Mui-selected': {
                              color: colors.primary,
                              bgcolor: colors.lightGray,
                            },
                            '.MuiSvgIcon-root': {
                              color: colors.primary,
                            },
                          }
                        }}
                        onClick={(event) => handleListItemClick(event, 1)}
                      >
                        <ListItemIcon>
                          <AddToPhotos sx={{ color: colors.primary }} />
                        </ListItemIcon>
                        <ListItemText primary="Limited Liability Partnership" sx={{ ml: -1.5 }} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}
                sx={{
                  bgcolor: colors.white, textAlign: "center", boxShadow: 2,
                  borderRadius: '12px', mx: 1
                }}
              >
                {
                  selectedIndex === 0 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Private Company Limited by Shares or Guarantee </Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary }}>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>As indicated by name limited liability, the significant benefit of this type of company formation so that your personal assets are kept separate and secure in case your company fails in business.Registering a limited company is a simple process; once you have the right information in hand.You can register it directly at the companies house or let Mashr help you do it quickly on your behalf so that you can prepare for your other business needs in the meantime. </Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>What’s needed for company registration in the UK:</Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Propose a unique company name.</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>Naming your company is the first thing you should work out as it should be unique and valid to be acceptable by the companies house.</Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Get a Registered office address</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>To register a company, you must own a unique office address in the UK jurisdiction of incorporation (England and Wales, Scotland, or North Ireland). This address is used to deliver important documents by companies house and HMRC. We know which address would be best for your business, so don’t get into scams and get your valuable business documents lost in the process.</Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Personal Details of Director, Shareholders, and Person with Significant Control (PSC)</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>This information includes:</Typography>
                      <ul style={{ lineHeight: 2, fontSize: '14px', color: colors.textPrimary }}>
                        <li>Title</li>
                        <li>First name and Surname of the person</li>
                        <li>Country of residence and nationality</li>
                        <li>Date of birth</li>
                        <li>Residential address</li>
                        <li>Occupation</li>
                        <li>Service address</li>
                        <li>Nature of control (Number of shares held by the PSC)</li>
                        <li>Personal information to create a secure online signature (3 details only)</li>
                      </ul>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Information of capital and shareholdings (For companies Limited by shares)</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}>It comprises information on the statement of capital and the type of Shares, their value, rights, and quantity of shares.</Typography>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.black }}>Statement of Guarantee (for Companies Limited by guarantee)</Typography>
                      <Typography sx={{ my: 2, fontSize: "14px" }}> This includes information on the amount of financial guarantee by each guarantor.</Typography>


                    </Box>
                  </Box>
                }
                {
                  selectedIndex === 1 &&
                  <Box>
                    <Typography variant="h5"
                      sx={{ color: colors.black, fontWeight: 'bold', my: 2 }}
                    >Limited Liability Partnership</Typography>
                    <Box sx={{ textAlign: 'justify', mx: 3, color: colors.textPrimary, mt: 4 }}>
                      <Typography
                        sx={{ mt: 2, mb: 4, fontSize: "14px" }}
                      >Limited liability company formation requires having two or more members in this partnership. Registering an LLP also requires personal details, residential and service addresses similar to limited by shares/guarantee company. Furthermore, in LLP, if you intend to make some amendments to the model articles, you will have to download the documents to make the required changes and upload them again for further processing.</Typography>

                    </Box>
                  </Box>
                }
              </Grid>
            </Grid>
          </Box>
        </Grid >
      </Container >
    </Layout >
  )
}


export default UKFormation


