import React from "react";
import { Grid, Typography } from "@mui/material";
import Layout from "app/layout/Layout";

//*Imported Component
import colors from "app/style/colors";
import OrderSummary from "app/components/OrderSummary/OrderSummary";
import BusinessBanking from "app/components/BusinessBanking/BusinessBanking";

function ProcessBusinessBanking() {
  return (
    <Layout>
      <Typography variant="h5" sx={{ color: colors.secondary, mt: 6, mx: 6 }}>
        Complete your order for{" "}
        <span style={{ fontWeight: "bold" }}>
          MANGOTECH LIMITED LIABILITY CO.
        </span>
      </Typography>
      <Grid container spacing={3} sx={{ justifyContent: "space-evenly", p: 3 }}>
        <Grid item xs={12} md={7}>
          <BusinessBanking />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <OrderSummary value={58} />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default ProcessBusinessBanking;
