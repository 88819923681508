import React from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

import { Marketing } from 'assets/Images';
import colors from 'app/style/colors';
import { Link } from 'react-router-dom';

function Growth() {

  return (
    <Box sx={{ py: 5 }}>
      <Container>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item md={5.5}>
            <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 600 }} >Marketing</Typography>
            <Typography variant="h3"
              sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: 'Barlow', color: colors.black }} >
              We Help you set up &#38; grow a business with infinite opportunities in the top E-commerce marketplaces
            </Typography>
            <Typography variant="body1" sx={{ color: colors.textPrimary }} >
              No matter what your business size is when it comes to making it go global, Mashr makes sure to put in all the right ingredients just at the right time so that you thrive in your business ventures to convert your debts into assets.
            </Typography>
            <Typography variant="body1" sx={{ color: colors.textPrimary, my: 1 }} >
              Your time and wealth matter to us equally as it does to you, our team of top-notch professionals is much considerate of these factors while working for you so that we meet your business goals together and build an everlasting bond.
            </Typography>
            <Typography variant="body1" sx={{ color: colors.textPrimary }} >
              We have worked on establishing an E-commerce business for hundreds of clients so far who trusted us, and we made sure to keep that trust last to this day.
            </Typography>
            <Button component={Link} to='/start-business' variant='contained' sx={{ borderRadius: 2, py: 2, px: 2, mt: 2, mr: 1.5 }} >
              Start Business Now
            </Button>
            <Button variant='text'
              sx={{
                fontSize: 16,
                borderRadius: 2,
                py: 2,
                px: 2,
                mt: 2,
                mr: 1.5
              }}
            >
              <PlayArrow sx={{
                width: 28,
                height: 28,
                fontSize: 28,
                mr: 1,
                p: 0.5,
                borderRadius: '50%',
                border: `1px solid ${colors.lightPrimary}`
              }}
              />
              See Our Work
            </Button>
          </Grid>
          <Grid item xs={12} md={6} sx={{ m: 'auto' }}>
            <Marketing />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Growth;