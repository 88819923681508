import { Box, Container, Typography, Divider, Grid } from '@mui/material';
//* Import Component
import colors from 'app/style/colors';
import Images from 'assets/Images';
import React from 'react';
import Layout from 'app/layout/Layout';


function AboutUs() {
  return (
    <Layout>
      <Container>

        {/* ========== About Banner ========== */}
        <Box sx={{
          marginTop: 1.2, borderRadius: '14px',
          background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Images.aboutUsBanner})`,
          height: 300, position: 'sticky', p: 4, opacity: 1, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
        }
        }>


          <Typography variant="h2" sx={{
            color: colors.white, textAlign: 'center', marginTop: '100px', fontWeight: 'bold'
          }}>
            About Us
          </Typography>
          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '140px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px',
              mb: 1
            }}
          />

          <Divider
            sx={{
              bgcolor: colors.primary,
              width: '100px',
              padding: '1px',
              margin: 'auto',
              fontSize: '28px'
            }}
          />
        </Box>
        {/* About Us Paragraphs */}
        <Grid container spacing={2} sx={{ mt: 4, pb: '40px' }}>
          <Grid item xs={12} md={4}>
            <img src={Images.mainAbout} width="95%" alt="About Us " style={{ borderRadius: '14px' }} />
          </Grid>
          <Grid container item xs={12} md={8}>
            <Grid item xs={12} md={12} >
              <Typography variant='h3' sx={{ fontWeight: 700 }}>Know who we are</Typography>
              <Typography sx={{ mt: 2 }}>We are a team of highly professional eCommerce enthusiasts
                assisting people in setting up, running, and growing their eCommerce businesses.
                We offer everything essential for a successful online business, from registering a company,
                tax filing, taking all the hassle of creating online bank accounts to creating an account
                on an eCommerce platform. </Typography>
              <Typography sx={{ my: 2 }}>
                We take your business as seriously as you do, so we do not stop here and take a
                step further to make sure it’s being socially recognized with our proven marketing
                strategies to target the right audience. Besides this, we have a highly professional
                team of software developers, product photography specialists, and web developers
                to meet all your eCommerce business needs so that our customers don’t have to search around when they decide to move further in their business growth.
              </Typography>
            </Grid>
            <Grid container item xs={12} md={12} spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant='h5' sx={{ color: colors.primary, fontWeight: 'bold' }}>There are learning opportunities too</Typography>
                <Typography sx={{ my: 2 }}>We don’t just want to keep it all with us; therefore, we offer courses for people passionate about learning what an eCommerce business is all about. Our highly knowledgeable team with years of practical experience is all set to teach advanced strategies so that you master what’s trending in the e-business market. </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>How this incredible journey started ? </Typography>
            <Typography sx={{ my: 2 }}>We started back in 2019 with just a few people on our team. We were so keenly interested in the eCommerce business that we quit our jobs to explore this area to the roots. The problems we faced to succeed in our eCommerce business made us decide to help others in the community by offering what we had learned the hard way. We have grown to a family of more than a hundred highly skilled employees with only one objective in mind: to facilitate your business horizons and improve every day. </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant='h5' sx={{ color: colors.primary, fontWeight: 'bold' }}>Future Goals </Typography>
            <Typography sx={{ my: 2 }}>Our goal is to make your experience with us even more smooth and more pleasant by working on what we might have been lacking on.Along with getting the best at what we already are offering you, Mashr is now adding more eCommerce marketplaces to its services so that we have it all covered for you. </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Mashr is going Global </Typography>
            <Typography sx={{ mt: 2 }}>The years of success and immense appreciation from our customers have given us the confidence to step forward and offer our services globally to facilitate businesses around the world.</Typography>
            <Typography sx={{ my: 2 }}>So, gear up for an everlasting bond and support from our end to clear all your doubts and ensure that your investment leads to a profitable business because we have all the elements to make it successful.</Typography>
          </Grid>
          <Grid item xs={12} md={7} sx={{ my: 'auto' }}>
            <Typography variant="h4"
              sx={{ textAlign: 'center', pb: 1, fontWeight: '600', }}>
              Achievements
            </Typography>
            <Divider
              sx={{
                bgcolor: colors.primary,
                width: '60px',
                padding: '1px',
                margin: 'auto',
              }}
            />
            <Typography sx={{ my: 2, fontSize: '18px' }}>When it comes to joining an e-Commerce business, timing with the right approach is crucial. So, we have set the stage for our customers by providing the best services with the winning strategies. We have served thousands of happy customers so far who are now successfully doing business on platforms like Amazon, eBay, Etsy, and other online marketplaces. Seeing people you have helped start their businesses flourishing is an outstanding achievement, and we take it as pride.</Typography>
          </Grid>
          <Grid item xs={12} md={5} sx={{ textAlign: "right", my: 2 }}>
            <img src={Images.achievements} width="100%" alt="Achievements" />
          </Grid>
        </Grid>

      </Container>
    </Layout>
  )
}

export default AboutUs