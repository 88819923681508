import { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function useProvideAuth() {

  const [user, setUser] = useState(null);

  const login = (token) => {
    localStorage.setItem('jwt', token)
    setUser(token)
    toast.success('login Successfully!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  const logout = () => {
    toast.success('Logout Successfully!', {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    localStorage.removeItem('jwt')
    setUser(null)
  };

  // *For Verify Token
  const verify = () => {
    const token = localStorage.getItem('jwt')
    setUser(token)
  };

  return {
    user,
    login,
    logout,
    verify,
  };
}

export default useProvideAuth;