import React from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Button,
  CardContent,
  Card,
} from "@mui/material";

import colors from "app/style/colors";
import { Link } from "react-router-dom";

function Ecommerce() {
  return (
    <Box sx={{ py: 10, bgcolor: "#F9FAFB" }}>
      <Container>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item md={4}>
            <Typography
              variant="h3"
              sx={{ mt: 1.5, mb: 3.5, fontWeight: 700, fontFamily: "Barlow" }}
            >
              Take a few simple steps towards your e-Commerce journey.
            </Typography>
            <Button
              component={Link}
              to="/start-business"
              variant="contained"
              sx={{
                py: 1.5,
                px: 2,
                mt: 5,
                borderRadius: 2,
              }}
            >
              Start Business
            </Button>
          </Grid>
          <Grid item md={7} container spacing={2}>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 250,
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                  bgcolor: "transparent !important",
                  transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: colors.white,
                    boxShadow: "-24px 24px 72px -8px rgb(145 158 171 / 24%)",
                    transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    h6: {
                      color: colors.primary,
                      transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                  },
                }}
              >
                <CardContent>
                  <Typography
                    component="h6"
                    sx={{
                      fontSize: 18,
                      color: colors.textSecondary,
                      my: 1.5,
                      fontWeight: 700,
                      fontFamily: "Barlow",
                    }}
                  >
                    Step 1
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 0.6,
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    Decide a Country (US, UK) and State (US) to get your company
                    registered
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 250,
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                  bgcolor: "transparent !important",
                  transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: colors.white,
                    boxShadow: "-24px 24px 72px -8px rgb(145 158 171 / 24%)",
                    transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    h6: {
                      color: colors.primary,
                      transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                  },
                }}
              >
                <CardContent>
                  <Typography
                    component="h6"
                    sx={{
                      fontSize: 18,
                      color: colors.textSecondary,
                      my: 1.5,
                      fontWeight: 700,
                      fontFamily: "Barlow",
                    }}
                  >
                    Step 2
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 0.6,
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    Provide us a little more details for your company
                    registration following a few simple steps so that you are
                    guided towards the best of services to register and run your
                    business
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 250,
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                  bgcolor: "transparent !important",
                  transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: colors.white,
                    boxShadow: "-24px 24px 72px -8px rgb(145 158 171 / 24%)",
                    transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    h6: {
                      color: colors.primary,
                      transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                  },
                }}
              >
                <CardContent>
                  <Typography
                    component="h6"
                    sx={{
                      fontSize: 18,
                      color: colors.textSecondary,
                      my: 1.5,
                      fontWeight: 700,
                      fontFamily: "Barlow",
                    }}
                  >
                    Step 3
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 0.6,
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    Select a suitable Package for yourself (Don’t forget to
                    check our limited time discount deals)
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 250,
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                  bgcolor: "transparent !important",
                  transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: colors.white,
                    boxShadow: "-24px 24px 72px -8px rgb(145 158 171 / 24%)",
                    transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    h6: {
                      color: colors.primary,
                      transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                  },
                }}
              >
                <CardContent>
                  <Typography
                    component="h6"
                    sx={{
                      fontSize: 18,
                      color: colors.textSecondary,
                      my: 1.5,
                      fontWeight: 700,
                      fontFamily: "Barlow",
                    }}
                  >
                    Step 4
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 0.6,
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    Choose an e-Commerce marketplace and let us know to create
                    an account correctly
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: 3,
                  height: 250,
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                  bgcolor: "transparent !important",
                  transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: colors.white,
                    boxShadow: "-24px 24px 72px -8px rgb(145 158 171 / 24%)",
                    transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    h6: {
                      color: colors.primary,
                      transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    },
                  },
                }}
              >
                <CardContent>
                  <Typography
                    component="h6"
                    sx={{
                      fontSize: 18,
                      color: colors.textSecondary,
                      my: 1.5,
                      fontWeight: 700,
                      fontFamily: "Barlow",
                    }}
                  >
                    Step 5
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 0.6,
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    We have many business growth ideas to help you grow your
                    business at a pace.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      my: 0.6,
                      color: colors.textPrimary,
                      fontWeight: "bold",
                    }}
                  >
                    Consult with us{" "}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Ecommerce;
