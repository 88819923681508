import { Link, useNavigate } from "react-router-dom";
import { CreditCardTwoTone, Lightbulb } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React from "react";

// *Redux Components
import { useSelector, useDispatch } from "react-redux";
import { processContactDetails } from "app/redux/features/stateReducer/stateReducer";

// *Imported Components
import colors from "app/style/colors";
import Images, { Quote } from "assets/Images";
import { Service } from "app/config/service";
import Swal from "sweetalert2";

const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

function ProcessPayment() {
  //* Auto Complete State Select For Mailing Address
  const mailStateSelect = [
    { label: "Alabama" },
    { label: "Alaska" },
    { label: "Arizona" },
    { label: "Arkansas" },
    { label: "California" },
    { label: "Colorando" },
    { label: "Connecticut" },
    { label: "Florida" },
    { label: "Georgia" },
  ];

  //* Auto Complete State Select For Mailing Address
  const monthSelect = [
    { label: "January" },
    { label: "Febuary" },
    { label: "March" },
    { label: "April" },
    { label: "May" },
    { label: "June" },
    { label: "July" },
    { label: "August" },
    { label: "September" },
    { label: "October" },
    { label: "November" },
    { label: "December" },
  ];
  //* Auto Complete State Select For Mailing Address
  const yearSelect = [
    { label: "2022" },
    { label: "2023" },
    { label: "2024" },
    { label: "2025" },
    { label: "2026" },
    { label: "2027" },
    { label: "2028" },
    { label: "2029" },
    { label: "2030" },
    { label: "2031" },
    { label: "2032" },
    { label: "2033" },
    { label: "2034" },
    { label: "2035" },
    { label: "2036" },
  ];

  // *Sweeet Alert Try
  const successAlert = (Message) => {
    Swal.fire({
      title: "Thank You",
      html:
        "<p style='color: red'>" +
        " A Verification link has been sent to your email id" +
        "</p>" +
        `${Message}`,
      icon: "success",
      confirmButtonColor: colors.primary,
    }).then(() => {
      // setOpenInvoice(!openInvoice);
      navigate("/process-order-review");
    });
  };
  const errorAlert = (Message) => {
    Swal.fire({
      title: "oops",
      html: `${Message}`,
      icon: "error",
      confirmButtonColor: colors.primary,
    });
  };

  //* Consuming API
  const navigate = useNavigate();

  //* Get all saved data from all pages
  const companyDetails = useSelector((state) => state.stateData.companyData);
  console.log(
    "🚀 ~ file: ProcessPayment.js ~ line 503 ~ ProcessPayment ~ companyDetails",
    companyDetails
  );

  //Service Order API Obj
  const serviceOrder = async () => {
    try {
      //Creating All Required Objects For API
      let order = {
        PackageName: companyDetails.packageName,
        cp_FirstName: companyDetails.userF_Name,
        cp_LastName: companyDetails.userL_Name,
        cp_Email: companyDetails.userEmail,
        cp_Phone: companyDetails.phone,
        ma_StreetAddress: companyDetails.streetAddress,
        ma_Address: companyDetails.address,
        ma_City: companyDetails.city,
        ma_State: companyDetails.state,
        ma_ZipCode: companyDetails.zipCode,
        sft_3weeks: false,
        sft_1day: companyDetails.stateFillingTime,
        ci_LLCName: companyDetails.llc_name,
        ci_Designator: companyDetails.designator,
        ci_NoOfMembers: companyDetails.no_of_members,
        ci_BusinessPurpose: companyDetails.business_purpose,
        ci_IndustryKeyword: companyDetails.industry_keyword,
        cai_AddressCheckbox: true,
        cai_StreetAddress: companyDetails.companyStreetAddress,
        cai_Address: companyDetails.companyAddress,
        cai_City: companyDetails.companyCity,
        cai_State: companyDetails.companyState,
        cai_ZipCode: companyDetails.companyZipCode,
      };

      let registeragent = {};
      if (companyDetails.registeredAgentFree === true) {
        registeragent = {
          RegisterAgent: companyDetails.registeredAgentFree,
        };
      } else {
        registeragent = {
          RegisterAgent: companyDetails.registeredAgentFree,
          ind_Checkbox: companyDetails.registeredAgentFree,
          ind_FirstName: companyDetails.registeredAgentFree,
          ind_LastName: companyDetails.registeredAgentFree,
          ind_StreetAddress: companyDetails.registeredAgentFree,
          ind_Address: companyDetails.registeredAgentFree,
          ind_City: companyDetails.registeredAgentFree,
          ind_State: companyDetails.registeredAgentFree,
          ind_ZipCode: companyDetails.registeredAgentFree,
          com_CompanyName: companyDetails.registeredAgentFree,
          com_StreetAddress: companyDetails.registeredAgentFree,
          com_Address: companyDetails.registeredAgentFree,
          com_City: companyDetails.registeredAgentFree,
          com_State: companyDetails.registeredAgentFree,
          com_ZipCode: companyDetails.registeredAgentFree,
        };
      }

      let ein = {};

      if (companyDetails.einSecurityNumber === false) {
        ein = {
          EIN_SecurityNumber: companyDetails.einSecurityNumber,
          No_Checkbox: true,
          No_FirstName: companyDetails.einF_name,
          No_LastName: companyDetails.einL_name,
          No_EIN_ITIN: companyDetails.einITIN,
          No_EIN_SSN: companyDetails.einSSN,
          No_EIN_Number: companyDetails.einNoEINNumber,
          psa_Checkbox: true,
          psa_StreetAddress: companyDetails.einStreetAddress,
          psa_City: companyDetails.einCity,
          psa_State: companyDetails.einState,
          psa_ZipCode: companyDetails.einZipCode,
        };
      } else {
        ein = {
          EIN_SecurityNumber: companyDetails.einSecurityNumber,
          No_Checkbox: true,
          No_FirstName: companyDetails.einF_name,
          No_LastName: companyDetails.einL_name,
          psa_Checkbox: true,
          psa_StreetAddress: companyDetails.einStreetAddress,
          psa_City: companyDetails.einCity,
          psa_State: companyDetails.einState,
          psa_ZipCode: companyDetails.einZipCode,
        };
      }

      let settingbusiness = {};
      if (companyDetails.processLicense === "NoIdentify") {
        settingbusiness = {
          BusinessBanking: companyDetails.businessBanking,
          MethodOfDelivery: companyDetails.processPostage,
          TaxStrategy: companyDetails.processTaxInvite,
          BusinessLicense: companyDetails.processLicense,
        };
      } else {
        settingbusiness = {
          BusinessBanking: companyDetails.businessBanking,
          MethodOfDelivery: companyDetails.processPostage,
          TaxStrategy: companyDetails.processTaxInvite,
          BusinessLicense: companyDetails.processLicense,
          yes_StreetAddress: companyDetails.businessBanking,
          yes_City: companyDetails.businessBanking,
          yes_State: companyDetails.businessBanking,
          yes_ZipCode: companyDetails.businessBanking,
        };
      }

      let members = [];
      companyDetails.extractMemberData.map((item, index) => {
        if (item.memberType === "individual") {
          let obj = {
            ind_NameCheckbox: true,
            ind_AddressCheckbox: true,
            ind_FirstName: item.ind_FirstName,
            ind_LastName: item.ind_LastName,
            ind_StreetAddress: item.memberStreetAddress,
            ind_Address: item.memberAddress,
            ind_City: item.memberCity,
            ind_State: item.memberState,
            ind_ZipCode: item.memberZipCode,
            ind_Ownership: item.memberOwnership,
          };
          members.push(obj);
        } else {
          let obj = {
            ind_AddressCheckbox: true,
            com_CompanyName: item.com_CompanyName,
            ind_StreetAddress: item.memberStreetAddress,
            ind_Address: item.memberAddress,
            ind_City: item.memberCity,
            ind_State: item.memberState,
            ind_ZipCode: item.memberZipCode,
            ind_Ownership: item.memberOwnership,
          };
          members.push(obj);
        }
      });

      console.log("Member Array Data", companyDetails.extractMemberData);

      //* Create Final Object to Send to API

      let obj = {
        order,
        registeragent,
        ein,
        settingbusiness,
        members,
      };
      console.log("final obj", obj);
      const { Status, ResponseCode, Message, Data } =
        await Service.postServiceOrderV2(obj);
      if (Status === true && ResponseCode === 201) {
        successAlert(Message);
        //setOrderId(Data);
      } else if (Status === true && ResponseCode === 500) {
        errorAlert(Message);
      }
    } catch (error) {
      console.log(
        "file: ServicePayment.js => line 14 => ServicePayment => error",
        error
      );
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            borderRadius: "12px",
            boxShadow: `rgba(99, 99, 99, 0.2) 0px 2px 8px 0px`,
            my: 4,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CreditCardTwoTone
                sx={{ color: colors.primary, fontSize: "36px", mr: 2 }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: colors.black }}
              >
                Billing Information
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ backgroundColor: colors.portlandOrange, p: 3 }}>
                <Quote />
                <Typography
                  sx={{ color: colors.textPrimary, my: 2, minHeight: "220px" }}
                >
                  I must admit that I was nervous to begin this process,
                  however, I was able to easy maneuver my way throughout the
                  website. Incfile.com made my first time entrepreneurial
                  experience as peaceful one. I am beyond grateful and excited
                  to start my journey. Thank you!
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1 }}>
                    <Typography
                      sx={{ color: colors.black, fontWeight: "bold" }}
                    >
                      Quatina S.
                    </Typography>
                    <Typography
                      sx={{ color: colors.textPrimary, fontSize: "12px" }}
                    >
                      Shopperapproved.com
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={Images.verifiedCart}
                      alt="Verified"
                      style={{ marginRight: 2 }}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: colors.textPrimary,
                        fontWeight: "bold",
                      }}
                    >
                      Verified Purchase
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ backgroundColor: colors.portlandOrange, p: 3 }}>
                <Quote />
                <Typography
                  sx={{ color: colors.textPrimary, my: 2, minHeight: "220px" }}
                >
                  Awesome service. Very quick, easy and straight to the point.
                  They take all the hassle and worry out of renewal so you can
                  concentrate on running your business.
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1 }}>
                    <Typography
                      sx={{ color: colors.black, fontWeight: "bold" }}
                    >
                      Kamulah T.
                    </Typography>
                    <Typography
                      sx={{ color: colors.textPrimary, fontSize: "12px" }}
                    >
                      Shopperapproved.com
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={Images.verifiedCart}
                      alt="Verified"
                      style={{ marginRight: 2 }}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: colors.textPrimary,
                        fontWeight: "bold",
                      }}
                    >
                      Verified Purchase
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ my: 4 }} spacing={2}>
            <Grid item xs={12} md={6} sx={{ mr: 4 }}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 300 }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Cardholder First Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Cardholder Last Name"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ my: 6 }} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Street Address"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address (Cont)"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="City"
                variant="outlined"
              />
            </Grid>
            <Grid item container xs={12} md={6} spacing={2}>
              <Grid item xs={12} md={6}>
                {/* <TextField fullWidth id="outlined-basic" label="State" variant="outlined" /> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={mailStateSelect}
                  renderInput={(params) => (
                    <TextField {...params} label="State" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Zip Code"
                  type="number"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              backgroundColor: colors.portlandOrange,
              borderRadius: "8px",
              p: 3,
              display: "flex",
              alignItems: "center",
              my: 4,
            }}
          >
            <Lightbulb sx={{ mr: 1, fontSize: "42px", color: colors.black }} />
            <Box>
              <Typography sx={{ color: colors.textPrimary }}>
                In order to avoid delays and/or the cancellation of your order
                ensure that the billing address provided reconciles with the
                payment card used.
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Card Number"
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item container xs={12} md={8} spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography
                  sx={{ color: colors.textPrimary, fontSize: "14px", mb: 1.2 }}
                >
                  Expiration Date
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={monthSelect}
                  renderInput={(params) => (
                    <TextField {...params} label="Month" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} sx={{ my: 4 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={yearSelect}
                  renderInput={(params) => (
                    <TextField {...params} label="Year" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ my: 4 }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="CVV"
                  type="number"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <span style={{ color: colors.textPrimary }}>
                    I Agree To The
                    <span style={{ color: colors.primary }}>
                      &nbsp;Legal Statement
                    </span>{" "}
                    And
                    <span style={{ color: colors.primary }}>
                      &nbsp; Cancellation Policy.
                    </span>
                  </span>
                }
              />
            </FormGroup>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 1,
        }}
      >
        <Button component={Link} to="/process-order-review" variant="outlined">
          Back
        </Button>
        <Button
          // component={Link}
          // to="/process-payment"
          variant="contained"
          onClick={serviceOrder}
        >
          Complete & Pay
        </Button>
      </Box>
    </Box>
  );
}

export default ProcessPayment;
