import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Preview, RemoveRedEye } from '@mui/icons-material';
//* Import Component
import colors from 'app/style/colors'
import SideNav from 'app/components/SideNav/SideNav';
import { Loader } from 'assets/Images';
import Layout from 'app/layout/Layout';
import ViewOrder from 'app/components/Dialog/ViewOrder';
import useAuth from 'app/hooks/useAuth';
import { Service } from 'app/config/service';
import { getDate } from 'app/utils';
import { Link } from 'react-router-dom';
import ProfileDetail from 'app/components/ProfileDetail/ProfileDetail';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#344768',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,

  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function MyOrders() {

  const { user, logout } = useAuth();
  const userId = user.split("_")[0]

  const [openOrder, setOpenOrder] = useState(false);
  const [loader, setLoader] = useState(true);
  const handleOrderDialog = () => {
    setOpenOrder(!openOrder);
  };

  const [orderDetails, setorderDetails] = useState([]);


  //* Get Order Details API
  const getOrderDetails = async () => {
    try {
      const { Data } = await Service.getOrderDetails()
      console.log('file: MyOrders.js => line 69 => getOrderDetails => Data', Data);
      // const userDetail = await getUserInfo(Data[0]?.UserID)
      setorderDetails(Data)
      // setuserDetail(userDetail)
      setLoader(false)
    } catch (error) {
      console.log('file: MyOrders.js => line 69 => getOrderDetails => error', error)

    }
  }

  useEffect(() => {
    getOrderDetails()
  }, [])

  return (
    <Layout>

      <Grid container sx={{ mt: 4, justifyContent: 'center' }}>
        <Grid item xs={12} md={2}
          sx={{
            bgcolor: colors.white, textAlign: "center", boxShadow: 3,
            borderRadius: '12px', mx: 4, height: '180px'
          }}
        >

          <SideNav indexValue={1} />

        </Grid>
        <Grid item xs={12} md={8}>
          <ProfileDetail id={userId} pageName={"Order Details"} />

          {loader ? (
            <Box sx={{ textAlign: 'center' }}>
              <Loader />
            </Box>) : (
            <Grid sx={{ borderRadius: '12px', boxShadow: 3, padding: 2 }}>
              <TableContainer component={Paper} sx={{ cursor: 'pointer', maxHeight: 550 }}>
                <Table sx={{ minWidth: 1100, minHeight: 200 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Order ID(#)</Typography></StyledTableCell>
                      <StyledTableCell><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Service Name</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Order Date</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Status</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Payment</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Total&nbsp;($)</Typography></StyledTableCell>
                      <StyledTableCell ><Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Action</Typography></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails.map((item, index) => (
                      <Fragment>
                        {/* ====== View Order dialog ===== */}
                        <ViewOrder id={item.Id} open={openOrder} handleClose={handleOrderDialog} />
                        <StyledTableRow
                          key={index}>
                          <StyledTableCell>
                            <Typography sx={{ textAlign: 'center' }}>{item.Id}</Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography sx={{ textAlign: 'center' }}>{item.ServiceName}</Typography>
                          </StyledTableCell>
                          <StyledTableCell ><Typography sx={{ textAlign: 'center' }}>{getDate(item.AddOn)}</Typography></StyledTableCell>
                          <StyledTableCell ><Typography sx={{ textAlign: 'center', color: item.Status === "Verified" ? "green" : "red" }}>{item.Status}</Typography></StyledTableCell>
                          <StyledTableCell ><Typography sx={{ textAlign: 'center', color: item.PaymentStatus === "Paid" ? "green" : "red" }}>{item.PaymentStatus}</Typography></StyledTableCell>
                          <StyledTableCell ><Typography sx={{ textAlign: 'center' }}>${item.ServiceCharge}</Typography></StyledTableCell>
                          <StyledTableCell >
                            <Tooltip title="View Details" >
                              <IconButton onClick={() => setOpenOrder(!openOrder)}>
                                <RemoveRedEye color="primary" sx={{ fontSize: "22px" }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View Pre-Requisite" >
                              <IconButton component={Link} to={`/myrequirements/${item.Id}`}>
                                <Preview color="primary" sx={{ fontSize: "22px" }} />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MyOrders